import './styles/global.css';
import { Routes, Route, useLocation } from "react-router-dom";
import { RequireAuth } from "./components/auth/RequireAuth";
import { Layout } from './components/layout/Layout';
import { Home } from './components/home/Home';
import { Login } from './components/auth/Login';
import { Dashboard } from './components/dashboard/Dashboard';
import { Helmet } from "react-helmet";
import Alerts from "./components/global/alerts/Alerts";
import Dialogs from "./components/global/dialogs/Dialogs";
import { Condition } from './components/conditions/Condition';
import { VideoDetails } from "./components/videos/VideoDetails"
import { Fragment, useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import useSession from './components/global/session/useSession';
import { Auth, Hub } from 'aws-amplify';
import useAPI from './components/global/api/useAPI';
import useAlerts from './components/global/alerts/useAlerts';
import Search from './components/search/Search';
import FSLoading from './components/loading/FSLoading';
import Accounts from './components/admin/accounts/Accounts';
import Films from './components/admin/films/Films';
import Conditions from './components/admin/conditions/Conditions';
import Impacts from './components/admin/Impacts/Impacts';
import Respondents from './components/admin/respondents/Respondents';
import Users from './components/admin/users/Users';
import Profile from './components/admin/profile/Profile';


// --------------------------------------------------------
// MAIN APPLICATION
// --------------------------------------------------------

function App() {


	// ----------------------------------------------------
	// PROPERTIES
	// ----------------------------------------------------

	const [isLoading, setIsLoading] = useState(true);
	const api = useAPI();
	const session = useSession();
	const location = useLocation();
	const alerts = useAlerts();


	// ----------------------------------------------------
	// LOAD APPLICATION DATA 
	// ----------------------------------------------------

    const loadApplicationData = async (isIndex = true) => {

		setIsLoading(true);
		try {
			// CHECK AUTH
			let currentUser = await Auth.currentAuthenticatedUser();
			let account = await api.getAccountByID(currentUser.attributes.profile);
			
			// FETCH BACKEND DATA
			let videos = await api.getAllVideos();
			let conditions = await api.getAllConditions();
			let impacts = await api.getAllImpacts();
			let respondents = await api.getAllRespondents();

			session.updateCognitoUser(currentUser);
			session.updateAccount(account);
			session.updateVideos(videos);
			session.updateConditions(conditions);
			session.updateImpacts(impacts);
			session.updateRespondents(respondents);

		} catch (err) {
			console.log(err);
		}
		setIsLoading(false);
    }


	// ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
		let isIndex = (location.pathname == "/") ? true : false;
		
		// LISTEN FOR AUTH EVENTS
		Hub.listen('auth', (data) => {
			switch (data.payload.event) {
			  case 'signIn':
				console.log('AUTH EVENT :: User Signed In');
				loadApplicationData(isIndex);
				break;
			  case 'signOut':
				console.log('AUTH EVENT :: User Signed Out');
				break;
			}
		});

		// LOAD APPLICATION DATA
		loadApplicationData(isIndex);
    }, []);


	// ----------------------------------------------------
	// GET APPLICATION ROUTES
	// ----------------------------------------------------

	const GetAppRoutes = () => {
		let accessLevel = session.getUserAttribute(session.ACCESS_LEVEL);
		if (accessLevel == "SuperAdmins" || accessLevel == "Admins") {
			return (
				<Routes>
					<Route path="/" element={<Layout />}>
						
						{/* PUBLIC ROUTES */}
						<Route index element={<Home />} />
						<Route path="/login" element={<Login />} />

						{/* FRONTEND ROUTES */}
						<Route exact path="/dashboard" element={<RequireAuth><Dashboard/></RequireAuth>} />
						<Route exact path="/condition/:id" element={<RequireAuth><Condition/></RequireAuth>} />
						<Route exact path="/film/:id" element={<RequireAuth><VideoDetails /></RequireAuth>} />
						<Route exact path="/search" element={<RequireAuth><Search /></RequireAuth>} />

						{/* ADMIN ROUTES */}
						<Route exact path="/admin/films" element={<RequireAuth><Films /></RequireAuth>} />
						<Route exact path="/admin/conditions" element={<RequireAuth><Conditions /></RequireAuth>} />
						<Route exact path="/admin/impacts" element={<RequireAuth><Impacts /></RequireAuth>} />
						<Route exact path="/admin/respondents" element={<RequireAuth><Respondents /></RequireAuth>} />
						<Route exact path="/admin/accounts" element={<RequireAuth><Accounts /></RequireAuth>} />
						<Route exact path="/admin/users/:id" element={<RequireAuth><Users /></RequireAuth>} />
						{/* <Route exact path="/admin/profile" element={<RequireAuth><Profile /></RequireAuth>} /> */}
					</Route>
				</Routes>
			)
		} else if (accessLevel == "AccountAdmins") {
			return (
				<Routes>
					<Route path="/" element={<Layout />}>

						{/* PUBLIC ROUTES */}
						<Route index element={<Home />} />
						<Route path="/login" element={<Login />} />
						
						{/* FRONTEND ROUTES */}
						<Route exact path="/dashboard" element={<RequireAuth><Dashboard/></RequireAuth>} />
						<Route exact path="/condition/:id" element={<RequireAuth><Condition/></RequireAuth>} />
						<Route exact path="/film/:id" element={<RequireAuth><VideoDetails /></RequireAuth>} />
						<Route exact path="/search" element={<RequireAuth><Search /></RequireAuth>} />

						{/* ADMIN ROUTES */}
						<Route exact path="/admin/users/:id" element={<RequireAuth><Users /></RequireAuth>} />
						{/* <Route exact path="/admin/profile" element={<RequireAuth><Profile /></RequireAuth>} /> */}
					</Route>
				</Routes>
			)
		} else {
			return (
				<Routes>
					<Route path="/" element={<Layout />}>

						{/* PUBLIC ROUTES */}
						<Route index element={<Home />} />
						<Route path="/login" element={<Login />} />
						
						{/* FRONTEND ROUTES */}
						<Route exact path="/dashboard" element={<RequireAuth><Dashboard/></RequireAuth>} />
						<Route exact path="/condition/:id" element={<RequireAuth><Condition/></RequireAuth>} />
						<Route exact path="/film/:id" element={<RequireAuth><VideoDetails /></RequireAuth>} />
						<Route exact path="/search" element={<RequireAuth><Search /></RequireAuth>} />

						{/* ADMIN ROUTES */}
						<Route exact path="/admin/profile" element={<RequireAuth><Profile /></RequireAuth>} />
					</Route>
				</Routes>
			)
		}
	}


	// ----------------------------------------------------
	// RENDER MAIN APPLICATION
	// ----------------------------------------------------

	return (
		<Fragment>
			{ isLoading === true
				? <FSLoading text={"Loading > Please Wait..."}/>
				: <Container maxWidth={false} disableGutters>
					<Helmet>
						<title>Welcome to HealthImmersion by Dig</title>
					</Helmet>
					<GetAppRoutes/>
					<Dialogs/>
					<Alerts/>
				</Container>
			}
		</Fragment>
	);
}

export default App;