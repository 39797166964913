import React, { useState } from 'react';


// DIALOGS CONTEXT PROVIDER
export default function DialogsProvider({ children }) {

    // PROPERTIES
    const [progressOpen, setProgressOpen] = useState(false);
    const [progressTitle, setProgressTitle] = useState("");
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [confirmationTitle, setConfirmationTitle] = useState("");
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [validationOpen, setValidationOpen] = useState(false);
    const [validationTitle, setValidationTitle] = useState("");
    const [validationMessages, setValidationMessages] = useState([]);
    const [contactNonAuthOpen, setContactNonAuthOpen] = useState(false);
    const [contactAuthOpen, setContactAuthOpen] = useState(false);
    const [contactReason, setContactReason] = useState('');


    // CALLBACK REFERENCES
    const [okCallback, setOkCallback] = useState({});
    const [cancelCallback, setCancelCallback] = useState({});
    const [validateOkCallback, setValidateOkCallback] = useState({});

    // SHOW PROGRESS DIALOG
    const showProgress = (title) => {
        setProgressTitle(title);
        setProgressOpen(true);
    }

    // CLOSE PROGRESS DIALOG
    const closeProgress = () => {
        setProgressOpen(false);
        setProgressTitle("");
    }

    // SHOW CONFIRMATION DIALOG
    const showConfirmation = (title, message, callbackOK, callbackCancel) => {
        setOkCallback(() => callbackOK);
        setCancelCallback(() => callbackCancel);
        setConfirmationTitle(title);
        setConfirmationMessage(message);
        setConfirmationOpen(true);
    }

    // HANDLE OK PRESSED
    const handleOKPressed = () => {
        setConfirmationOpen(false);
        setConfirmationTitle("");
        setConfirmationMessage("");
        okCallback();
    }

    // HANDLE CANCEL PRESSED
    const handleCancelPressed = () => {
        setConfirmationOpen(false);
        setConfirmationTitle("");
        setConfirmationMessage("");
        cancelCallback();
    }

    // SHOW VALIDATION DIALOG
    const showValidation = (title, messages, callbackOK) => {
        setValidateOkCallback(() => callbackOK);
        setValidationTitle(title);
        setValidationMessages(messages);
        setValidationOpen(true);
    }

    // HANDLE VALIDATE OK PRESSED
    const handleValidateOKPressed = () => {
        setValidationOpen(false);
        setValidationTitle("");
        setValidationMessages([]);
        validateOkCallback();
    }

    // SHOW CONTACT NONAUTH
    const showContactNonAuth = () => {
        setContactNonAuthOpen(true);
    }

    // SHOW CONTACT AUTH
    const showContactAuth = () => {
        setContactAuthOpen(true);
    }

    // CLOSE CONTACT NONAUTH
    const closeContactNonAuth = () => {
        setContactNonAuthOpen(false);
    }

    // CLOSE CONTACT AUTH
    const closeContactAuth = () => {
        setContactReason("");
        setContactAuthOpen(false);
    }

    // HANDLE REASON CHANGE
    const handleReasonChange = (event) => {
        setContactReason(event.target.value);
    };

    // HANDLE CONTACT NONAUTH SUBMIT
    const handleContactNonAuthSubmit = (event) => {
        event.preventDefault();
        console.log("Name: ", event.currentTarget.yourName.value);
        console.log("Email: ", event.currentTarget.emailAddress.value);
        console.log("message: ", event.currentTarget.message.value);
    }

    // HANDLE CONTACT AUTH SUBMIT
    const handleContactAuthSubmit = (event) => {
        event.preventDefault();
        console.log("reason: ", contactReason);
        console.log("message: ", event.currentTarget.message.value);
    }

    // POPULATE CONTEXT OBJECT
    const contextValue = {
        progressOpen: progressOpen,
        progressTitle: progressTitle,
        confirmationOpen: confirmationOpen,
        confirmationTitle: confirmationTitle,
        confirmationMessage: confirmationMessage,
        validationOpen: validationOpen,
        validationTitle: validationTitle,
        validationMessages: validationMessages,
        contactNonAuthOpen: contactNonAuthOpen,
        contactAuthOpen: contactAuthOpen,
        contactReason: contactReason,
        showProgress: (title) => showProgress(title),
        closeProgress: () => closeProgress(),
        showConfirmation: (title, message, okCallback, cancelCallback) => showConfirmation(title, message, okCallback, cancelCallback),
        okPressed: () => { handleOKPressed() },
        cancelPressed: () => { handleCancelPressed() },
        showValidation: (title, message, okCallback) => showValidation(title, message, okCallback),
        validateOkPressed: () => { handleValidateOKPressed() },
        showContactNonAuth: () => showContactNonAuth(),
        showContactAuth: () => showContactAuth(),
        closeContactNonAuth: () => closeContactNonAuth(),
        closeContactAuth: () => closeContactAuth(),
        handleReasonChange: (event) => handleReasonChange(event),
        handleContactNonAuthSubmit: (event) => handleContactNonAuthSubmit(event),
        handleContactAuthSubmit: (event) => handleContactAuthSubmit(event),
    };

    // RENDER PROVIDER
    return (
        <DialogsContext.Provider value={contextValue}>
            {children}
        </DialogsContext.Provider>
    );
}

// DIALOGS CONTEXT OBJECT
export const DialogsContext = React.createContext({
    progressOpen: false,
    progressTitle: "",
    confirmationOpen: false,
    confirmationTitle: "",
    confirmationMessage: "",
    validationOpen: false,
    validationTitle: "",
    validationMessages: [],
    contactNonAuthOpen: false,
    contactAuthOpen: false,
    contactReason: "",
    showProgress: (title) => {},
    closeProgress: () => {},
    showConfirmation: (title, message, okCallback, cancelCallback) => {},
    okPressed: () => {},
    cancelPressed: () => {},
    showValidation: (title, message, okCallback) => {},
    validateOkPressed: () => {},
    showContactNonAuth: () => {},
    showContactAuth: () => {},
    closeContactNonAuth: () => {},
    closeContactAuth: () => {},
    handleReasonChange: (event) => {},
    handleContactNonAuthSubmit: (event) => {},
    handleContactAuthSubmit: (event) => {},
});