import { Grid } from "@mui/material";
import { BenefitsContainer, BenefitsInnerContainer } from "../../styled/containers/Containers";
import { darkBlue, mediumBlue, mediumPurple } from "../../styled/theme/Variables";
import BenefitsContent from "./components/BenefitsContent";


// --------------------------------------------------------
// BENEFITS SECTION
// --------------------------------------------------------

export default function Benefits (props) {

    // PROPERTIES
    const title1 = "Spark Innovation";
    const description1 = "Discover new and innovative growth opportunities with your patients/consumers.";
    const title2 = "Inspire Marketing";
    const description2 = "Write better creative briefs for vendors and strategists, bring new segments to life and reposition your brand.";
    const title3 = "Build Empathy";
    const description3 = "Generate empathy throughout your company for your target patient group.";

    // RENDER BENEFITS SECTION
    return (
        <BenefitsContainer maxWidth={false} disableGutters>
            <BenefitsInnerContainer>
                <Grid container direction={"row"} justifyContent={"space-around"}>
                    <Grid item>
                        <BenefitsContent title={title1} description={description1} headerColor={mediumBlue} />
                    </Grid>
                    <Grid item>
                        <BenefitsContent title={title2} description={description2} headerColor={darkBlue} />
                    </Grid>
                    <Grid item>
                        <BenefitsContent title={title3} description={description3} headerColor={mediumPurple} />
                    </Grid>
                </Grid>
            </BenefitsInnerContainer>
        </BenefitsContainer>
    )
}