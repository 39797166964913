import { Grid, Typography } from "@mui/material";
import { BenefitContentBox } from "../../../styled/boxes/Boxes";
import { mainWhite, mediumPurple } from "../../../styled/theme/Variables";



// --------------------------------------------------------
// BENEFITS CONTENT
// --------------------------------------------------------

export default function BenefitsContent ( props ) {

    // TOP CONTAINER CONFIG
    const topConfig = {
        backgroundColor: props.headerColor,
        borderRadius: "25px 25px 0px 0px",
        padding: "35px 0 20px",
    }

    // BOTTOM CONTAINER CONFIG
    const bottomConfig = {
        padding:"35px",
        minHeight: "165px",
        borderLeft: "1px solid",
        borderBottom: "1px solid",
        borderRight: "1px solid",
        borderColor: "#B2B2B266",
        borderRadius: "0px 0px 25px 25px",
    }

    // RENDER BENEFITS CONTENT
    return (
        <BenefitContentBox>
            <Grid container direction={"column"}>
                <Grid item sx={topConfig}>
                    <Typography variant="h5" color={mainWhite}><strong>{props.title}</strong></Typography>
                </Grid>
                <Grid item sx={bottomConfig}>
                    <Typography>{props.description}</Typography>
                </Grid>
            </Grid>
        </BenefitContentBox>
    )
}