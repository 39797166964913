import { Authenticator, Flex, Image, useTheme } from "@aws-amplify/ui-react";
import { useAuthenticator, View } from "@aws-amplify/ui-react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import "@aws-amplify/ui-react/styles.css";
import { Typography } from "@mui/material";
import { darkBlue, darkGray, mediumGray } from "../styled/theme/Variables";


// --------------------------------------------------------
// RENDER LOGIN COMPONENT
// --------------------------------------------------------

export function Login() {
  
    // ----------------------------------------------------
	// PROPERTIES
	// ----------------------------------------------------

    const { tokens } = useTheme();
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || "/dashboard";

    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        if (route === "authenticated") {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);

    
    // ----------------------------------------------------
	// CONTAINER STYLES
	// ----------------------------------------------------

    const loginContainer = {
        width: "100%",
    }
    const loginInnerContainer = {
		backgroundColor: tokens.colors.brand.white,
        width: "90%",
        padding: "150px 50px"
    }
    const formFields = {
        signIn: {
          username: {
            placeholder: '',
            isRequired: true,
            label: 'Username:'
          },
          password: {
            placeholder: '',
            isRequired: true,
            label: 'Password:'
          },
        },
      }

    // ----------------------------------------------------
	// RENDER LOGIN COMPONENT
	// ----------------------------------------------------
    return (
        <View {...loginContainer}>
            <Flex justifyContent="center">
                <View {...loginInnerContainer}>
                    <Flex justifyContent="center" alignItems={"center"} direction={"column"}>
                        {/* <Image alt="Amplify logo" src="/images/global/health-immersion-logo-dark.png" /> */}
                        {/* <Typography variant="body" color={darkBlue} gutterBottom><strong>ACCOUNT LOGIN</strong></Typography> */}
                        <Authenticator hideSignUp={true} formFields={formFields} />
                    </Flex>
                </View>
            </Flex>
        </View>
    );
}