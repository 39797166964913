import { Box, Checkbox, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GreenButton, PurpleButton } from "../../styled/buttons/Buttons";
import { CRUDDialogActionsContainer, CRUDDialogContainer, CRUDDialogHeaderContainer } from "../../styled/containers/Containers";
import { mainWhite } from "../../styled/theme/Variables";
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { v4 as uuidv4 } from 'uuid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useDialogs from "../../global/dialogs/useDialogs";
import useSession from "../../global/session/useSession";
import { JSONPath } from "jsonpath-plus";
import useAPI from "../../global/api/useAPI";
import { Storage } from 'aws-amplify';


// --------------------------------------------------------
// EDIT FILM COMPONENT
// --------------------------------------------------------

export function EditFilm( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------
    
    const api = useAPI();
    const dialogs = useDialogs();
    const session = useSession();
    const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(false);
    const [isLoadingVideo, setIsLoadingVideo] = useState(false);
    const [allowThumbnailUpload, setAllowThumbnailUpload] = useState(true);
    const [allowVideoUpload, setAllowVideoUpload] = useState(true);
    const [thumbnailUploadPath, setThumbnailUploadPath] = useState("");
    const [videoUploadPath, setVideoUploadPath] = useState("");
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [selectedImpacts, setSelectedImpacts] = useState([]);
    const [selectedRespondents, setSelectedRespondents] = useState([]);
    const [currentEditFilm, setCurrentEditFilm] = useState({name:"", description:""});
    const [filmName, setFilmName] = useState("");
    const [filmDescription, setFilmDescription] = useState("");


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        if (props.film && props.film !== currentEditFilm){
            setFilmName(props.film.name);
            setFilmDescription(props.film.description);

            let conditions = getConditionNames(props.film);
            let impacts = getImpactNames(props.film);
            let respondents = getRespondentNames(props.film);

            if (props.film.thumbnail.key){
                setAllowThumbnailUpload(false);
                setThumbnailUploadPath(props.film.thumbnail.key);
            }
            if (props.film.video.key){
                setAllowVideoUpload(false);
                setVideoUploadPath(props.film.video.key);
            }
            setSelectedConditions(conditions);
            setSelectedImpacts(impacts);
            setSelectedRespondents(respondents);
            setCurrentEditFilm(props.film);
        }
    }, [props]);


    // ----------------------------------------------------
	// GET CONDITION NAMES
	// ----------------------------------------------------

    const getConditionNames = (video) => {
        let conditions = [];
        for (let a = 0; a < video.conditions.items.length; a++){
            conditions.push(session.getConditionByID(video.conditions.items[a].conditionID).name);
        }
        return conditions.sort();
    }

    // ----------------------------------------------------
	// GET IMPACT NAMES
	// ----------------------------------------------------

    const getImpactNames = (video) => {
        let impacts = [];
        for (let a = 0; a < video.impacts.items.length; a++){
            impacts.push(session.getImpactByID(video.impacts.items[a].impactID).name);
        }
        return impacts.sort();
    }

    // ----------------------------------------------------
	// GET RESPONDENT NAMES
	// ----------------------------------------------------

    const getRespondentNames = (video) => {
        let respondents = [];
        for (let a = 0; a < video.respondents.items.length; a++){
            respondents.push(session.getRespondentByID(video.respondents.items[a].respondentID).name);
        }
        return respondents.sort();
    }

    // ----------------------------------------------------
	// SUBMIT EDIT FILM 
	// ----------------------------------------------------

    const handleSubmit = async (event) => {
        event.preventDefault();

        let validate = validateForm(event);
        if (validate !== false) {
            let title = "Missing Required Fields";
            dialogs.showValidation(title, validate, () => { });
            return;
        }

        let conditionsQuery = "$[?(";
        let impactsQuery = "$[?(";
        let respondentsQuery = "$[?(";
        for (let a = 0; a < selectedConditions.length; a++) {
            conditionsQuery += "@.name === '" + selectedConditions[a] + "'";
            (a < selectedConditions.length - 1) ? conditionsQuery += " || " : conditionsQuery += ")].id";
        }
        for (let a = 0; a < selectedImpacts.length; a++) {
            impactsQuery += "@.name === '" + selectedImpacts[a] + "'";
            (a < selectedImpacts.length - 1) ? impactsQuery += " || " : impactsQuery += ")].id";
        }
        for (let a = 0; a < selectedRespondents.length; a++) {
            respondentsQuery += "@.name === '" + selectedRespondents[a] + "'";
            (a < selectedRespondents.length - 1) ? respondentsQuery += " || " : respondentsQuery += ")].id";
        }
        let impactIDs = JSONPath({ path: impactsQuery, json: session.impacts });
        let conditonIDs = JSONPath({ path: conditionsQuery, json: session.conditions });
        let respondentIDs = JSONPath({ path: respondentsQuery, json: session.respondents });

        let filmName = event.currentTarget.filmName.value;
        let filmDescription = event.currentTarget.filmDescription.value;
        let filmThumbnail = {name:event.currentTarget.filmName.value, key:event.currentTarget.thumbnailImage.value};
        let filmVideo = {name:event.currentTarget.filmName.value, key:event.currentTarget.videoFile.value};

        try {
            dialogs.showProgress("Updating Film > Please wait...");
            await api.editVideo(currentEditFilm, filmName, filmDescription, conditonIDs, impactIDs, respondentIDs, filmThumbnail, filmVideo);
            dialogs.closeProgress();
        } catch (error) {
            console.log(error);
            dialogs.closeProgress();
        }
    };

    // ----------------------------------------------------
	// VALIDATE FORM SUBMIT
	// ----------------------------------------------------

    const validateForm = (event) => {
        let errors = [];
        let filmName = (event.currentTarget.filmName.value === "") ? errors.push("Film Name is required") : null;
        // let filmDescription = (event.currentTarget.filmDescription.value === "") ? errors.push("Film Description is required") : null;
        let hasSelectedConditions = (selectedConditions.length === 0) ? errors.push("At least one Condition is required") : null;
        let hasSelectedImpacts = (selectedImpacts.length === 0) ? errors.push("At least one Impact is required") : null;
        let hasSelectedRespondents = (selectedRespondents.length === 0) ? errors.push("At least one Respondent is required") : null
        let thumbnailImage = (event.currentTarget.thumbnailImage.value === "") ? errors.push("Thumbnail Image is required") : null;
        let videoFile = (event.currentTarget.videoFile.value === "") ? errors.push("Video File is required") : null;
        if (errors.length > 0) {
            return errors;
        } else {
            return false;
        }
    }

    // ----------------------------------------------------
	// CLOSE DIALOG
	// ----------------------------------------------------

    const handleClose = () => {
        props.close(false);
    };

    // ----------------------------------------------------
	// THUMBNAIL / VIDEO HANDLERS
	// ----------------------------------------------------

    const handleClearThumbnail = async () => {
        let title = "Delete Thumbnail";
        let message = "Are you sure you want to delete this Thumbnail?";
        dialogs.showConfirmation(title, message, () => doDeleteThumbnail(), () => { });
    };
    const doDeleteThumbnail = async () => {
        dialogs.showProgress("Deleting > Please wait...");
        await Storage.remove(thumbnailUploadPath);
        setAllowThumbnailUpload(true);
        setThumbnailUploadPath("");
        dialogs.closeProgress();
    }
    const handleClearVideo = async () => {
        let title = "Delete Video File";
        let message = "Are you sure you want to delete this Video File?";
        dialogs.showConfirmation(title, message, () => doDeleteVideo(), () => { });
    };
    const doDeleteVideo = async () => {
        dialogs.showProgress("Deleting > Please wait...");
        await Storage.remove(videoUploadPath);
        setAllowVideoUpload(true);
        setVideoUploadPath("");
        dialogs.closeProgress();
    };

    // ----------------------------------------------------
	// DROPDOWN HANDLERS
	// ----------------------------------------------------

    const handleConditionsChange = (event) => {
        const { target: { value }, } = event;
        setSelectedConditions(typeof value === 'string' ? value.split(',') : value);
    };
    const handleImpactsChange = (event) => {
        const { target: { value }, } = event;
        setSelectedImpacts(typeof value === 'string' ? value.split(',') : value);
    };
    const handleRespondentsChange = (event) => {
        const { target: { value }, } = event;
        setSelectedRespondents(typeof value === 'string' ? value.split(',') : value);
    };


    // ----------------------------------------------------
	// DROPDOWN STYLING
	// ----------------------------------------------------

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

	return (
        <Dialog fullWidth={true} maxWidth={"sm"} open={props.open}>
            <form onSubmit={handleSubmit}>
                <DialogContent sx={{padding:"0px"}}>
                    <CRUDDialogHeaderContainer>
                        <Typography color={mainWhite} variant={"h6"}><strong>Edit Film</strong></Typography>
                    </CRUDDialogHeaderContainer>
                    <CRUDDialogContainer>
                        <Grid container direction={"column"} rowSpacing={3}>
                            <Grid item flexGrow={1}>
                                <TextField id="filmName" label="Film Name" value={filmName} variant="outlined" sx={{width:"100%"}} onChange={(event) => setFilmName(event.target.value)}/>
                            </Grid>
                            <Grid item>
                                <TextField id="filmDescription" label="Film Description" value={filmDescription} multiline rows={5} variant="outlined" sx={{width:"100%"}} onChange={(event) => setFilmDescription(event.target.value)}/>
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ width: "100%", maxWidth: "550px" }}>
                                    <InputLabel id="selectedConditions">Conditions</InputLabel>
                                    <Select labelId="selectedConditions" multiple value={ selectedConditions }
                                        onChange={ handleConditionsChange }
                                        input={ <OutlinedInput multiline label="Conditions"/> }
                                        renderValue={ (selected) => selected.join(', ') }
                                        MenuProps={MenuProps} >
                                        { session.conditions.map((condition, index) => (
                                            <MenuItem key={index} value={condition.name}>
                                                <Checkbox checked={selectedConditions.indexOf(condition.name) > -1} />
                                                <ListItemText primary={condition.name} primaryTypographyProps={{fontSize: '14px'}}  />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ width: "100%", maxWidth: "550px" }}>
                                    <InputLabel id="selectedImpacts">Impacts</InputLabel>
                                    <Select labelId="selectedImpacts" multiple value={ selectedImpacts }
                                        onChange={ handleImpactsChange }
                                        input={ <OutlinedInput label="Impacts"/> }
                                        renderValue={ (selected) => selected.join(', ') }
                                        MenuProps={MenuProps} >
                                        { session.impacts.map((impact, index) => (
                                            <MenuItem key={index} value={impact.name}>
                                                <Checkbox checked={selectedImpacts.indexOf(impact.name) > -1} />
                                                <ListItemText primary={impact.name} primaryTypographyProps={{fontSize: '14px'}}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ width: "100%", maxWidth: "550px" }}>
                                    <InputLabel id="selectedRespondents">Respondents</InputLabel>
                                    <Select labelId="selectedRespondents" multiple value={ selectedRespondents }
                                        onChange={ handleRespondentsChange }
                                        input={ <OutlinedInput label="Respondents"/> }
                                        renderValue={ (selected) => selected.join(', ') }
                                        MenuProps={MenuProps} >
                                        { session.respondents.map((respondents, index) => (
                                            <MenuItem key={index} value={respondents.name}>
                                                <Checkbox checked={selectedRespondents.indexOf(respondents.name) > -1} />
                                                <ListItemText primary={respondents.name} primaryTypographyProps={{fontSize: '14px'}}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Box sx={{ position:'relative' }}>
                                    <StorageManager
                                        acceptedFileTypes={['image/*']}
                                        accessLevel="public"
                                        maxFileCount={1}
                                        isResumable
                                        components={{
                                            Container({ children }) {
                                                return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
                                            },
                                            DropZone({ children }) {
                                                return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
                                            },
                                            FilePicker({ onClick }) {
                                                return (
                                                    <FormControl sx={{ width: "100%", maxWidth: "550px" }}>
                                                        <InputLabel >Thumbnail Image</InputLabel>
                                                        <OutlinedInput
                                                            id="thumbnailImage"
                                                            disabled
                                                            value={thumbnailUploadPath}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={onClick} sx={{display:(allowThumbnailUpload ? "inherit" : "none")}}>
                                                                        <CloudUploadIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={handleClearThumbnail} sx={{display:(allowThumbnailUpload ? "none" : "inherit")}}>
                                                                        <HighlightOffIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label="Thumbnail Image"
                                                        />
                                                    </FormControl>
                                                );
                                            },
                                            FileList(args) {
                                                return null;
                                            },
                                            FileListHeader( args ) {
                                                return null;
                                            },
                                        }}
                                        processFile={({ file, key }) => {
                                            let uuid = uuidv4();
                                            let uuidSplit = uuid.split('-');
                                            key = "images/" + uuidSplit[4] + "-" + file.name;
                                            return { file, key };
                                        }}
                                        onUploadStart={({ key }) => {
                                            dialogs.showProgress("Uploading > Please wait...");
                                            setIsLoadingThumbnail(true);
                                        }}
                                        onUploadSuccess={({ key }) => {
                                            dialogs.closeProgress();
                                            setIsLoadingThumbnail(false);
                                            setThumbnailUploadPath(key);
                                            setAllowThumbnailUpload(false);
                                        }}
                                    />
                                    {isLoadingThumbnail && <CircularProgress size={24} sx={{position: 'absolute', top: '50%', left: '50%', marginTop:"-10px"}} />}
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={{ position:'relative', width: "100%", maxWidth: "550px" }}>
                                    <StorageManager
                                        acceptedFileTypes={['video/*']}
                                        accessLevel="public"
                                        maxFileCount={1}
                                        isResumable
                                        components={{
                                            Container({ children }) {
                                                return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
                                            },
                                            DropZone({ children }) {
                                                return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
                                            },
                                            FilePicker({ onClick }) {
                                                return (
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <InputLabel >Video File</InputLabel>
                                                        <OutlinedInput
                                                            id="videoFile"
                                                            disabled
                                                            value={videoUploadPath}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={onClick} sx={{display:(allowVideoUpload ? "inherit" : "none")}}>
                                                                        <CloudUploadIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={handleClearVideo} sx={{display:(allowVideoUpload ? "none" : "inherit")}}>
                                                                        <HighlightOffIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label="Video File"
                                                        />
                                                    </FormControl>
                                                );
                                            },
                                            FileList(args) {
                                                return null;
                                            },
                                            FileListHeader( args ) {
                                                return null;
                                            },
                                        }}
                                        processFile={({ file, key }) => {
                                            let uuid = uuidv4();
                                            let uuidSplit = uuid.split('-');
                                            key = "videos/" + uuidSplit[4] + "-" + file.name;
                                            return { file, key };
                                        }}
                                        onUploadStart={({ key }) => {
                                            dialogs.showProgress("Uploading > Please wait...");
                                            setIsLoadingVideo(true);
                                        }}
                                        onUploadSuccess={({ key }) => {
                                            dialogs.closeProgress();
                                            setIsLoadingVideo(false);
                                            setVideoUploadPath(key);
                                            setAllowVideoUpload(false);
                                        }}
                                    />
                                    {isLoadingVideo && <CircularProgress size={24} sx={{position: 'absolute', top: '50%', left: '50%', marginTop:"-10px"}} />}
                                </Box>
                            </Grid>
                        </Grid>
                    </CRUDDialogContainer>
                </DialogContent>
                <DialogActions sx={{padding:"20px 0px 0px 0px"}}>
                    <CRUDDialogActionsContainer>
                        <Grid container direction="row" columnSpacing={1} justifyContent={"flex-end"}>
                            <Grid item>
                                <GreenButton variant="contained" onClick={handleClose}>Cancel</GreenButton>
                            </Grid>
                            <Grid item>
                                <PurpleButton type="submit" variant="contained" >Update Film</PurpleButton>
                            </Grid>
                        </Grid>
                    </CRUDDialogActionsContainer>
                </DialogActions>
            </form>
        </Dialog>
    )
}