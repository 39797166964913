import { Box, Grid, Pagination, Typography } from "@mui/material";
import { GreenButton, PurpleButton, RedButton } from "../../styled/buttons/Buttons";
import { DataGridContainer, DataGridItemContainer } from "../../styled/containers/Containers";
import { mediumBlue } from "../../styled/theme/Variables";
import { useState } from "react";


// --------------------------------------------------------
// DATA GRID COMPONENT
// --------------------------------------------------------

export function DataGrid( props ) {

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(Math.ceil(props.items.length / pageSize));

    // ----------------------------------------------------
	// HANDLE PAGINATION
	// ----------------------------------------------------

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    }

    // ----------------------------------------------------
	// GET CURRENT PAGE ITEMS
	// ----------------------------------------------------

    const getCurrentPageItems = () => {
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = startIndex + pageSize;
        let videos = props.items.slice(startIndex, endIndex);
        return videos;
    }


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

	return (
        <DataGridContainer>
            { getCurrentPageItems()?.map((item, index) => (
                <DataGridItemContainer key={index}>
                    
                    <Grid container direction="row" justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <Typography color={mediumBlue} variant={"body2"}><strong>{item.name}</strong></Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" columnSpacing={1}>
                                { props.users === undefined
                                    ? null : <Grid item><GreenButton variant="contained" size="small" onClick={() => props.users(item.id)}>Users</GreenButton></Grid>
                                }
                                { props.view === undefined
                                    ? null : <Grid item><GreenButton variant="contained" size="small" onClick={() => props.view(item.id)}>View</GreenButton></Grid>
                                }
                                { props.edit === undefined
                                    ? null : <Grid item><PurpleButton variant="contained" size="small" onClick={() => props.edit(item.id)}>Edit</PurpleButton></Grid>
                                }
                                { props.delete === undefined
                                    ? null : <Grid item><RedButton variant="contained" size="small" onClick={() => props.delete(item.id)}>Delete</RedButton></Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                </DataGridItemContainer>
            ))}

            <Grid container justifyContent={"center"} alignItems={"center"}>
                <Box sx={{margin: "60px 0px 20px 0px"}} >
                    <Pagination page={currentPage} count={totalPages} shape="rounded" size="large" onChange={handlePageChange}  />
                </Box>
            </Grid>

        </DataGridContainer>
    )
}

export default DataGrid;