import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { HomeHeroContainer, HomeHeroInnerContainer } from "../../styled/containers/Containers";
import { GreenButtonXL } from "../../styled/buttons/Buttons"
import useDialogs from "../../global/dialogs/useDialogs";


// --------------------------------------------------------
// HEADER COMPONENT
// --------------------------------------------------------

export default function HomeHero (props) {

    // PROPERTIES -----------------------------------------

    const dialogs = useDialogs();

    // LEFT SIDE
    const leftSide = {
        float: "left",
        maxWidth: "45%",
        padding: "50px 0",
        textAlign: "left",
        p: {
            fontSize: "14px",
        }
    }


    // RENDER HERO
    return (
        <HomeHeroContainer maxWidth={false} disableGutters>
            <HomeHeroInnerContainer>
                <Box sx={leftSide}>
                    <Typography variant="h1">Bringing the voice of the patient to life.</Typography>
                    <Typography variant="h2">HealthImmersion is a secure and searchable collection of documentary films and patient journey maps. The content thoughtfully details the ongoing stories of a carefully recruited group of patients representing several different chronic health conditions.</Typography>
                    <p>The documentary films and patient journey maps are the product of longitudinal, in-person filmed ethnographic explorations. They provide deep insights into each health condition's impact on patients, their lives, their families/friends and caregivers.</p>
                    <br/>
                    <GreenButtonXL variant="contained" onClick={dialogs.showContactNonAuth}>Get Access</GreenButtonXL>
                </Box>
                <div className="clear" ></div>
            </HomeHeroInnerContainer>
        </HomeHeroContainer>
    )
}