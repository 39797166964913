/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listCognitoUsers = /* GraphQL */ `
  query ListCognitoUsers($groupName: String!, $nextToken: String) {
    listCognitoUsers(groupName: $groupName, nextToken: $nextToken)
  }
`;
export const handleContactForm = /* GraphQL */ `
  query HandleContactForm(
    $authed: Boolean!
    $formData: AWSJSON!
    $accountData: AWSJSON
  ) {
    handleContactForm(
      authed: $authed
      formData: $formData
      accountData: $accountData
    )
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      logo {
        name
        key
        __typename
      }
      conditions {
        items {
          id
          accountID
          conditionID
          account {
            id
            name
            logo {
              name
              key
              __typename
            }
            conditions {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo {
          name
          key
          __typename
        }
        conditions {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      name
      description
      conditions {
        items {
          id
          videoID
          conditionID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      impacts {
        items {
          id
          videoID
          impactID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          impact {
            id
            name
            description
            videos {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      video {
        name
        key
        __typename
      }
      thumbnail {
        name
        key
        __typename
      }
      isFeatured
      respondents {
        items {
          id
          videoID
          respondentID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          respondent {
            id
            name
            bio
            image {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchVideos = /* GraphQL */ `
  query SearchVideos(
    $filter: SearchableVideoFilterInput
    $sort: [SearchableVideoSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableVideoAggregationInput]
  ) {
    searchVideos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCondition = /* GraphQL */ `
  query GetCondition($id: ID!) {
    getCondition(id: $id) {
      id
      name
      description
      document {
        name
        key
        __typename
      }
      videos {
        items {
          id
          videoID
          conditionID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accounts {
        items {
          id
          accountID
          conditionID
          account {
            id
            name
            logo {
              name
              key
              __typename
            }
            conditions {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConditions = /* GraphQL */ `
  query ListConditions(
    $filter: ModelConditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConditions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        document {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        accounts {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchConditions = /* GraphQL */ `
  query SearchConditions(
    $filter: SearchableConditionFilterInput
    $sort: [SearchableConditionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableConditionAggregationInput]
  ) {
    searchConditions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        description
        document {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        accounts {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getImpact = /* GraphQL */ `
  query GetImpact($id: ID!) {
    getImpact(id: $id) {
      id
      name
      description
      videos {
        items {
          id
          videoID
          impactID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          impact {
            id
            name
            description
            videos {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listImpacts = /* GraphQL */ `
  query ListImpacts(
    $filter: ModelImpactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImpacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        videos {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchImpacts = /* GraphQL */ `
  query SearchImpacts(
    $filter: SearchableImpactFilterInput
    $sort: [SearchableImpactSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableImpactAggregationInput]
  ) {
    searchImpacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        description
        videos {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getRespondent = /* GraphQL */ `
  query GetRespondent($id: ID!) {
    getRespondent(id: $id) {
      id
      name
      bio
      image {
        name
        key
        __typename
      }
      videos {
        items {
          id
          videoID
          respondentID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          respondent {
            id
            name
            bio
            image {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRespondents = /* GraphQL */ `
  query ListRespondents(
    $filter: ModelRespondentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRespondents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        bio
        image {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchRespondents = /* GraphQL */ `
  query SearchRespondents(
    $filter: SearchableRespondentFilterInput
    $sort: [SearchableRespondentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRespondentAggregationInput]
  ) {
    searchRespondents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        bio
        image {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getAccountCondition = /* GraphQL */ `
  query GetAccountCondition($id: ID!) {
    getAccountCondition(id: $id) {
      id
      accountID
      conditionID
      account {
        id
        name
        logo {
          name
          key
          __typename
        }
        conditions {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      condition {
        id
        name
        description
        document {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        accounts {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAccountConditions = /* GraphQL */ `
  query ListAccountConditions(
    $filter: ModelAccountConditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountConditions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        conditionID
        account {
          id
          name
          logo {
            name
            key
            __typename
          }
          conditions {
            items {
              id
              accountID
              conditionID
              account {
                id
                name
                logo {
                  name
                  key
                  __typename
                }
                conditions {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              condition {
                id
                name
                description
                document {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                accounts {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        condition {
          id
          name
          description
          document {
            name
            key
            __typename
          }
          videos {
            items {
              id
              videoID
              conditionID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              condition {
                id
                name
                description
                document {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                accounts {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          accounts {
            items {
              id
              accountID
              conditionID
              account {
                id
                name
                logo {
                  name
                  key
                  __typename
                }
                conditions {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              condition {
                id
                name
                description
                document {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                accounts {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVideoCondition = /* GraphQL */ `
  query GetVideoCondition($id: ID!) {
    getVideoCondition(id: $id) {
      id
      videoID
      conditionID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      condition {
        id
        name
        description
        document {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        accounts {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVideoConditions = /* GraphQL */ `
  query ListVideoConditions(
    $filter: ModelVideoConditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoConditions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoID
        conditionID
        video {
          id
          name
          description
          conditions {
            items {
              id
              videoID
              conditionID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              condition {
                id
                name
                description
                document {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                accounts {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          impacts {
            items {
              id
              videoID
              impactID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              impact {
                id
                name
                description
                videos {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          video {
            name
            key
            __typename
          }
          thumbnail {
            name
            key
            __typename
          }
          isFeatured
          respondents {
            items {
              id
              videoID
              respondentID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              respondent {
                id
                name
                bio
                image {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        condition {
          id
          name
          description
          document {
            name
            key
            __typename
          }
          videos {
            items {
              id
              videoID
              conditionID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              condition {
                id
                name
                description
                document {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                accounts {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          accounts {
            items {
              id
              accountID
              conditionID
              account {
                id
                name
                logo {
                  name
                  key
                  __typename
                }
                conditions {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              condition {
                id
                name
                description
                document {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                accounts {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVideoImpact = /* GraphQL */ `
  query GetVideoImpact($id: ID!) {
    getVideoImpact(id: $id) {
      id
      videoID
      impactID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      impact {
        id
        name
        description
        videos {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVideoImpacts = /* GraphQL */ `
  query ListVideoImpacts(
    $filter: ModelVideoImpactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoImpacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoID
        impactID
        video {
          id
          name
          description
          conditions {
            items {
              id
              videoID
              conditionID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              condition {
                id
                name
                description
                document {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                accounts {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          impacts {
            items {
              id
              videoID
              impactID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              impact {
                id
                name
                description
                videos {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          video {
            name
            key
            __typename
          }
          thumbnail {
            name
            key
            __typename
          }
          isFeatured
          respondents {
            items {
              id
              videoID
              respondentID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              respondent {
                id
                name
                bio
                image {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        impact {
          id
          name
          description
          videos {
            items {
              id
              videoID
              impactID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              impact {
                id
                name
                description
                videos {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVideoRespondent = /* GraphQL */ `
  query GetVideoRespondent($id: ID!) {
    getVideoRespondent(id: $id) {
      id
      videoID
      respondentID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      respondent {
        id
        name
        bio
        image {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVideoRespondents = /* GraphQL */ `
  query ListVideoRespondents(
    $filter: ModelVideoRespondentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoRespondents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoID
        respondentID
        video {
          id
          name
          description
          conditions {
            items {
              id
              videoID
              conditionID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              condition {
                id
                name
                description
                document {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                accounts {
                  items {
                    id
                    accountID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          impacts {
            items {
              id
              videoID
              impactID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              impact {
                id
                name
                description
                videos {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          video {
            name
            key
            __typename
          }
          thumbnail {
            name
            key
            __typename
          }
          isFeatured
          respondents {
            items {
              id
              videoID
              respondentID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              respondent {
                id
                name
                bio
                image {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        respondent {
          id
          name
          bio
          image {
            name
            key
            __typename
          }
          videos {
            items {
              id
              videoID
              respondentID
              video {
                id
                name
                description
                conditions {
                  items {
                    id
                    videoID
                    conditionID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                impacts {
                  items {
                    id
                    videoID
                    impactID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                video {
                  name
                  key
                  __typename
                }
                thumbnail {
                  name
                  key
                  __typename
                }
                isFeatured
                respondents {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              respondent {
                id
                name
                bio
                image {
                  name
                  key
                  __typename
                }
                videos {
                  items {
                    id
                    videoID
                    respondentID
                    createdAt
                    updatedAt
                    __typename
                  }
                  nextToken
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
