import { Image } from "@aws-amplify/ui-react";
import { Box } from "@mui/system";
import { GreenButtonXL } from "../../styled/buttons/Buttons";
import { BottomContainer, BottomInnerContainer } from "../../styled/containers/Containers";
import { mainWhite, mediumBlue } from "../../styled/theme/Variables";
import { Typography } from "@mui/material";


// --------------------------------------------------------
// BOTTOM SIGNUP
// --------------------------------------------------------


export default function BottomSignup (props) {


    const bottomSignupLeft = {
        float: "left",
        maxWidth: "45%",
        padding: "50px 0",
        textAlign: "left",
        }

     const bottomSignupQuote = {
            float: "right",
            maxWidth: "40%",
            padding: "50px 5%",
            borderRadius: "50px",
            backgroundColor: mainWhite,
            em: {
                fontSize: "1rem",
            }
           
        }


    // RENDER BOTTOM
    return (
        <BottomContainer id="bottom.container" maxWidth={false} disableGutters>
            <BottomInnerContainer>
                <Typography variant={"h4"} color={mediumBlue}>“Dig's insight films are so well done - from the recruiting and interviews to the insight analysis, filming and editing. Being able to share these intimate human stories with our Marketing, HR and Executive teams has been a game changer.”</Typography>
                <Typography variant={"h6"} color={mediumBlue} sx={{marginTop:3}}><strong>Sr. Executive, Leading Healthcare Company, Chicago</strong></Typography>
            </BottomInnerContainer>
        </BottomContainer>
    )
}