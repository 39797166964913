import { Box, Checkbox, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputAdornment, InputLabel,OutlinedInput, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { GreenButton, PurpleButton } from "../../styled/buttons/Buttons";
import { CRUDDialogActionsContainer, CRUDDialogContainer, CRUDDialogHeaderContainer } from "../../styled/containers/Containers";
import { mainWhite } from "../../styled/theme/Variables";
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { v4 as uuidv4 } from 'uuid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useDialogs from "../../global/dialogs/useDialogs";
import useAPI from "../../global/api/useAPI";
import { Storage } from 'aws-amplify';


// --------------------------------------------------------
// ADD CONDITION COMPONENT
// --------------------------------------------------------

export function AddCondition( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------
    
    const api = useAPI();
    const dialogs = useDialogs();

    const [allowDocumentUpload, setAllowDocumentUpload] = useState(true);
    const [documentUploadPath, setDocumentUploadPath] = useState("");


    // ----------------------------------------------------
	// SUBMIT ADD CONDITION 
	// ----------------------------------------------------

    const handleSubmit = async (event) => {
        event.preventDefault();

        let validate = validateForm(event);
        if (validate !== false) {
            let title = "Missing Required Fields";
            dialogs.showValidation(title, validate, () => { });
            return;
        }

        let conditionName = event.currentTarget.conditionName.value;
        let conditionDescription = event.currentTarget.conditionDescription.value;
        let conditionDocument = {name:event.currentTarget.conditionName.value, key:event.currentTarget.documentFile.value};
        
        try {
            dialogs.showProgress("Adding Condition > Please wait...");
            await api.newCondition(conditionName, conditionDescription, conditionDocument);
            dialogs.closeProgress();
        } catch (error) {
            console.log(error);
            dialogs.closeProgress();
        }
    };

    // ----------------------------------------------------
	// VALIDATE FORM SUBMIT 
	// ----------------------------------------------------

    const validateForm = (event) => {
        let errors = [];
        let conditionName = (event.currentTarget.conditionName.value === "") ? errors.push("Condition Name is required") : null;
        // let conditionDescription = (event.currentTarget.conditionDescription.value === "") ? errors.push("Condition Description is required") : null;
        if (errors.length > 0) {
            return errors;
        } else {
            return false;
        }
    }

    // ----------------------------------------------------
	// CLOSE DIALOG 
	// ----------------------------------------------------

    const handleClose = () => {
        props.close(false);
    };

    // ----------------------------------------------------
	// DROPDOWN HANDLERS
	// ----------------------------------------------------

    const handleClearDocument = async () => {
        let title = "Delete Journey Map";
        let message = "Are you sure you want to delete this Document?";
        dialogs.showConfirmation(title, message, () => doDeleteDocument(), () => { });
    };
    const doDeleteDocument = async () => {
        dialogs.showProgress("Deleting > Please wait...");
        await Storage.remove(documentUploadPath);
        setAllowDocumentUpload(true);
        setDocumentUploadPath("");
        dialogs.closeProgress();
    };


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

	return (
        <Dialog fullWidth={true} maxWidth={"sm"} open={props.open}>
            <form onSubmit={handleSubmit}>
                <DialogContent sx={{padding:"0px"}}>
                    <CRUDDialogHeaderContainer>
                        <Typography color={mainWhite} variant={"h6"}><strong>Add New Condition</strong></Typography>
                    </CRUDDialogHeaderContainer>
                    <CRUDDialogContainer>
                        <Grid container direction={"column"} rowSpacing={3}>
                            <Grid item flexGrow={1}>
                                <TextField id="conditionName" label="Condition Name" variant="outlined" sx={{width:"100%"}} />
                            </Grid>
                            <Grid item>
                                <TextField id="conditionDescription" label="Condition Description" multiline rows={5} variant="outlined" sx={{width:"100%"}} />
                            </Grid>
                            <Grid item>
                                <Box sx={{ position:'relative', width: "100%", maxWidth: "550px" }}>
                                    <StorageManager
                                        acceptedFileTypes={['.pdf']}
                                        accessLevel="public"
                                        maxFileCount={1}
                                        isResumable
                                        components={{
                                            Container({ children }) {
                                                return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
                                            },
                                            DropZone({ children }) {
                                                return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
                                            },
                                            FilePicker({ onClick }) {
                                                return (
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <InputLabel >Journey Map</InputLabel>
                                                        <OutlinedInput
                                                            id="documentFile"
                                                            disabled
                                                            value={documentUploadPath}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={onClick} sx={{display:(allowDocumentUpload ? "inherit" : "none")}}>
                                                                        <CloudUploadIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={handleClearDocument} sx={{display:(allowDocumentUpload ? "none" : "inherit")}}>
                                                                        <HighlightOffIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label="Document File"
                                                        />
                                                    </FormControl>
                                                );
                                            },
                                            FileList(args) {
                                                return null;
                                            },
                                            FileListHeader( args ) {
                                                return null;
                                            },
                                        }}
                                        processFile={({ file, key }) => {
                                            let uuid = uuidv4();
                                            let uuidSplit = uuid.split('-');
                                            key = "downloads/" + uuidSplit[4] + "-" + file.name;
                                            return { file, key };
                                        }}
                                        onUploadStart={({ key }) => {
                                            dialogs.showProgress("Uploading > Please wait...");
                                        }}
                                        onUploadSuccess={({ key }) => {
                                            dialogs.closeProgress();
                                            setDocumentUploadPath(key);
                                            setAllowDocumentUpload(false);
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CRUDDialogContainer>
                </DialogContent>
                <DialogActions sx={{padding:"20px 0px 0px 0px"}}>
                    <CRUDDialogActionsContainer>
                        <Grid container direction="row" columnSpacing={1} justifyContent={"flex-end"}>
                            <Grid item>
                                <GreenButton variant="contained" onClick={handleClose}>Cancel</GreenButton>
                            </Grid>
                            <Grid item>
                                <PurpleButton type="submit" variant="contained" >Add Condition</PurpleButton>
                            </Grid>
                        </Grid>
                    </CRUDDialogActionsContainer>
                </DialogActions>
            </form>
        </Dialog>
    )
}