/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser(
    $role: String!
    $accountName: String!
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $locale: String
    $accountID: String
  ) {
    createCognitoUser(
      role: $role
      accountName: $accountName
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      locale: $locale
      accountID: $accountID
    )
  }
`;
export const deleteCognitoUser = /* GraphQL */ `
  mutation DeleteCognitoUser($username: String!) {
    deleteCognitoUser(username: $username)
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser(
    $username: String!
    $role: String
    $firstName: String
    $lastName: String
    $locale: String
    $accountID: String
    $accountName: String
    $emailAddress: String
  ) {
    updateCognitoUser(
      username: $username
      role: $role
      firstName: $firstName
      lastName: $lastName
      locale: $locale
      accountID: $accountID
      accountName: $accountName
      emailAddress: $emailAddress
    )
  }
`;
export const forcePasswordReset = /* GraphQL */ `
  mutation ForcePasswordReset($username: String!) {
    forcePasswordReset(username: $username)
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      name
      logo {
        name
        key
        __typename
      }
      conditions {
        items {
          id
          accountID
          conditionID
          account {
            id
            name
            logo {
              name
              key
              __typename
            }
            conditions {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      name
      logo {
        name
        key
        __typename
      }
      conditions {
        items {
          id
          accountID
          conditionID
          account {
            id
            name
            logo {
              name
              key
              __typename
            }
            conditions {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      name
      logo {
        name
        key
        __typename
      }
      conditions {
        items {
          id
          accountID
          conditionID
          account {
            id
            name
            logo {
              name
              key
              __typename
            }
            conditions {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      name
      description
      conditions {
        items {
          id
          videoID
          conditionID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      impacts {
        items {
          id
          videoID
          impactID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          impact {
            id
            name
            description
            videos {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      video {
        name
        key
        __typename
      }
      thumbnail {
        name
        key
        __typename
      }
      isFeatured
      respondents {
        items {
          id
          videoID
          respondentID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          respondent {
            id
            name
            bio
            image {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      name
      description
      conditions {
        items {
          id
          videoID
          conditionID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      impacts {
        items {
          id
          videoID
          impactID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          impact {
            id
            name
            description
            videos {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      video {
        name
        key
        __typename
      }
      thumbnail {
        name
        key
        __typename
      }
      isFeatured
      respondents {
        items {
          id
          videoID
          respondentID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          respondent {
            id
            name
            bio
            image {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      name
      description
      conditions {
        items {
          id
          videoID
          conditionID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      impacts {
        items {
          id
          videoID
          impactID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          impact {
            id
            name
            description
            videos {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      video {
        name
        key
        __typename
      }
      thumbnail {
        name
        key
        __typename
      }
      isFeatured
      respondents {
        items {
          id
          videoID
          respondentID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          respondent {
            id
            name
            bio
            image {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCondition = /* GraphQL */ `
  mutation CreateCondition(
    $input: CreateConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    createCondition(input: $input, condition: $condition) {
      id
      name
      description
      document {
        name
        key
        __typename
      }
      videos {
        items {
          id
          videoID
          conditionID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accounts {
        items {
          id
          accountID
          conditionID
          account {
            id
            name
            logo {
              name
              key
              __typename
            }
            conditions {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCondition = /* GraphQL */ `
  mutation UpdateCondition(
    $input: UpdateConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    updateCondition(input: $input, condition: $condition) {
      id
      name
      description
      document {
        name
        key
        __typename
      }
      videos {
        items {
          id
          videoID
          conditionID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accounts {
        items {
          id
          accountID
          conditionID
          account {
            id
            name
            logo {
              name
              key
              __typename
            }
            conditions {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCondition = /* GraphQL */ `
  mutation DeleteCondition(
    $input: DeleteConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    deleteCondition(input: $input, condition: $condition) {
      id
      name
      description
      document {
        name
        key
        __typename
      }
      videos {
        items {
          id
          videoID
          conditionID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      accounts {
        items {
          id
          accountID
          conditionID
          account {
            id
            name
            logo {
              name
              key
              __typename
            }
            conditions {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          condition {
            id
            name
            description
            document {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            accounts {
              items {
                id
                accountID
                conditionID
                account {
                  id
                  name
                  logo {
                    name
                    key
                    __typename
                  }
                  conditions {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createImpact = /* GraphQL */ `
  mutation CreateImpact(
    $input: CreateImpactInput!
    $condition: ModelImpactConditionInput
  ) {
    createImpact(input: $input, condition: $condition) {
      id
      name
      description
      videos {
        items {
          id
          videoID
          impactID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          impact {
            id
            name
            description
            videos {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateImpact = /* GraphQL */ `
  mutation UpdateImpact(
    $input: UpdateImpactInput!
    $condition: ModelImpactConditionInput
  ) {
    updateImpact(input: $input, condition: $condition) {
      id
      name
      description
      videos {
        items {
          id
          videoID
          impactID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          impact {
            id
            name
            description
            videos {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteImpact = /* GraphQL */ `
  mutation DeleteImpact(
    $input: DeleteImpactInput!
    $condition: ModelImpactConditionInput
  ) {
    deleteImpact(input: $input, condition: $condition) {
      id
      name
      description
      videos {
        items {
          id
          videoID
          impactID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          impact {
            id
            name
            description
            videos {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRespondent = /* GraphQL */ `
  mutation CreateRespondent(
    $input: CreateRespondentInput!
    $condition: ModelRespondentConditionInput
  ) {
    createRespondent(input: $input, condition: $condition) {
      id
      name
      bio
      image {
        name
        key
        __typename
      }
      videos {
        items {
          id
          videoID
          respondentID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          respondent {
            id
            name
            bio
            image {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRespondent = /* GraphQL */ `
  mutation UpdateRespondent(
    $input: UpdateRespondentInput!
    $condition: ModelRespondentConditionInput
  ) {
    updateRespondent(input: $input, condition: $condition) {
      id
      name
      bio
      image {
        name
        key
        __typename
      }
      videos {
        items {
          id
          videoID
          respondentID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          respondent {
            id
            name
            bio
            image {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRespondent = /* GraphQL */ `
  mutation DeleteRespondent(
    $input: DeleteRespondentInput!
    $condition: ModelRespondentConditionInput
  ) {
    deleteRespondent(input: $input, condition: $condition) {
      id
      name
      bio
      image {
        name
        key
        __typename
      }
      videos {
        items {
          id
          videoID
          respondentID
          video {
            id
            name
            description
            conditions {
              items {
                id
                videoID
                conditionID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                condition {
                  id
                  name
                  description
                  document {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  accounts {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            impacts {
              items {
                id
                videoID
                impactID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                impact {
                  id
                  name
                  description
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            video {
              name
              key
              __typename
            }
            thumbnail {
              name
              key
              __typename
            }
            isFeatured
            respondents {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          respondent {
            id
            name
            bio
            image {
              name
              key
              __typename
            }
            videos {
              items {
                id
                videoID
                respondentID
                video {
                  id
                  name
                  description
                  conditions {
                    nextToken
                    __typename
                  }
                  impacts {
                    nextToken
                    __typename
                  }
                  video {
                    name
                    key
                    __typename
                  }
                  thumbnail {
                    name
                    key
                    __typename
                  }
                  isFeatured
                  respondents {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                respondent {
                  id
                  name
                  bio
                  image {
                    name
                    key
                    __typename
                  }
                  videos {
                    nextToken
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAccountCondition = /* GraphQL */ `
  mutation CreateAccountCondition(
    $input: CreateAccountConditionInput!
    $condition: ModelAccountConditionConditionInput
  ) {
    createAccountCondition(input: $input, condition: $condition) {
      id
      accountID
      conditionID
      account {
        id
        name
        logo {
          name
          key
          __typename
        }
        conditions {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      condition {
        id
        name
        description
        document {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        accounts {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAccountCondition = /* GraphQL */ `
  mutation UpdateAccountCondition(
    $input: UpdateAccountConditionInput!
    $condition: ModelAccountConditionConditionInput
  ) {
    updateAccountCondition(input: $input, condition: $condition) {
      id
      accountID
      conditionID
      account {
        id
        name
        logo {
          name
          key
          __typename
        }
        conditions {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      condition {
        id
        name
        description
        document {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        accounts {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAccountCondition = /* GraphQL */ `
  mutation DeleteAccountCondition(
    $input: DeleteAccountConditionInput!
    $condition: ModelAccountConditionConditionInput
  ) {
    deleteAccountCondition(input: $input, condition: $condition) {
      id
      accountID
      conditionID
      account {
        id
        name
        logo {
          name
          key
          __typename
        }
        conditions {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      condition {
        id
        name
        description
        document {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        accounts {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVideoCondition = /* GraphQL */ `
  mutation CreateVideoCondition(
    $input: CreateVideoConditionInput!
    $condition: ModelVideoConditionConditionInput
  ) {
    createVideoCondition(input: $input, condition: $condition) {
      id
      videoID
      conditionID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      condition {
        id
        name
        description
        document {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        accounts {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVideoCondition = /* GraphQL */ `
  mutation UpdateVideoCondition(
    $input: UpdateVideoConditionInput!
    $condition: ModelVideoConditionConditionInput
  ) {
    updateVideoCondition(input: $input, condition: $condition) {
      id
      videoID
      conditionID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      condition {
        id
        name
        description
        document {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        accounts {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVideoCondition = /* GraphQL */ `
  mutation DeleteVideoCondition(
    $input: DeleteVideoConditionInput!
    $condition: ModelVideoConditionConditionInput
  ) {
    deleteVideoCondition(input: $input, condition: $condition) {
      id
      videoID
      conditionID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      condition {
        id
        name
        description
        document {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        accounts {
          items {
            id
            accountID
            conditionID
            account {
              id
              name
              logo {
                name
                key
                __typename
              }
              conditions {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVideoImpact = /* GraphQL */ `
  mutation CreateVideoImpact(
    $input: CreateVideoImpactInput!
    $condition: ModelVideoImpactConditionInput
  ) {
    createVideoImpact(input: $input, condition: $condition) {
      id
      videoID
      impactID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      impact {
        id
        name
        description
        videos {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVideoImpact = /* GraphQL */ `
  mutation UpdateVideoImpact(
    $input: UpdateVideoImpactInput!
    $condition: ModelVideoImpactConditionInput
  ) {
    updateVideoImpact(input: $input, condition: $condition) {
      id
      videoID
      impactID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      impact {
        id
        name
        description
        videos {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVideoImpact = /* GraphQL */ `
  mutation DeleteVideoImpact(
    $input: DeleteVideoImpactInput!
    $condition: ModelVideoImpactConditionInput
  ) {
    deleteVideoImpact(input: $input, condition: $condition) {
      id
      videoID
      impactID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      impact {
        id
        name
        description
        videos {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVideoRespondent = /* GraphQL */ `
  mutation CreateVideoRespondent(
    $input: CreateVideoRespondentInput!
    $condition: ModelVideoRespondentConditionInput
  ) {
    createVideoRespondent(input: $input, condition: $condition) {
      id
      videoID
      respondentID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      respondent {
        id
        name
        bio
        image {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVideoRespondent = /* GraphQL */ `
  mutation UpdateVideoRespondent(
    $input: UpdateVideoRespondentInput!
    $condition: ModelVideoRespondentConditionInput
  ) {
    updateVideoRespondent(input: $input, condition: $condition) {
      id
      videoID
      respondentID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      respondent {
        id
        name
        bio
        image {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVideoRespondent = /* GraphQL */ `
  mutation DeleteVideoRespondent(
    $input: DeleteVideoRespondentInput!
    $condition: ModelVideoRespondentConditionInput
  ) {
    deleteVideoRespondent(input: $input, condition: $condition) {
      id
      videoID
      respondentID
      video {
        id
        name
        description
        conditions {
          items {
            id
            videoID
            conditionID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            condition {
              id
              name
              description
              document {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              accounts {
                items {
                  id
                  accountID
                  conditionID
                  account {
                    id
                    name
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        impacts {
          items {
            id
            videoID
            impactID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            impact {
              id
              name
              description
              videos {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        video {
          name
          key
          __typename
        }
        thumbnail {
          name
          key
          __typename
        }
        isFeatured
        respondents {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      respondent {
        id
        name
        bio
        image {
          name
          key
          __typename
        }
        videos {
          items {
            id
            videoID
            respondentID
            video {
              id
              name
              description
              conditions {
                items {
                  id
                  videoID
                  conditionID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  condition {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              impacts {
                items {
                  id
                  videoID
                  impactID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  impact {
                    id
                    name
                    description
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              video {
                name
                key
                __typename
              }
              thumbnail {
                name
                key
                __typename
              }
              isFeatured
              respondents {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            respondent {
              id
              name
              bio
              image {
                name
                key
                __typename
              }
              videos {
                items {
                  id
                  videoID
                  respondentID
                  video {
                    id
                    name
                    description
                    isFeatured
                    createdAt
                    updatedAt
                    __typename
                  }
                  respondent {
                    id
                    name
                    bio
                    createdAt
                    updatedAt
                    __typename
                  }
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
