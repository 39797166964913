import { JSONPath } from 'jsonpath-plus';
import React, { useState } from 'react';
import useDialogs from '../dialogs/useDialogs';

export default function SessionProvider({ children }) {
      
    // ----------------------------------------------------
    // PROPERTIES
    // ----------------------------------------------------

    const [cognitoUser, setCognitoUser] = useState();
    const [account, setAccount] = useState();
    const [accounts, setAccounts] = useState();
    const [videos, setVideos] = useState();
    const [conditions, setConditions] = useState();
    const [impacts, setImpacts] = useState();
    const [respondents, setRespondents] = useState();
    const debugMode = true;

    // USER PARAMETERS
    const COGNITO_ID = "userID";
    const ACCOUNT_NAME = "accountName";
    const GROUP_NAME = "groupName";
    const FIRST_NAME = "firstName";
    const LAST_NAME = "lastName";
    const EMAIL_ADDRESS = "emailAddress";
    const PHONE_NUMBER = "phoneNumber";
    const LOCALE = "locale";
    const ACCESS_LEVEL = "accessLevel";
    const ACCESS_LABEL = "accessLabel";


    // ----------------------------------------------------
    // UPDATE CONGITO USER
    // ----------------------------------------------------

    const updateCognitoUser = (user) => {
        console.log("COGNITO USER: ", user);
        setCognitoUser(user);
    }

    // ----------------------------------------------------
    // GET USER ATTRIBUTE
    // ----------------------------------------------------

    const getUserAttribute = (attribute) => {
        if(!cognitoUser) return;
        switch (attribute){
            case COGNITO_ID:
                return cognitoUser.attributes.sub;
            case ACCOUNT_NAME:
                return account.name;
            case GROUP_NAME:
                return getGroupName();
            case FIRST_NAME:
                return cognitoUser.attributes.given_name;
            case LAST_NAME:
                return cognitoUser.attributes.family_name;
            case EMAIL_ADDRESS:
                return cognitoUser.attributes.email;
            case PHONE_NUMBER:
                let formatted = cognitoUser.attributes.phone_number.split("+1");
                return formatted[1];
            case LOCALE:
                return cognitoUser.attributes.locale;
            case ACCESS_LEVEL:
                return getAccessLevel()[0];
            case ACCESS_LABEL:
                return getAccessLevel()[1];
            default:
                return "";
        }
    }

    // ----------------------------------------------------
    // GET ACCESS LEVEL
    // ----------------------------------------------------

    const getAccessLevel = () => {
        const accessLevels = ["SuperAdmins", "Admins", "AccountAdmins", "AccountUsers"];
        const accessLabels = ["Super Admin", "Admin", "Account Admin", "Account User"];
        if(!cognitoUser) return [];
        if (cognitoUser.signInUserSession){
            const assignedlevels = cognitoUser.signInUserSession.idToken.payload["cognito:groups"];
            for (let a = 0 ; a < accessLevels.length ; a++){
                if (assignedlevels.indexOf(accessLevels[a]) !== -1){
                    return [accessLevels[a], accessLabels[a]];
                }
            }
        }
    }

    // ----------------------------------------------------
    // UPDATE ACCOUNT
    // ----------------------------------------------------

    const updateAccount = (account) => {
        if (process.env.NODE_ENV !== 'production') {
            console.log("USER ACCOUNT: ", account);
        }
        setAccount(account);
    }

    // ----------------------------------------------------
    // GET GROUP NAME
    // ----------------------------------------------------

    const getGroupName = () => {
        const groupNames = ["SuperAdmins", "Admins", "AccountAdmins", "AccountUsers"]
        if(!cognitoUser) return;
        if (cognitoUser.signInUserSession){
            const groups = cognitoUser.signInUserSession.idToken.payload["cognito:groups"];
            for (let a = 0 ; a < groups.length ; a++){
                if (groupNames.indexOf(groups[a]) === -1){
                    return groups[a];
                }
            }
        }
    }

    // ----------------------------------------------------
    // UPDATE ACCOUNTS
    // ----------------------------------------------------

    const updateAccounts = (accounts) => {
        if (process.env.NODE_ENV !== 'production') {
            console.log("ACCOUNTS: ", accounts);
        }
        setAccounts(accounts);
    }

    // ----------------------------------------------------
    // UPDATE VIDEOS
    // ----------------------------------------------------

    const updateVideos = (videos) => {
        if (process.env.NODE_ENV !== 'production') {
            console.log("VIDEOS: ", videos);
        }
        setVideos(videos);
    }

    // ----------------------------------------------------
    // GET VIDEOS BY ID
    // ----------------------------------------------------

    const getVideoByID = (videoID) => {
        return JSONPath({ path: "$[?(@.id == '" + videoID + "')]", json: videos })[0];
    }

    // ----------------------------------------------------
    // GET VIDEOS BY CONDITION ID
    // ----------------------------------------------------

    const getVideosByConditionID = (conditionID) => {
        let condition = getConditionByID(conditionID);
        let filteredVideos = [];
        for (let a = 0 ; a < condition.videos.items.length ; a++){
            let currentVideoID = condition.videos.items[a].videoID;
            filteredVideos.push(getVideoByID(currentVideoID));
        }
        let results = filteredVideos.sort( sortResultsByName );
        return [...new Set(results)];
    }

    // ----------------------------------------------------
    // UPDATE CONDITIONS
    // ----------------------------------------------------

    const updateConditions = (conditions) => {
        if (process.env.NODE_ENV !== 'production') {
            console.log("CONDITIONS: ", conditions);
        }
        setConditions(conditions);
    }

    // ----------------------------------------------------
    // GET CONDITION BY ID
    // ----------------------------------------------------

    const getConditionByID = (conditionID) => {
        return JSONPath({ path: "$[?(@.id == '" + conditionID + "')]", json: conditions })[0];
    }

    // ----------------------------------------------------
    // UPDATE IMPACTS
    // ----------------------------------------------------

    const updateImpacts = (impacts) => {
        if (process.env.NODE_ENV !== 'production') {
            console.log("IMPACTS: ", impacts);
        }
        setImpacts(impacts);
    }

    // ----------------------------------------------------
    // GET IMPACT BY ID
    // ----------------------------------------------------

    const getImpactByID = (impactID) => {
        return JSONPath({ path: "$[?(@.id == '" + impactID + "')]", json: impacts })[0];
    }

    // ----------------------------------------------------
    // GET IMPACTS BY CONDITION ID
    // ----------------------------------------------------

    const getImpactsByConditionID = (conditionID) => {
        let condition = getConditionByID(conditionID);
        let filteredImpacts = [];
        for (let a = 0 ; a < condition.videos.items.length ; a++){
            let currentVideo = getVideoByID(condition.videos.items[a].videoID);
            for (let b = 0 ; b < currentVideo.impacts.items.length ; b++){
                let currentImpactID = currentVideo.impacts.items[b].impactID;
                filteredImpacts.push(getImpactByID(currentImpactID));
            }
        }
        let results = filteredImpacts.sort( sortResultsByName );
        return [...new Set(results)];
    }

    // ----------------------------------------------------
    // UPDATE RESPONDENTS
    // ----------------------------------------------------

    const updateRespondents = (respondents) => {
        if (process.env.NODE_ENV !== 'production') {
            console.log("RESPONDENTS: ", respondents);
        }
        setRespondents(respondents);
    }

    // ----------------------------------------------------
    // GET RESPONDENT BY ID
    // ----------------------------------------------------

    const getRespondentByID = (respondentID) => {
        return JSONPath({ path: "$[?(@.id == '" + respondentID + "')]", json: respondents })[0];
    }

    // ----------------------------------------------------
    // GET RESPONDENTS BY CONDITION ID
    // ----------------------------------------------------

    const getRespondentsByConditionID = (conditionID) => {
        let condition = getConditionByID(conditionID);
        let filteredRespondents = [];
        for (let a = 0 ; a < condition.videos.items.length ; a++){
            let currentVideo = getVideoByID(condition.videos.items[a].videoID);
            for (let b = 0 ; b < currentVideo.respondents.items.length ; b++){
                let currentRespondentID = currentVideo.respondents.items[b].respondentID;
                filteredRespondents.push(getRespondentByID(currentRespondentID));
            }
        }
        let results = filteredRespondents.sort( sortResultsByName );
        return [...new Set(results)];
    }

    // ----------------------------------------------------
    // SORT RESULT ARRAYS
    // ----------------------------------------------------

    const sortResultsByName = (a, b) => {
        if ( a.name < b.name ){ return -1; }
        if ( a.name > b.name ){ return 1; }
        return 0;
    }

    // ----------------------------------------------------
    // POPULATE CONTEXT OBJECT
    // ----------------------------------------------------

    const contextValue = {
        cognitoUser: cognitoUser,
        account: account,
        accounts: accounts,
        videos: videos,
        conditions: conditions,
        impacts: impacts,
        respondents: respondents,
        
        updateCognitoUser: (user) => updateCognitoUser(user),
        getUserAttribute: (attribute) => getUserAttribute(attribute),
        updateAccount: (account) => updateAccount(account),
        updateAccounts: (accounts) => updateAccounts(accounts),
        updateVideos: (videos) => updateVideos(videos),
        getVideoByID: (videoID) => getVideoByID(videoID),
        getVideosByConditionID: (conditionID) => getVideosByConditionID(conditionID),
        updateConditions: (conditions) => updateConditions(conditions),
        getConditionByID: (conditionID) => getConditionByID(conditionID),
        updateImpacts: (impacts) => updateImpacts(impacts),
        getImpactByID: (impactID) => getImpactByID(impactID),
        getImpactsByConditionID: (conditionID) => getImpactsByConditionID(conditionID),
        updateRespondents: (respondents) => updateRespondents(respondents),
        getRespondentByID: (respondentID) => getRespondentByID(respondentID),
        getRespondentsByConditionID: (conditionID) => getRespondentsByConditionID(conditionID),

        COGNITO_ID: COGNITO_ID,
        ACCOUNT_NAME: ACCOUNT_NAME,
        GROUP_NAME: GROUP_NAME,
        FIRST_NAME: FIRST_NAME,
        LAST_NAME: LAST_NAME,
        EMAIL_ADDRESS: EMAIL_ADDRESS,
        PHONE_NUMBER: PHONE_NUMBER,
        LOCALE: LOCALE,
        ACCESS_LEVEL: ACCESS_LEVEL,
        ACCESS_LABEL: ACCESS_LABEL,
    };

    // ----------------------------------------------------
    // RENDER PROVIDER
    // ----------------------------------------------------

    return (
        <SessionContext.Provider value={contextValue}>
            {children}
        </SessionContext.Provider>
    );
}

// ----------------------------------------------------
// SESSON CONTEXT OBJECT
// ----------------------------------------------------

export const SessionContext = React.createContext({
    cognitoUser: {},
    account: {},
    accounts: {},
    videos: {},
    conditions: {},
    impacts: {},
    respondents: {},
    
    updateCognitoUser: (user) => {},
    getUserAttribute: (attribute) => {},
    updateAccount: (account) => {},
    updateAccounts: (accounts) => {},
    updateVideos: (videos) => {},
    getVideoByID: (videoID) => {},
    getVideosByConditionID: (conditionID) => {},
    updateConditions: (conditions) => {},
    getConditionByID: (conditionID) => {},
    updateImpacts: (impacts) => {},
    getImpactByID: (impactID) => {},
    getImpactsByConditionID: (conditionID) => {},
    updateRespondents: (respondents) => {},
    getRespondentByID: (respondentID) => {},
    getRespondentsByConditionID: (conditionID) => {},

    COGNITO_ID: "",
    ACCOUNT_NAME: "",
    GROUP_NAME: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    EMAIL_ADDRESS: "",
    PHONE_NUMBER: "",
    LOCALE: "",
    ACCESS_LEVEL: "",
    ACCESS_LABEL: "",
});
