import { Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from "@mui/material";
import { GreenButton, PurpleButton } from "../../styled/buttons/Buttons";
import { CRUDDialogActionsContainer, CRUDDialogContainer, CRUDDialogHeaderContainer } from "../../styled/containers/Containers";
import { mainWhite } from "../../styled/theme/Variables";
import useDialogs from "../../global/dialogs/useDialogs";
import useAPI from "../../global/api/useAPI";


// --------------------------------------------------------
// ADD IMPACT COMPONENT
// --------------------------------------------------------

export function AddImpact( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------
    
    const api = useAPI();
    const dialogs = useDialogs();


    // ----------------------------------------------------
	// HANDLERS 
	// ----------------------------------------------------

    const handleSubmit = async (event) => {
        event.preventDefault();

        let validate = validateForm(event);
        if (validate !== false) {
            let title = "Missing Required Fields";
            dialogs.showValidation(title, validate, () => { });
            return;
        }

        let impactName = event.currentTarget.impactName.value;
        let impactDescription = event.currentTarget.impactDescription.value;
        
        try {
            dialogs.showProgress("Adding Impact > Please wait...");
            await api.newImpact(impactName, impactDescription);
            dialogs.closeProgress();
        } catch (error) {
            console.log(error);
            dialogs.closeProgress();
        }
    };

    const validateForm = (event) => {
        let errors = [];
        let impactName = (event.currentTarget.impactName.value === "") ? errors.push("Impact Name is required") : null;
        // let impactDescription = (event.currentTarget.impactDescription.value === "") ? errors.push("Impact Description is required") : null;
        if (errors.length > 0) {
            return errors;
        } else {
            return false;
        }
    }

    const handleClose = () => {
        props.close(false);
    };

    const handleClearDocument = async () => {
        dialogs.closeProgress();
    };


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

	return (
        <Dialog fullWidth={true} maxWidth={"sm"} open={props.open}>
            <form onSubmit={handleSubmit}>
                <DialogContent sx={{padding:"0px"}}>
                    <CRUDDialogHeaderContainer>
                        <Typography color={mainWhite} variant={"h6"}><strong>Add New Impact</strong></Typography>
                    </CRUDDialogHeaderContainer>
                    <CRUDDialogContainer>
                        <Grid container direction={"column"} rowSpacing={3}>
                            <Grid item flexGrow={1}>
                                <TextField id="impactName" label="Impact Name" variant="outlined" sx={{width:"100%"}} />
                            </Grid>
                            <Grid item>
                                <TextField id="impactDescription" label="Impact Description" multiline rows={5} variant="outlined" sx={{width:"100%"}} />
                            </Grid>
                        </Grid>
                    </CRUDDialogContainer>
                </DialogContent>
                <DialogActions sx={{padding:"20px 0px 0px 0px"}}>
                    <CRUDDialogActionsContainer>
                        <Grid container direction="row" columnSpacing={1} justifyContent={"flex-end"}>
                            <Grid item>
                                <GreenButton variant="contained" onClick={handleClose}>Cancel</GreenButton>
                            </Grid>
                            <Grid item>
                                <PurpleButton type="submit" variant="contained" >Add Impact</PurpleButton>
                            </Grid>
                        </Grid>
                    </CRUDDialogActionsContainer>
                </DialogActions>
            </form>
        </Dialog>
    )
}