import { Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import useSession from "../../global/session/useSession";
import Loading from "../../loading/Loading";
import { PurpleButton } from "../../styled/buttons/Buttons";
import { AdminContainer, AdminContentContainer, AdminHeroContainer, AdminHeroInnerContainer } from "../../styled/containers/Containers";
import { mediumBlue } from "../../styled/theme/Variables";
import DataGrid from "../modules/DataGrid";
import useAPI from "../../global/api/useAPI";
import useDialogs from "../../global/dialogs/useDialogs";
import { AddImpact } from "./AddImpact";
import { EditImpact } from "./EditImpact";


// --------------------------------------------------------
// IMPACTS COMPONENT
// --------------------------------------------------------

export function Impacts( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------

    const api = useAPI();
    const dialogs = useDialogs();
    const session = useSession();
    const [isLoading, setIsLoading] = useState(true);
    
    const [impacts, setImpacts] = useState([]);
    const [newImpactOpen, setNewImpactOpen] = useState(false);
    const [editImpactOpen, setEditImpactOpen] = useState(false);
    const [currentEditImpact, setCurrentEditImpact] = useState(null);


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        setImpacts(session.impacts);
        setIsLoading(false);
    }, []);


    // ----------------------------------------------------
	// NEW IMPACT
	// ----------------------------------------------------

    const newItem = (id) => {
        setNewImpactOpen(true)
    }

    // ----------------------------------------------------
	// EDIT IMPACT
	// ----------------------------------------------------

    const editItem = (id) => {
        let currentImpact = impacts.filter(impact => impact.id === id)[0];
        setCurrentEditImpact(currentImpact);
        setEditImpactOpen(true);
    }

    // ----------------------------------------------------
	// DELETE IMPACT
	// ----------------------------------------------------

    const deleteItem = (id) => {
        let title = "Delete Impact";
        let message = "Are you sure you want to delete this Impact?";
        dialogs.showConfirmation(title, message, () => doDelete(id), () => { });
    }
    const doDelete = async (id) => {
        dialogs.showProgress("Deleting Impact > Please wait...");
        await api.removeImpact(id);
        dialogs.closeProgress();
    }

    // ----------------------------------------------------
	// CLOSE / RESET DIALOGS
	// ----------------------------------------------------

    const handleNewClose = () => {
        setNewImpactOpen(false);
    };
    const handleEditClose = () => {
        setEditImpactOpen(false)
    };


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

    return (
        <Fragment>
            { isLoading === true
                ? <Loading text={"Loading > Please Wait..."}/>
                : <AdminContainer maxWidth={false} disableGutters>
                    <AdminHeroContainer maxWidth={false} disableGutters>
                        <AdminHeroInnerContainer>
                            <Grid container direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <Typography variant={"h4"} color={mediumBlue}><strong>Manage Impacts</strong></Typography>
                                </Grid>
                                <Grid item>
                                    <PurpleButton variant="contained" onClick={newItem}>Add New Impact</PurpleButton>
                                </Grid>
                            </Grid>
                        </AdminHeroInnerContainer>
                    </AdminHeroContainer>
                    <AdminContentContainer>
                        <DataGrid items={impacts} edit={editItem} delete={deleteItem}/>
                    </AdminContentContainer>
                </AdminContainer>
            }
            <AddImpact open={newImpactOpen} close={handleNewClose} />
            <EditImpact open={editImpactOpen} close={handleEditClose} impact={currentEditImpact} />
         </Fragment>
    )

}

export default Impacts;