import { Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAPI from "../global/api/useAPI";
import useSession from "../global/session/useSession";
import Loading from "../loading/Loading";
import { SearchContainer, SearchHeroContainer, SearchHeroInnerContainer } from "../styled/containers/Containers";
import { mediumBlue } from "../styled/theme/Variables";
import { VideosGrid } from "../videos/VideosGrid";


// --------------------------------------------------------
// SEARCH COMPONENT
// --------------------------------------------------------

export default function Search () {


    // ----------------------------------------------------
	// PROPERTIES
	// ----------------------------------------------------

    const api = useAPI();
    const session = useSession();
    const location = useLocation();
    const searchQuery = location.state.searchQuery;
    const [searchAllVideos, setSearchAllVideos] = useState();
    const [searchConditions, setSearchConditions] = useState();
    const [searchImpacts, setSearchImpacts] = useState();
    const [searchRespondents, setSearchRespondents] = useState();
    const [isLoading, setIsLoading] = useState(true);


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        let searchComplete = false;

        // LOAD SEARCH DATA
        const loadSearchData = async () => {
            const results = await api.getSearchResults(searchQuery);
            let videoResults = [];
            let conditionResults = [];
            let impactResults = [];
            let respondentResults = [];

            if (results.videos.length > 0){
                for (let a = 0; a < results.videos.length; a++){
                    videoResults.push(results.videos[a].id)
                }
            }
            if (results.conditions.length > 0){
                for (let a = 0; a < results.conditions.length; a++){
                    conditionResults.push(results.conditions[a]);
                    for (let b = 0 ; b < results.conditions[a].videos.items.length; b++){
                        videoResults.push(results.conditions[a].videos.items[b].videoID)
                    }
                }
            }
            if (results.impacts.length > 0){
                for (let a = 0; a < results.impacts.length; a++){
                    impactResults.push(results.impacts[a]);
                    for (let b = 0 ; b < results.impacts[a].videos.items.length; b++){
                        videoResults.push(results.impacts[a].videos.items[b].videoID)
                    }
                }
            }
            if (results.respondents.length > 0){
                for (let a = 0; a < results.respondents.length; a++){
                    respondentResults.push(results.respondents[a]);
                    for (let b = 0 ; b < results.respondents[a].videos.items.length; b++){
                        videoResults.push(results.respondents[a].videos.items[b].videoID)
                    }
                }
            }
            let allVideoResults = []
            let cleanedVideoIDs = [...new Set(videoResults)];
            for (let a = 0; a < cleanedVideoIDs.length; a++){
                allVideoResults.push(session.getVideoByID(cleanedVideoIDs[a]));
            }
            setSearchAllVideos(allVideoResults);
            setSearchConditions(conditionResults);
            setSearchImpacts(impactResults);
            setSearchRespondents(respondentResults);
            setIsLoading(false);
        }
        loadSearchData();

        // CLEANUP
        return () => {
            searchComplete = true;
        }
    }, []);


    // ----------------------------------------------------
	// RENDER SEARCH COMPONENT
	// ----------------------------------------------------

    return (
        <Fragment>
			{ isLoading === true
				? <Loading text={"Loading > Please Wait..."}/>
				: <SearchContainer maxWidth={false} disableGutters>
                    <SearchHeroContainer maxWidth={false} >

                        {/* HERO CONTENT */}
                        <SearchHeroInnerContainer>
                            <Typography variant={"h4"} color={mediumBlue}><strong>Search Results for "{searchQuery}"</strong></Typography>
                        </SearchHeroInnerContainer>
                        
                    </SearchHeroContainer>

                    {/* FILTERS / GRID */}
                    <VideosGrid videos={searchAllVideos} conditions={searchConditions} impacts={searchImpacts} respondents={searchRespondents} />

                </SearchContainer>
			}
		</Fragment>
    )
}
