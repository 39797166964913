import React, { Fragment } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useAlerts from "./useAlerts";

const Alerts = () => {

    // PROPERTIES
    const { successOpen, successMessage, closeSuccess, errorOpen, errorMessage, closeError } = useAlerts();

    // RENDER UI
    return (
        <Fragment>
            <Snackbar anchorOrigin={{ vertical:"bottom", horizontal:"center" }} open={successOpen} autoHideDuration={5000} onClose={closeSuccess}>
                <Alert elevation={6} variant="filled" onClose={closeSuccess} severity="success">{successMessage}</Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical:"bottom", horizontal:"center" }} open={errorOpen} autoHideDuration={5000} onClose={closeError}>
                <Alert elevation={6} variant="filled" onClose={closeError} severity="error">{errorMessage}</Alert>
            </Snackbar>
        </Fragment>
    )
}
export default Alerts;