import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Container, Grid, Typography } from '@mui/material';
import { mediumBlue } from '../styled/theme/Variables';

const Loading = ( props ) => {

    // ----------------------------------------------------
    // PROPERTIES
    // ----------------------------------------------------

    const { text } = props;


    // ----------------------------------------------------
    // RENDER LOADING UI
    // ----------------------------------------------------

    return (
        <Container>
            <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={5} sx={{height:"80vh"}}>
                <Grid item>
                    <Typography align="center" color={mediumBlue}>
                        <strong>{ text ? text : 'Loading data...' }</strong>
                    </Typography>
                </Grid>
                <Grid item>
                    <CircularProgress color="primary"/>
                </Grid>
            </Grid>
        </Container>
    );
}
export default Loading;