import { Image } from "@aws-amplify/ui-react";
import { Grid, Typography } from "@mui/material";
import { FeaturedConditionsContainer, FeaturedConditionsInnerContainer } from "../../styled/containers/Containers";


// --------------------------------------------------------
// FEATURED CONDITIONS SECTION
// --------------------------------------------------------

export default function FeaturedConditions (props) {

    // RENDER FEATURED CONDITIONS
    return (
        <FeaturedConditionsContainer maxWidth={false} disableGutters>
            <FeaturedConditionsInnerContainer>
                <Typography variant="h3">Featured Conditions</Typography>
                <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-anxiety-depression.png" alt="Anxiety and Depression" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-arthritis.png" alt="Arthritis" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-caregiving.png" alt="Caregiving" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-chrons.png" alt="Chrons" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-copd.png" alt="COPD" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-heart-disease.png" alt="Heart Disease" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-liver-disease.png" alt="Liver Disease" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-long-covid.png" alt="Long COVID" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-migraines.png" alt="Migraines" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-psoriasis.png" alt="Psoriasis" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-skin-cancer.png" alt="Skin Cancer" width="233" height="167"></Image>
                    </Grid>
                    <Grid item>
                        <Image className="featuredConditionImage" src="/images/home/featured-condition-type-2-diabetes.png" alt="Type 2 Diabetes" width="233" height="167"></Image>
                    </Grid>
                </Grid>
            </FeaturedConditionsInnerContainer>
        </FeaturedConditionsContainer>
    )
}