import { Box, Typography } from "@mui/material";
import { PatientInterviewsContainer, PatientInterviewsInnerContainer } from "../../styled/containers/Containers";
import useDialogs from "../../global/dialogs/useDialogs";
import { darkBlue, mediumBlue } from "../../styled/theme/Variables";


// --------------------------------------------------------
// PATIENT INTERVIEWS SECTION
// --------------------------------------------------------

export default function PatientInterviews (props) {

    // PROPERTIES -----------------------------------------

    const dialogs = useDialogs();

    // CONTENT STYLES
    const contentWrap = {
        maxWidth: "42%",
        margin: "auto",
        textAlign: "center",
    }



    // RENDER SECTION -------------------------------------
    return (
        <PatientInterviewsContainer maxWidth={false} disableGutters>
            <PatientInterviewsInnerContainer>
                <Box sx={contentWrap}>
                    <Typography variant={"h3"}>Exclusive Patient Roundtables</Typography>
                    <Typography variant={"body"}>In addition to accessing an exclusive collection of films and reports, HealthImmersion members will be able to partake in virtual facilitated discussions with key patients from the docuseries. This allows members to ask additional questions, drill deeper in areas of specific interest, and solicit patient feedback on new stratgies or offerings.</Typography>
                    <Box sx={{paddingTop: "15px"}}>
                        <Typography variant={"body"} color={darkBlue}><button className="underlinedLink asText" onClick={dialogs.showContactNonAuth}>Contact us for details</button></Typography>
                    </Box>
                </Box>
            </PatientInterviewsInnerContainer>
        </PatientInterviewsContainer>
    )
}