import { Box, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Pagination, Select, Tooltip, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { GreenButton } from "../styled/buttons/Buttons";
import { VideoGridFiltersContainer, VideoGridResultsContainer } from "../styled/containers/Containers";
import { mediumBlue } from "../styled/theme/Variables";
import { ResultsGridItem } from "../styled/results/ResultGridItem";
import useSession from "../global/session/useSession";
import { JSONPath } from "jsonpath-plus";


// --------------------------------------------------------
// VIDEOS GRID / FILTERS
// --------------------------------------------------------

export function VideosGrid( props ) {


    // ----------------------------------------------------
	// PROPERTIES
	// ----------------------------------------------------

    const session = useSession();
    const [filteredVideos, setFilteredVideos] = useState(props.videos);
    const [pageSize, setPageSize] = useState(12);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(Math.ceil(filteredVideos.length / pageSize));


    // ----------------------------------------------------
	// HANDLE PAGINATION
	// ----------------------------------------------------

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    }

    // ----------------------------------------------------
	// GET CURRENT PAGE VIDEOS
	// ----------------------------------------------------

    const getCurrentPageVideos = () => {
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = startIndex + pageSize;
        let videos = filteredVideos.slice(startIndex, endIndex);
        return videos;
    }


    // ----------------------------------------------------
	// FILTER STYLING
	// ----------------------------------------------------

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    // ----------------------------------------------------
	// HANDLE FILTERING
	// ----------------------------------------------------

    const [selectedConditions, setSelectedConditions] = useState([]);
    const [selectedImpacts, setSelectedImpacts] = useState([]);
    const [selectedRespondents, setSelectedRespondents] = useState([]);

    const handleConditionsChange = (event) => {
        const { target: { value }, } = event;
        filterVideos(typeof value === 'string' ? value.split(',') : value, selectedImpacts, selectedRespondents);
    };
    const handleImpactsChange = (event) => {
        const { target: { value }, } = event;
        filterVideos(selectedConditions, typeof value === 'string' ? value.split(',') : value, selectedRespondents);
    };
    const handleRespondentsChange = (event) => {
        const { target: { value }, } = event;
        filterVideos(selectedConditions, selectedImpacts, typeof value === 'string' ? value.split(',') : value);
    };
    const clearFilters = (event) => {
        filterVideos([],[],[]);
    };


    // ----------------------------------------------------
	// FILTER VIDEOS
	// ----------------------------------------------------

    const filterVideos = (conditions, impacts, respondents) => {

        if (conditions.length === 0 && impacts.length === 0 && respondents.length === 0){
            let videos = [...props.videos];
            console.log("Filtered Videos: ", videos);
            setFilteredVideos(videos);
            setTotalPages(Math.ceil(props.videos.length / pageSize));
            setCurrentPage(1);
        } else {

            let videosRef = [];
            let conditionVideos = [];
            let impactVideos = [];
            let respondentVideos = [];

            // FILTER BY CONDITIONS
            if (props.conditions){
                for (let a = 0 ; a < conditions.length ; a++){
                    for (let b = 0 ; b < props.conditions.length ; b++){
                        if (props.conditions[b].name == conditions[a]){
                            for (let c = 0 ; c < props.conditions[b].videos.items.length ; c++){
                                conditionVideos.push(session.getVideoByID(props.conditions[b].videos.items[c].videoID));
                            }
                        }
                    }
                }
                videosRef = videosRef.concat(conditionVideos);
            }

            // FILTER BY IMPACTS
            if (props.impacts){
                for (let a = 0 ; a < impacts.length ; a++){
                    for (let b = 0 ; b < props.impacts.length ; b++){
                        if (props.impacts[b].name == impacts[a]){
                            for (let c = 0 ; c < props.impacts[b].videos.items.length ; c++){
                                if (!props.conditions){
                                    let filteredVideoCheck = props.videos.filter(video => video.id === props.impacts[b].videos.items[c].videoID);
                                    if (filteredVideoCheck.length > 0){
                                        impactVideos.push(session.getVideoByID(props.impacts[b].videos.items[c].videoID));
                                    }
                                } else {
                                    impactVideos.push(session.getVideoByID(props.impacts[b].videos.items[c].videoID));
                                }
                            }
                        }
                    }
                }
                videosRef = videosRef.concat(impactVideos);
            }

            // FILTER BY RESPONDENTS
            if (props.respondents){
                for (let a = 0 ; a < respondents.length ; a++){
                    for (let b = 0 ; b < props.respondents.length ; b++){
                        if (props.respondents[b].name === respondents[a]){
                            for (let c = 0 ; c < props.respondents[b].videos.items.length ; c++){
                                if (!props.conditions){
                                    let filteredVideoCheck = props.videos.filter(video => video.id === props.respondents[b].videos.items[c].videoID);
                                    if (filteredVideoCheck.length > 0){
                                        respondentVideos.push(session.getVideoByID(props.respondents[b].videos.items[c].videoID));
                                    }
                                } else {
                                    respondentVideos.push(session.getVideoByID(props.respondents[b].videos.items[c].videoID));
                                }
                            }
                        }
                    }
                }
                videosRef = videosRef.concat(respondentVideos);
            }
            const updatedFilteredVideos = [...new Set([...videosRef])];
            let results = updatedFilteredVideos.sort( sortResultsByName );
            console.log("Filtered Videos: ", updatedFilteredVideos);
            setFilteredVideos( updatedFilteredVideos );
            setTotalPages(Math.ceil(updatedFilteredVideos.length / pageSize));
            setCurrentPage(1);
        }
        setSelectedConditions(conditions);
        setSelectedImpacts(impacts);
        setSelectedRespondents(respondents);
    }

    // ----------------------------------------------------
    // SORT RESULT ARRAYS
    // ----------------------------------------------------

    const sortResultsByName = (a, b) => {
        if ( a.name < b.name ){ return -1; }
        if ( a.name > b.name ){ return 1; }
        return 0;
    }

    // ----------------------------------------------------
	// GET DISABLED STATUS
	// ----------------------------------------------------

    function getDisabledStatus(video) {
        
        for (var a = 0 ; a < video.conditions.items.length ; a++){
            let conditionID = video.conditions.items[a].conditionID;
            for (var b = 0 ; b < session.account.conditions.items.length ; b++){
                if (conditionID === session.account.conditions.items[b].conditionID){
                    return false;
                }
            }
        }
        return true;
    }


    // ----------------------------------------------------
	// RENDER CONDITION COMPONENT
	// ----------------------------------------------------

	return (
        <Fragment>
            {console.log("Videos: ", filteredVideos)}
            <VideoGridFiltersContainer>
                <Typography color={mediumBlue} variant={"h5"} sx={{marginBottom:"20px", fontWeight:600}}>Filter your search:</Typography> 
                <Grid container rowSpacing={1} justifyContent={"flex-start"} alignItems={"center"}>
                    
                    { props.conditions && props.conditions.length > 0
                        ? <Grid item>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel id="conditions-label">Conditions</InputLabel>
                                    <Select labelId="conditions-label" multiple value={ selectedConditions }
                                        onChange={ handleConditionsChange }
                                        input={ <OutlinedInput label="Conditions"/> }
                                        renderValue={ (selected) => selected.join(', ') }
                                        MenuProps={MenuProps} >
                                        { props.conditions.map((condition, index) => (
                                            <MenuItem value={condition.name} key={index}> 
                                                {/* disabled={(Math.random() < 0.5)} */}
                                                <Checkbox checked={selectedConditions.indexOf(condition.name) > -1} />
                                                <ListItemText primary={condition.name} primaryTypographyProps={{fontSize: '14px'}} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                        </Grid>
                        : <></>
                    }

                    {props.impacts.length > 0
                        ? <Grid item>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel id="impacts-label">Impacts</InputLabel>
                                    <Select labelId="impacts-label" multiple value={ selectedImpacts }
                                        onChange={ handleImpactsChange }
                                        input={ <OutlinedInput label="Impacts"/> }
                                        renderValue={ (selected) => selected.join(', ') }
                                        MenuProps={MenuProps} >
                                        { props.impacts.map((impact, index) => (
                                            <MenuItem key={index} value={impact.name}>
                                                <Checkbox checked={selectedImpacts.indexOf(impact.name) > -1} />
                                                <ListItemText primary={impact.name} primaryTypographyProps={{fontSize: '14px'}}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                        </Grid>
                        : <></>
                    }

                    {props.respondents.length > 0
                        ?   <Grid item>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                    <InputLabel id="respondents-label">Respondents</InputLabel>
                                    <Select labelId="respondents-label" multiple value={ selectedRespondents }
                                        onChange={ handleRespondentsChange }
                                        input={ <OutlinedInput label="Respondents"/> }
                                        renderValue={ (selected) => selected.join(', ') }
                                        MenuProps={MenuProps} >
                                        { props.respondents.map((respondents, index) => (
                                            <MenuItem key={index} value={respondents.name}>
                                                <Checkbox checked={selectedRespondents.indexOf(respondents.name) > -1} />
                                                <ListItemText primary={respondents.name} primaryTypographyProps={{fontSize: '14px'}}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                        </Grid>
                        : <></>
                    }
                    <Grid item>
                        <GreenButton variant="contained" size="large" sx={{ m:1 }} onClick={clearFilters}>Clear Filters</GreenButton>
                    </Grid>
                </Grid>
            </VideoGridFiltersContainer>
            <VideoGridResultsContainer>
                <Grid container rowSpacing={4} columnSpacing={4} justifyContent={"flex-start"} alignItems={"flex-start"}>
                    { getCurrentPageVideos()?.map((video, index) => (
                        <Grid item xs={3} key={index}>
                            <ResultsGridItem result={video} disabled={getDisabledStatus(video)} />
                        </Grid>
                    ))}
                </Grid>
                <Grid container justifyContent={"center"} alignItems={"center"}>
                    <Box sx={{margin: "60px 0px 30px 0px"}} >
                        <Pagination page={currentPage} count={totalPages} shape="rounded" size="large" onChange={handlePageChange}  />
                    </Box>
                </Grid>
            </VideoGridResultsContainer>
        </Fragment>
    )
}