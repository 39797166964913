import { Grid, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Loading from "../loading/Loading";
import { OrangeRoundedButton } from "../styled/buttons/Buttons";
import { DashboardContainer, DashboardHeroConditionsContainer, DashboardHeroContainer } from "../styled/containers/Containers";
import { darkGray, mainWhite, mediumBlue } from "../styled/theme/Variables";
import { useNavigate } from "react-router-dom";
import useSession from "../global/session/useSession";
import { VideosGrid } from "../videos/VideosGrid";
import { styled } from '@mui/material/styles';
import useDialogs from "../global/dialogs/useDialogs";


// --------------------------------------------------------
// DASHBOARD COMPONENT
// --------------------------------------------------------

export function Dashboard() {
	
    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------

    const navigate = useNavigate();
	const session = useSession();
    const dialogs = useDialogs();
    const [isLoading, setIsLoading] = useState(true);


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        // setFilteredVideos([...session.videos]);
        setIsLoading(false);
    }, []);


    // ----------------------------------------------------
	// NAVIGATE TO CONDITON
	// ----------------------------------------------------

    function navigatoToCondition(id) {
		navigate("/condition/" + id);
	}

    // ----------------------------------------------------
	// CUSTOM WIDTH TOOLTIP
	// ----------------------------------------------------

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} placement="top" arrow/>
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 400,
            padding: 15,
            backgroundColor: darkGray,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: darkGray,
        },
    });


    // ----------------------------------------------------
	// GET DISABLED STATUS
	// ----------------------------------------------------

    function getDisabledStatus(conditionID) {
        for (let i = 0; i < session.account.conditions.items.length ; i++) {
            let accountCondition = session.account.conditions.items[i];
            if (accountCondition.conditionID === conditionID) {
                return false;
            }
        }
        return true;
    }

    // ----------------------------------------------------
	// GET CONDITION BUTTON
	// ----------------------------------------------------

    const getConditionButton = (condition) => {
        let disbled = getDisabledStatus(condition.id);
        if (disbled){
            return (
                <CustomWidthTooltip title={
                    <Typography component={"div"} color={mainWhite} variant={"body2"}>
                        <div>
                            This content is not available to your account. If you would like access, please <a className="underlinedLinkNoRollover asText" onClick={dialogs.showContactAuth}>contact us</a>.
                        </div>
                    </Typography>}>
                    <div>
                        <OrangeRoundedButton disabled={true} variant="contained" onClick={()=>{navigatoToCondition(condition.id)}}>{condition.name}</OrangeRoundedButton>   
                    </div>
                </CustomWidthTooltip>
            )
        } else {
            return (
                <OrangeRoundedButton variant="contained" onClick={()=>{navigatoToCondition(condition.id)}}>{condition.name}</OrangeRoundedButton>   
            )
        }
    }

	// ----------------------------------------------------
	// RENDER DASHBOARD COMPONENT
	// ----------------------------------------------------

	return (
        <Fragment>
            { isLoading === true
                ? <Loading text={"Loading > Please Wait..."}/>
                : <DashboardContainer maxWidth={false} disableGutters>
                    <DashboardHeroContainer maxWidth={false} >
                        
                        {/* HERO HEADER TEXT */}
                        <Grid container rowSpacing={1} justifyContent={"center"} alignItems={"center"} direction={"column"}>
                            <Grid item>
                                <Typography color={mediumBlue} variant={"h1"} sx={{marginBottom:"10px"}}>Welcome To HealthImmersion</Typography>    
                            </Grid>
                            <Grid item>
                                <Typography>Choose from the conditions below, or use the search or filters to find a film.</Typography>
                            </Grid>
                        </Grid>

                        {/* CONDITION BUTTONS */}
                        <DashboardHeroConditionsContainer>
                            <Grid container rowSpacing={2} columnSpacing={2} justifyContent={"center"} alignItems={"center"}>
                                { session.conditions?.map((condition, index) => (
                                    <Grid item key={index}>
                                        { getConditionButton(condition) }
                                    </Grid>
                                ))}
                            </Grid>
                        </DashboardHeroConditionsContainer>
                    </DashboardHeroContainer>

                    {/* FILTERS / GRID */}
                    <VideosGrid videos={session.videos} conditions={session.conditions} impacts={session.impacts} respondents={session.respondents} />

                </DashboardContainer>
            }
        </Fragment>
    );
}