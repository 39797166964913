import { Image, useAuthenticator } from "@aws-amplify/ui-react";
import { Avatar, Backdrop, Box, Divider, Grid, IconButton, Menu, MenuItem, Typography, Link as MUILink, Paper, InputBase } from "@mui/material";
import { GreenButton, PurpleButton } from "../../styled/buttons/Buttons";
import { HeaderContainer, HeaderInnerContainer } from "../../styled/containers/Containers";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { darkBlue, lightBlue, mainWhite, mediumGray } from "../../styled/theme/Variables";
import useSession from "../../global/session/useSession";
import { S3MenuImage } from "../../styled/images/S3MenuImage";
import { Link } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import useDialogs from "../../global/dialogs/useDialogs";


// --------------------------------------------------------
// HEADER COMPONENT
// --------------------------------------------------------

export default function Header() {


    // ----------------------------------------------------
    // PROPERTIES
    // ----------------------------------------------------

    const { route, signOut } = useAuthenticator((context) => [context.route, context.signOut]);
    const navigate = useNavigate();
    const session = useSession();
    const dialogs = useDialogs();
    const location = useLocation();


    // ----------------------------------------------------
    // GET USER INITIALS
    // ----------------------------------------------------

    const getUserInitials = () => {
        let firstInitial = session.getUserAttribute(session.FIRST_NAME)[0];
        let lastInitial = session.getUserAttribute(session.LAST_NAME)[0];
        return {
            sx: { ml: 3, bgcolor: lightBlue, padding: 3, color: { darkBlue } },
            children: <Typography variant="h5" color={darkBlue} sx={{ marginTop: 0.5 }}>{firstInitial}{lastInitial}</Typography>
        }
    }

    // ----------------------------------------------------
    // GET ACCOUNT NAME
    // ----------------------------------------------------

    const getAccountName = () => {
        return session.getUserAttribute(session.ACCOUNT_NAME);
    }


    // ----------------------------------------------------
    // DROPDOWN MENU HANDLERS
    // ----------------------------------------------------

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function logIn() {
        navigate("/login");
    }
    function logOut() {
        signOut();
        navigate("/");
    }


    // ----------------------------------------------------
    // FORM SUBMIT HANDLER
    // ----------------------------------------------------

    const onFormSubmit = (event) => {
        event.preventDefault();
        navigate("/search", { state: { searchQuery: event.target[0].value }});
    }


    // ----------------------------------------------------
    // RENDER HEADER UI
    // ----------------------------------------------------

    return (
        <HeaderContainer maxWidth={false} disableGutters>
            <HeaderInnerContainer>
                <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ minHeight: "100px" }}>
                    <Grid item>
                        <Grid container justifyContent={"flex-start"} alignItems={"center"} columnSpacing={4}>
                            <Grid item>
                                {route === "authenticated"
                                    ? <MUILink component={Link} to="/dashboard">
                                        <Image src="/images/global/health-immersion-logo-light.png" alt="Health Immersion by Dig" width="311" height="37" />
                                    </MUILink>
                                    : <MUILink component={Link} to="/">
                                        <Image src="/images/global/health-immersion-logo-light.png" alt="Health Immersion by Dig" width="311" height="37" />
                                    </MUILink>
                                }
                            </Grid>
                            <Grid item>
                                {route === "authenticated" && location.pathname.indexOf("/admin") === -1
                                    ? <Paper component="form" sx={{ p: '0px 4px', display: 'flex', alignItems: 'center', minWidth: 300 }} onSubmit={onFormSubmit}>
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Search Films"
                                            inputProps={{ 'aria-label': 'search films' }}
                                        />
                                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                    </Paper>
                                    : <></>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            {route === "authenticated"
                                ? <Fragment>
                                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                        <h4 style={{ color: lightBlue }}>{getAccountName()}</h4>
                                        <Avatar {...getUserInitials()} />
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 2, color: mainWhite }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}>
                                            <MenuIcon sx={{ width: 32, height: 32 }} />
                                        </IconButton>
                                    </Box>
                                    <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1, opacity: 0.9 }} open={open} onClick={handleClose}>
                                        <Menu
                                            anchorEl={anchorEl} id="account-menu" open={open}
                                            onClose={handleClose}
                                            onClick={handleClose}
                                            PaperProps={{ elevation: 1, sx: { minWidth: 250, maxWidth: 250 } }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                                            <S3MenuImage imagePath={session.account.logo.key} imageName={session.account.logo.name} />
                                            <Divider sx={{ marginBottom: 1, marginTop: 1 }} />

                                            {/* SHOW / HIDE ADMIN MENU */}
                                            {session.getUserAttribute(session.ACCESS_LEVEL) === "SuperAdmins" || session.getUserAttribute(session.ACCESS_LEVEL) === "Admins"
                                                ? <div>
                                                    <MenuItem onClick={() => navigate("/admin/films")}>
                                                        <Typography>Films</Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => navigate("/admin/conditions")}>
                                                        <Typography>Conditions</Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => navigate("/admin/impacts")}>
                                                        <Typography>Impacts</Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => navigate("/admin/respondents")}>
                                                        <Typography>Respondents</Typography>
                                                    </MenuItem>
                                                    <Divider />
                                                    <MenuItem onClick={() => navigate("/admin/accounts")}>
                                                        <Typography>Accounts</Typography>
                                                    </MenuItem>
                                                    <Divider />
                                                </div>
                                                : <div />
                                            }

                                            {/* SHOW / HIDE ACCOUNT MENU */}
                                            {session.getUserAttribute(session.ACCESS_LEVEL) === "AccountAdmins"
                                                ? <div>
                                                    <MenuItem onClick={() => navigate("/admin/users/" + session.account.id)}>
                                                        <Typography>Manager Users</Typography>
                                                    </MenuItem>
                                                    <Divider />
                                                </div>
                                                : null
                                            }

                                            {/* <MenuItem disabled onClick={() => navigate("/admin/profile")}>
                                                <Typography>My Profile</Typography>
                                            </MenuItem> */}
                                            <MenuItem onClick={dialogs.showContactAuth}>
                                                <Typography>Support</Typography>
                                            </MenuItem>
                                            <MenuItem onClick={logOut}>
                                                <Typography>Logout</Typography>
                                            </MenuItem>
                                        </Menu>
                                    </Backdrop>
                                </Fragment>
                                : <Fragment>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <GreenButton variant="contained" onClick={dialogs.showContactNonAuth}>Get Access</GreenButton>
                                        </Grid>
                                        <Grid item>
                                            <PurpleButton variant="contained" onClick={() => logIn()}>Login</PurpleButton>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </HeaderInnerContainer>
        </HeaderContainer>
    )
}