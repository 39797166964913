
// --------------------------------------------------------
// CREATE ACCOUNT
// --------------------------------------------------------

export const createAccount = /* GraphQL */ `
	mutation CreateAccount(
		$input: CreateAccountInput!
		$condition: ModelAccountConditionInput
	) {
	createAccount(input: $input, condition: $condition) {
		id
		name
	}
}
`;

// --------------------------------------------------------
// CREATE COGNITO USER
// --------------------------------------------------------

export const createCognitoUser = /* GraphQL */ `
	mutation CreateCognitoUser(
		$role: String!
		$accountName: String!
		$firstName: String!
		$lastName: String!
		$emailAddress: String!
		$locale: String
		$accountID: String
	) {
	createCognitoUser(
		role: $role
		accountName: $accountName
		firstName: $firstName
		lastName: $lastName
		emailAddress: $emailAddress
		locale: $locale
		accountID: $accountID
	)
}
`;

// --------------------------------------------------------
// DELETE COGNITO USER
// --------------------------------------------------------

export const deleteCognitoUser = /* GraphQL */ `
	mutation DeleteCognitoUser($username: String!) {
		deleteCognitoUser(username: $username)
	}
`;


// --------------------------------------------------------
// DELETE COGNITO USER
// --------------------------------------------------------

export const forcePasswordReset = /* GraphQL */ `
	mutation ForcePasswordReset($username: String!) {
		forcePasswordReset(username: $username)
	}
`;