
// --------------------------------------------------------
// HOME PAGE COMPONENT
// --------------------------------------------------------

import { Fragment } from "react";
import { ContentInnerContainer } from "../styled/containers/Containers";
import HomeHero from "./hero/HomeHero";
import Benefits from "./sections/Benefits";
import BottomSignup from "./sections/BottomSignup";
import ExampleDocuseries from "./sections/ExampleDocuseries";
import FeaturedConditions from "./sections/FeaturedConditions";
import PatientInterviews from "./sections/PatientInterviews";

export function Home() {

	// ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------


	// ----------------------------------------------------
	// RENDER HOME PAGE COMPONENT 
	// ----------------------------------------------------

	return (
		<Fragment>
			<ContentInnerContainer maxWidth={false} disableGutters>
				<HomeHero />
				<Benefits />
				<FeaturedConditions />
				<ExampleDocuseries />
				<PatientInterviews />
				<BottomSignup />
			</ContentInnerContainer>
		</Fragment>
	);
}