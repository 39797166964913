import { Breadcrumbs, Grid, Link as MUILink, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Loading from "../loading/Loading";
import { OrangeRoundedButton } from "../styled/buttons/Buttons";
import { ConditionContainer, ConditionHeroContainer, ConditionHeroInnerContainer } from "../styled/containers/Containers";
import { mediumBlue } from "../styled/theme/Variables";
import { Link, useNavigate, useParams } from "react-router-dom";
import useSession from "../global/session/useSession";
import { VideosGrid } from "../videos/VideosGrid";
import { RespondentGridItem } from "../styled/results/RespondentGridItem";
import DownloadIcon from '@mui/icons-material/Download';
import { Storage } from "aws-amplify";


// --------------------------------------------------------
// CONDITION COMPONENT
// --------------------------------------------------------

export function Condition() {
	
    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------

    const { id } = useParams();
    const navigate = useNavigate();
    const session = useSession();
    const [isLoading, setIsLoading] = useState(true);
    const [condition, setCondition] = useState();
    const [conditionImpacts, setConditionImpacts] = useState();
    const [conditionRespondents, setConditionRespondents] = useState();
    const [conditionVideos, setConditionVideos] = useState();
    const [conditionReport, setConditionReport] = useState();
    const [conditionReportName, setConditionReportName] = useState();


    // ----------------------------------------------------
	// GET VIEW DATA
	// ----------------------------------------------------

    const getViewData = async () => {
        let videos = session.getVideosByConditionID(id);
        let currentConditon = session.getConditionByID(id);
        setCondition(currentConditon);
        setConditionImpacts(session.getImpactsByConditionID(id));
        setConditionRespondents(session.getRespondentsByConditionID(id));
        setConditionVideos(videos);
        getReportURL(currentConditon.document.key);
        setIsLoading(false);
    }


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        getViewData();
    }, []);


    // ----------------------------------------------------
	// GET REPORT URL
	// ----------------------------------------------------

    const getReportURL = async (key) => {
        var pathCheck = key.split("/");
        if (pathCheck[0] === "public"){
            key = key.substring(key.indexOf('/') + 1)
        }
        var reportBlob = await Storage.get(key, { download: true});
        setConditionReport(reportBlob.Body);
        setConditionReportName(pathCheck[pathCheck.length-1]);
    }

    // ----------------------------------------------------
	// DOWNLOAD REPORT PDF
	// ----------------------------------------------------

    function downloadReport() {
        const url = URL.createObjectURL(conditionReport);
        const a = document.createElement('a');
        a.href = url;
        a.download = conditionReportName || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    
	// ----------------------------------------------------
	// RENDER CONDITION COMPONENT
	// ----------------------------------------------------

	return (
        <Fragment>
            { isLoading === true
                ? <Loading text={"Loading > Please Wait..."}/>
                : <ConditionContainer maxWidth={false} disableGutters>
                    <ConditionHeroContainer maxWidth={false} >
                        
                        {/* HERO CONTENT */}
                        <ConditionHeroInnerContainer>
                            <Grid container spacing={10} justifyContent={"space-between"} alignItems={"flex-start"} direction={"row"} wrap={"nowrap"}>
                                <Grid item>

                                    {/* HERO CONTENT LEFT*/}
                                    <Grid container rowSpacing={1} justifyContent={"center"} alignItems={"flex-start"} direction={"column"}>
                                        <Grid item>
                                            <Breadcrumbs aria-label="breadcrumb">
                                                <MUILink component={Link} to="/dashboard">
                                                    <Typography>All Films & Conditions</Typography>
                                                </MUILink>
                                                <Typography>{ condition.name }</Typography>
                                            </Breadcrumbs>
                                        </Grid>
                                        <Grid item>
                                            <Typography color={mediumBlue} variant={"h1"} sx={{marginBottom:"10px", marginTop:"20px"}}>{ condition.name }</Typography>  
                                        </Grid>
                                        <Grid item>
                                            <Typography >{ condition.description }</Typography>
                                        </Grid>
                                        {condition.document.key === ""
                                            ? null
                                            : <Grid item>
                                                <OrangeRoundedButton startIcon={<DownloadIcon />} variant="contained" size="large" sx={{marginTop:"20px"}} onClick={() => downloadReport()}>Journey Map</OrangeRoundedButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item minWidth={"50%"}>
                                
                                    <Grid item>
                                        <Typography color={mediumBlue} sx={{marginBottom:"15px"}}>
                                            <strong>{ "Respondents:" }</strong>
                                        </Typography>
                                    </Grid>

                                    <Grid container columnSpacing={3} rowSpacing={3}>
                                        { conditionRespondents?.map((respondent, index) => (
                                            <Grid item key={index}>
                                                <RespondentGridItem respondent={respondent} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ConditionHeroInnerContainer>
                    </ConditionHeroContainer>

                    {/* FILTERS / GRID */}
                    <VideosGrid videos={conditionVideos} impacts={conditionImpacts} respondents={conditionRespondents} />

                </ConditionContainer>
            }
        </Fragment>
    );
}