import { Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import useSession from "../../global/session/useSession";
import Loading from "../../loading/Loading";
import { PurpleButton } from "../../styled/buttons/Buttons";
import { AdminContainer, AdminContentContainer, AdminHeroContainer, AdminHeroInnerContainer } from "../../styled/containers/Containers";
import { mediumBlue } from "../../styled/theme/Variables";
import DataGrid from "../modules/DataGrid";
import useAPI from "../../global/api/useAPI";
import useDialogs from "../../global/dialogs/useDialogs";
import { AddRespondent } from "./AddRespondent";
import { EditRespondent } from "./EditRespondent";


// --------------------------------------------------------
// RESPONDENTS COMPONENT
// --------------------------------------------------------

export function Respondents( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------

    const api = useAPI();
    const dialogs = useDialogs();
    const session = useSession();
    const [isLoading, setIsLoading] = useState(true);
    
    const [respondents, setRespondents] = useState([]);
    const [newRespondentOpen, setNewRespondentOpen] = useState(false);
    const [editRespondentOpen, setEditRespondentOpen] = useState(false);
    const [currentEditRespondent, setCurrentEditRespondent] = useState(null);


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        setRespondents(session.respondents);
        setIsLoading(false);
    }, []);


    // ----------------------------------------------------
	// NEW CONDITION
	// ----------------------------------------------------

    const newItem = (id) => {
        setNewRespondentOpen(true)
    }


    // ----------------------------------------------------
	// EDIT CONDITION
	// ----------------------------------------------------

    const editItem = (id) => {
        let currentRespondent = respondents.filter(respondent => respondent.id === id)[0];
        setCurrentEditRespondent(currentRespondent);
        setEditRespondentOpen(true);
    }

    // ----------------------------------------------------
	// DELETE CONDITION
	// ----------------------------------------------------

    const deleteItem = (id) => {
        let title = "Delete Respondent";
        let message = "Are you sure you want to delete this Respondent?";
        dialogs.showConfirmation(title, message, () => doDelete(id), () => { });
    }
    const doDelete = async (id) => {
        dialogs.showProgress("Deleting Respondent > Please wait...");
        await api.removeRespondent(id);
        dialogs.closeProgress();
    }

    // ----------------------------------------------------
	// CLOSE / RESET DIALOGS
	// ----------------------------------------------------

    const handleNewClose = () => {
        setNewRespondentOpen(false);
    };
    const handleEditClose = () => {
        setEditRespondentOpen(false)
    };


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

    return (
        <Fragment>
            { isLoading === true
                ? <Loading text={"Loading > Please Wait..."}/>
                : <AdminContainer maxWidth={false} disableGutters>
                    <AdminHeroContainer maxWidth={false} disableGutters>
                        <AdminHeroInnerContainer>
                            <Grid container direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <Typography variant={"h4"} color={mediumBlue}><strong>Manage Respondents</strong></Typography>
                                </Grid>
                                <Grid item>
                                    <PurpleButton variant="contained" onClick={newItem}>Add New Respondent</PurpleButton>
                                </Grid>
                            </Grid>
                        </AdminHeroInnerContainer>
                    </AdminHeroContainer>
                    <AdminContentContainer>
                        <DataGrid items={respondents} edit={editItem} delete={deleteItem}/>
                    </AdminContentContainer>
                </AdminContainer>
            }
            <AddRespondent open={newRespondentOpen} close={handleNewClose} />
            <EditRespondent open={editRespondentOpen} close={handleEditClose} respondent={currentEditRespondent} />
         </Fragment>
    )

}

export default Respondents;