import { createTheme } from '@mui/material/styles';
import { fontPrimaryBodyColor, fontPrimaryHeadingColor, primaryColor, secondaryColor, mediumBlue } from './Variables.js';

// --------------------------------------------------------
// HEALTH IMMERSION THEME
// --------------------------------------------------------

export const theme = createTheme({
    palette: {
        primary: {
            main: mediumBlue,
        },
        secondary: {
            main: secondaryColor,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1350, //previously 1440
            xl: 1920,
        },
    },
    typography: {
        h1: {
            fontFamily: "poppins, sans-serif",
            fontWeight: 700,
            fontSize: "38px",
            lineHeight: "1.25",
            color:mediumBlue,
            marginBottom: "30px",
        },
        h2: {
            fontFamily: "poppins, sans-serif",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "1.5",
            color:fontPrimaryHeadingColor,
        },
        h3: {
            fontFamily: "poppins, sans-serif",
            fontWeight: 700,
            fontSize: "36px",
            color:mediumBlue,
            marginBottom: "40px",
        },
        h4: {
            fontFamily: "poppins, sans-serif",
            fontWeight: 400,
            fontSize: "24px",
            color:fontPrimaryHeadingColor,
        },
        h5: {
            fontFamily: "poppins, sans-serif",
            fontWeight: 400,
            fontSize: "20px",
            color:fontPrimaryHeadingColor,
        },
        h6: {
            fontFamily: 'poppins, sans-serif',
            fontWeight: 400,
            fontSize: "16px",
            color:fontPrimaryHeadingColor,
        },
        body1: {
            fontFamily: "poppins, sans-serif",
            fontWeight: 400,
            fontSize: "16px",
            color: fontPrimaryBodyColor,
        },
        body2: {
            fontFamily: "poppins, sans-serif",
            fontWeight: 400,
            fontSize: "14px",
            color: fontPrimaryBodyColor,
        },
        body3: {
            fontFamily: "poppins, sans-serif",
            fontWeight: 400,
            fontSize: "12px",
            color: fontPrimaryBodyColor,
        },
        docuseriesH2: {
            fontFamily: "poppins, sans-serif",
            fontWeight: 500,
            fontSize: "28px",
            lineHeight: "1.5",
            color:fontPrimaryHeadingColor,
        },
        docuseriesH6: {
            fontFamily: 'poppins, sans-serif',
            fontWeight: 400,
            fontSize: "24px",
            color:fontPrimaryHeadingColor,
        },
        docuseriesBody: {
            fontFamily: "poppins, sans-serif",
            fontWeight: 400,
            fontSize: "22px",
            color: fontPrimaryBodyColor,
        },
        button: {
            fontFamily: "poppins, sans-serif",
        },
    },
});