import { Card, CardContent, CardMedia, CircularProgress, Container, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { Storage } from "aws-amplify";
import { JSONPath } from "jsonpath-plus";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { darkBlue, darkGray, mainWhite } from "../theme/Variables";
import { styled } from '@mui/material/styles';
import useDialogs from "../../global/dialogs/useDialogs";


// --------------------------------------------------------
// RESULTS GRID ITEM
// --------------------------------------------------------

export function ResultsGridItem ( props ) {

    // ----------------------------------------------------
	// PROPERTIES
	// ----------------------------------------------------

    const [resultImage, setResultImage] = useState();
    const dialogs = useDialogs();


    // ----------------------------------------------------
	// GET IMAGE URL
	// ----------------------------------------------------

    const getImageURL = async (key) => {
        var signedURL = await Storage.get(key);
        setResultImage(signedURL);
    }


    // ----------------------------------------------------
	// PROCESS CONDITIONS
	// ----------------------------------------------------

    const processConditions = (conditions) => {
        const conditionNames = JSONPath({
            path: "$.items[*].condition.name",
            json: conditions,
        });
        let removeDups = [...new Set(conditionNames)];
        return removeDups.join(', ');
    }


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        getImageURL(props.result.thumbnail.key);
    }, [props]);


    // ----------------------------------------------------
	// LINK / WRAPPER
	// ----------------------------------------------------

    const Wrapper = (props) => {
        if (props.disabled) {
            return (
                <CustomWidthTooltip title={
                    <Typography component={"div"} color={mainWhite} variant={"body2"}>
                        <div>
                            This content is not available to your account. If you would like access, please <a className="underlinedLinkNoRollover asText" onClick={dialogs.showContactAuth}>contact us</a>.
                        </div>
                    </Typography>}>
                    <div style={{opacity: "0.5"}}>
                        {props.children}
                    </div>
                </CustomWidthTooltip>
            )
        } else {
            return (
                <Link to={props.to}>
                    {props.children}
                </Link>
            )
        }
    }

    // ----------------------------------------------------
	// CUSTOM WIDTH TOOLTIP
	// ----------------------------------------------------

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} placement="top" arrow/>
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 400,
            padding: 15,
            backgroundColor: darkGray,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: darkGray,
        },
    });


    // ----------------------------------------------------
	// RENDER RESULTS GRID ITEM COMPONENT
	// ----------------------------------------------------

    return (
        <Wrapper to={"/film/" + props.result.id} disabled={props.disabled}>
            <Card elevation={0} >
                {resultImage ? (
                    <CardMedia sx={{ height: 180, borderRadius:"15px" }} image={resultImage} title={props.result.name} />
                ):(
                    <Container sx={{display:'flex', justifyContent:'center', alignItems: "center", height:"180px"}}>
                        <CircularProgress />
                    </Container>
                )}
                <CardContent sx={{paddingLeft:"0px", paddingRight: "0px"}}>
                    <Typography variant="h6" component="div" color={darkBlue}><strong>{ props.result.name }</strong></Typography>
                    <Typography variant="body2" color={darkGray}>{ processConditions(props.result.conditions) }</Typography>
                </CardContent>
            </Card>
        </Wrapper>
    )
}