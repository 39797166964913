import { Button, IconButton } from "@mui/material";
import { styled } from '@mui/material';
import { red } from "@mui/material/colors";
import { darkBlue, mainBlack, mainWhite, mediumBlue, mediumGreen, mediumOrange, mediumPurple } from "../theme/Variables";


// --------------------------------------------------------
// BASE BUTTON
// --------------------------------------------------------

export const BaseButton = styled ( Button )({
    padding: "10px 15px 7px 15px",
    textTransform: "none",
    minWidth: "80px",
});


// --------------------------------------------------------
// GREEN BUTTON
// --------------------------------------------------------

export const GreenButton = styled ( BaseButton )(({ theme }) => ({
    color: mainWhite,
    fontWeight: 600,
    backgroundColor: mediumGreen,
}));

// --------------------------------------------------------
// GREEN BUTTON XL
// --------------------------------------------------------

export const GreenButtonXL = styled ( GreenButton )(({ theme }) => ({
    padding: "12px 25px",
    fontSize: "1.15rem",
}));

// --------------------------------------------------------
// PURPLE BUTTON
// --------------------------------------------------------

export const PurpleButton = styled ( BaseButton )(({ theme }) => ({
    color: mainWhite,
    fontWeight: 600,
    backgroundColor: mediumPurple,
}));


// --------------------------------------------------------
// ORANGE ROUNDED BUTTON
// --------------------------------------------------------

export const OrangeRoundedButton = styled ( BaseButton )(({ theme }) => ({
    color: mainWhite,
    fontWeight: 600,
    backgroundColor: mediumOrange,
    borderRadius: "25px",
}));


// --------------------------------------------------------
// RED BUTTON
// --------------------------------------------------------

export const RedButton = styled ( BaseButton )(({ theme }) => ({
    color: mainWhite,
    fontWeight: 600,
    backgroundColor: red[500],
}));