import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import Header from "./header/Header";
import Footer from "./footer/Footer";


// --------------------------------------------------------
// RENDER LAYOUT COMPONENT
// --------------------------------------------------------

export function Layout() {
	
	// ----------------------------------------------------
	// CONTAINER STYLES
	// ----------------------------------------------------

    const contentContainer = {
        width: "100%",
    }

	// ----------------------------------------------------
	// RENDER LAYOUT COMPONENT
	// ----------------------------------------------------

	return (
		<Grid container 
			justifyContent={"center"}
			alignItems={"flex-start"}
			direction={"column"}
			style={{ minHeight: '100vh' }}>
			<Grid item style={{ width: "100vw" }}>
				<Header/>
			</Grid>
			<Grid item style={{ width: "100vw", flexGrow: 1 }}>
				<Outlet />
			</Grid>
			<Grid item style={{ width: "100vw" }}>
				<Footer/>
			</Grid>
		</Grid>
	);
}
