import { API, Storage, graphqlOperation } from 'aws-amplify';
import React, { useState } from 'react';
import useAlerts from '../alerts/useAlerts';
import useSession from '../session/useSession';
import { createAccountCondition, createCondition, createImpact, createRespondent, createVideo, createVideoCondition, createVideoImpact, createVideoRespondent, deleteAccount, deleteAccountCondition, deleteCondition, deleteImpact, deleteRespondent, deleteVideo, deleteVideoCondition, deleteVideoImpact, deleteVideoRespondent, updateAccount, updateCognitoUser, updateCondition, updateImpact, updateRespondent, updateVideo } from '../../../graphql/mutations';
import { searchConditions, searchImpacts, searchRespondents, searchVideos } from '../../../graphql/queries';
import { listVideos, listConditions, listImpacts, listRespondents, getAccount, listAccounts, listCognitoUsers } from '../../../graphql/custom/queries';
import { createCognitoUser, deleteCognitoUser, createAccount, forcePasswordReset } from '../../../graphql/custom/mutations';


export default function APIProvider({ children }) {

    // ----------------------------------------------------
    // PROPERTIES
    // ----------------------------------------------------

    const session = useSession();
    const alerts = useAlerts();
    const [isLoadingData, setIsLoadingData] = useState(false);


    // ----------------------------------------------------
    // SORT RESULT ARRAYS
    // ----------------------------------------------------

    const sortResultsByName = (a, b) => {
        if ( a.name < b.name ){ return -1; }
        if ( a.name > b.name ){ return 1; }
        return 0;
    }


    // ----------------------------------------------------
    // GET ACCOUNT
    // ----------------------------------------------------

    const getAccountByID = async (accountID) => {
        try {
            let getAccountParams = { id: accountID };
            let accountByID = await API.graphql(graphqlOperation(getAccount, getAccountParams));
            let response = accountByID.data.getAccount;
            return response;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }
    

    // ----------------------------------------------------
    // GET ACCOUNTS
    // ----------------------------------------------------

    const getAccounts = async () => {
        try {
            let accounts = await API.graphql(graphqlOperation(listAccounts));
            let response = accounts.data.listAccounts.items;
            return response;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // NEW ACCOUNT
    // ----------------------------------------------------

    const newAccount = async (name, logo, conditions) => {
        try {

            console.log("NEW ACCOUNT: ", name, logo, conditions);

            // CREATE ACCOUNT
            const createAccountParams = { input: { name, logo } };
            const newAccount = await API.graphql(graphqlOperation(createAccount, createAccountParams));
            const newAccountID = newAccount.data.createAccount.id;

            // ADD SUBSCRIBED CONDITIONS
            for (let i = 0; i < conditions.length; i++) {
                let createAccountConditionParams = { input: { accountID: newAccountID, conditionID: conditions[i] }};
                let newAccountCondition = await API.graphql(graphqlOperation(createAccountCondition, createAccountConditionParams));
                console.log(newAccountCondition);
            }

            // UPDATE LOCAL DATA
            let updatedAccounts = await getAccounts();
            session.updateAccounts(updatedAccounts);

            // RETURN NEW ACCOUNT
            return newAccount.data.createAccount;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // EDIT ACCOUNT
    // ----------------------------------------------------

    const editAccount = async (currentAccount, accountLogo, conditions) => {
        try {
            console.log('editAccount', currentAccount, accountLogo);
            
            // CLEANUP RELATED DATA
            if (currentAccount.conditions.items.length > 0) {
                currentAccount.conditions.items.forEach(async (condition) => {
                    const deleteAccountConditionParms = { input:{ id:condition.id }};
                    await API.graphql(graphqlOperation(deleteAccountCondition, deleteAccountConditionParms));
                });
            }

            // CREATE NEW RELATED DATA
            if (conditions.length > 0) {
                conditions.forEach(async (condition) => {
                    const newAccountConditionParms = { input:{ accountID:currentAccount.id, conditionID:condition }};
                    await API.graphql(graphqlOperation(createAccountCondition, newAccountConditionParms));
                });
            }

            // UPDATE ACCOUNT
            const updateAccountParams = { input: { id:currentAccount.id, logo:accountLogo } };
            const updatedAccount = await API.graphql(graphqlOperation(updateAccount, updateAccountParams));
            const response = updatedAccount.data.updateAccount;

            // UPDATE LOCAL DATA
            let updatedAccounts = await getAccounts();
            session.updateAccounts(updatedAccounts);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // REMOVE ACCOUNT
    // ----------------------------------------------------

    const removeAccount = async (account) => {
        console.log('removeAccount', account);

        try {

            // LIST ACCOUNT USERS
            const accoutUsersParams = { groupName: account.name };
            const accountUsers = await API.graphql(graphqlOperation(listCognitoUsers, accoutUsersParams));
            const usersResponse = await JSON.parse(accountUsers.data.listCognitoUsers);
            console.log(usersResponse.users.Users);

            // DELETE ACCOUNT USERS
            for (let a = 0; a < usersResponse.users.Users.length; a++) {
                const deleteUserParams = { username: usersResponse.users.Users[a].Username };
                const deleteUser = await API.graphql(graphqlOperation(deleteCognitoUser, deleteUserParams));
                const deletedUserResponse = await JSON.parse(deleteUser.data.deleteCognitoUser);
                console.log("deletedUserResponse", deletedUserResponse);
            }

            // DELETE RELATED DATA
            if (account.conditions.items.length > 0) {
                account.conditions.items.forEach(async (condition) => {
                    const deleteAccountConditionParms = { input:{ id:condition.id }};
                    await API.graphql(graphqlOperation(deleteAccountCondition, deleteAccountConditionParms));
                });
            }

            // DELETE FILES
            if (account.logo.key){
                await Storage.remove(account.logo.key);
            }

            // DELETE ACCOUNT
            const deleteAccountParams = { input: { id: account.id } };
            const deletedAccount = await API.graphql(graphqlOperation(deleteAccount, deleteAccountParams));
            const deletedAccountResponse = deletedAccount.data.deleteAccount;
            console.log('deletedAccountResponse', deletedAccountResponse);

            // DELETE ACCOUNT LOGO
            if (account.logo.key){
                await Storage.remove(account.logo.key);
            }

            // UPDATE LOCAL DATA
            let updatedAccounts = await getAccounts();
            session.updateAccounts(updatedAccounts);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }


    // ----------------------------------------------------
    // LIST GROUP USERS
    // ----------------------------------------------------

    const listGroupUsers = async (groupName, nextToken) => {
        try {
            const listUsersParams = { groupName, nextToken };
            const listUsers = await API.graphql(graphqlOperation(listCognitoUsers, listUsersParams));
            const response = await JSON.parse(listUsers.data.listCognitoUsers);
            return response.users.Users;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }


    // ----------------------------------------------------
    // NEW USER
    // ----------------------------------------------------

    const addNewUser = async (role, accountName, firstName, lastName, emailAddress, locale, accountID) => {
        try {
            const createUserParams = { role, accountName, firstName, lastName, emailAddress, locale, accountID };
            const createUser = await API.graphql(graphqlOperation(createCognitoUser, createUserParams));
            let response = await JSON.parse(createUser.data.createCognitoUser);
            return response;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }


    // ----------------------------------------------------
    // UPDATE USER
    // ----------------------------------------------------

    const updateUser = async (username, firstName, lastName, emailAddress) => {
        try {
            const updateUserParams = { username, firstName, lastName, emailAddress };
            let response = await API.graphql(graphqlOperation(updateCognitoUser, updateUserParams));
            console.log(response);
            return response;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }


    // ----------------------------------------------------
    // UPDATE USER
    // ----------------------------------------------------

    const forceResetPassword = async (username) => {
        try {
            const forceResetParams = { username };
            let response = await API.graphql(graphqlOperation(forcePasswordReset, forceResetParams));
            console.log(response);
            return response;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }


    // ----------------------------------------------------
    // GET ALL VIDEOS
    // ----------------------------------------------------

    const getAllVideos = async () => {
        try {
            let videos = await API.graphql(graphqlOperation(listVideos, {limit:1000}));
            let results = videos.data.listVideos.items.sort( sortResultsByName );
            return results;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // NEW VIDEO
    // ----------------------------------------------------

    const newVideo = async (name, description, conditions, impacts, respondents, thumbnail, video) => {
        try {

            // CREATE VIDEO
            const newVideoParms = { input:{ name, description, thumbnail, video, isFeatured:false }};
            const newVideo = await API.graphql(graphqlOperation(createVideo, newVideoParms));
            
            // CREATE RELATED DATA
            if (conditions.length > 0) {
                conditions.forEach(async (condition) => {
                    const newVideoConditionParms = { input:{ videoID:newVideo.data.createVideo.id, conditionID:condition }};
                    await API.graphql(graphqlOperation(createVideoCondition, newVideoConditionParms));
                });
            }
            if (impacts.length > 0) {
                impacts.forEach(async (impact) => {
                    const newVideoImpactParms = { input:{ videoID:newVideo.data.createVideo.id, impactID:impact }};
                    await API.graphql(graphqlOperation(createVideoImpact, newVideoImpactParms));
                });
            }
            if (respondents.length > 0) {
                respondents.forEach(async (respondent) => {
                    const newVideoRespondentParms = { input:{ videoID:newVideo.data.createVideo.id, respondentID:respondent }};
                    await API.graphql(graphqlOperation(createVideoRespondent, newVideoRespondentParms));
                });
            }

            // UPDATE LOCAL DATA
            let updatedVideos = await getAllVideos();
			let updatedConditions = await getAllConditions();
			let updatedImpacts = await getAllImpacts();
			let updatedRespondents = await getAllRespondents();
			session.updateConditions(updatedConditions);
			session.updateImpacts(updatedImpacts);
			session.updateRespondents(updatedRespondents);
			session.updateVideos(updatedVideos);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }


    // ----------------------------------------------------
    // EDIT VIDEO
    // ----------------------------------------------------

    const editVideo = async (currentVideo, name, description, conditions, impacts, respondents, thumbnail, video) => {
        try {

            // CLEANUP RELATED DATA
            if (currentVideo.conditions.items.length > 0) {
                currentVideo.conditions.items.forEach(async (condition) => {
                    const deleteVideoConditionParms = { input:{ id:condition.id }};
                    await API.graphql(graphqlOperation(deleteVideoCondition, deleteVideoConditionParms));
                });
            }
            if (currentVideo.impacts.items.length > 0) {
                currentVideo.impacts.items.forEach(async (impact) => {
                    const deleteVideoImpactParms = { input:{ id:impact.id }};
                    await API.graphql(graphqlOperation(deleteVideoImpact, deleteVideoImpactParms));
                });
            }
            if (currentVideo.respondents.items.length > 0) {
                currentVideo.respondents.items.forEach(async (respondent) => {
                    const deleteVideoRespondentParms = { input:{ id:respondent.id }};
                    await API.graphql(graphqlOperation(deleteVideoRespondent, deleteVideoRespondentParms));
                });
            }

            // CREATE NEW RELATED DATA
            if (conditions.length > 0) {
                conditions.forEach(async (condition) => {
                    const newVideoConditionParms = { input:{ videoID:currentVideo.id, conditionID:condition }};
                    await API.graphql(graphqlOperation(createVideoCondition, newVideoConditionParms));
                });
            }
            if (impacts.length > 0) {
                impacts.forEach(async (impact) => {
                    const newVideoImpactParms = { input:{ videoID:currentVideo.id, impactID:impact }};
                    await API.graphql(graphqlOperation(createVideoImpact, newVideoImpactParms));
                });
            }
            if (respondents.length > 0) {
                respondents.forEach(async (respondent) => {
                    const newVideoRespondentParms = { input:{ videoID:currentVideo.id, respondentID:respondent }};
                    await API.graphql(graphqlOperation(createVideoRespondent, newVideoRespondentParms));
                });
            }

            // UPDATE VIDEO
            const updateVideoParms = { input:{ id:currentVideo.id, name, description, thumbnail, video, isFeatured:false }};
            console.log(updateVideoParms)
            await API.graphql(graphqlOperation(updateVideo, updateVideoParms));

            // UPDATE LOCAL DATA
            let updatedVideos = await getAllVideos();
			let updatedConditions = await getAllConditions();
			let updatedImpacts = await getAllImpacts();
			let updatedRespondents = await getAllRespondents();
			session.updateConditions(updatedConditions);
			session.updateImpacts(updatedImpacts);
			session.updateRespondents(updatedRespondents);
			session.updateVideos(updatedVideos);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }


    // ----------------------------------------------------
    // DELETE VIDEO
    // ----------------------------------------------------

    const removeVideo = async (videoID) => {
        try {

            // GET VIDEO REFERENCE
            let currentVideo = session.getVideoByID(videoID);

            // DELETE RELATED DATA
            if (currentVideo.conditions.items.length > 0){
                currentVideo.conditions.items.forEach(async (condition) => {
                    const deleteVideoConditionParms = { input:{ id:condition.id }};
                    await API.graphql(graphqlOperation(deleteVideoCondition, deleteVideoConditionParms));
                });
            }
            if (currentVideo.impacts.items.length > 0){
                currentVideo.impacts.items.forEach(async (impact) => {
                    const deleteVideoImpactParms = { input:{ id:impact.id }};
                    await API.graphql(graphqlOperation(deleteVideoImpact, deleteVideoImpactParms));
                });
            }
            if (currentVideo.respondents.items.length > 0){
                currentVideo.respondents.items.forEach(async (respondent) => {
                    const deleteVideoRespondentParms = { input:{ id:respondent.id }};
                    await API.graphql(graphqlOperation(deleteVideoRespondent, deleteVideoRespondentParms));
                });
            }

            // DELETE FILES
            if (currentVideo.thumbnail.key){
                await Storage.remove(currentVideo.thumbnail.key);
            }
            if (currentVideo.video.key){
                await Storage.remove(currentVideo.video.key);
            }

            // DELETE VIDEO
            const deleteVideoParms = { input:{ id:videoID }};
            await API.graphql(graphqlOperation(deleteVideo, deleteVideoParms));

            // UPDATE LOCAL DATA
            let updatedVideos = await getAllVideos();
			let updatedConditions = await getAllConditions();
			let updatedImpacts = await getAllImpacts();
			let updatedRespondents = await getAllRespondents();
			session.updateConditions(updatedConditions);
			session.updateImpacts(updatedImpacts);
			session.updateRespondents(updatedRespondents);
			session.updateVideos(updatedVideos);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // GET ALL CONDITIONS
    // ----------------------------------------------------

    const getAllConditions = async () => {
        try {
            let conditions = await API.graphql(graphqlOperation(listConditions));
            let results = conditions.data.listConditions.items.sort( sortResultsByName );
            return results;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // NEW VIDEO
    // ----------------------------------------------------

    const newCondition = async (name, description, document) => {
        try {

            // CREATE CONDITION
            const newConditionParms = { input:{ name, description, document }};
            const newCondition = await API.graphql(graphqlOperation(createCondition, newConditionParms));

            // UPDATE LOCAL DATA
			let updatedConditions = await getAllConditions();
			session.updateConditions(updatedConditions);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // EDIT CONDITION
    // ----------------------------------------------------

    const editCondition = async (currentCondition, name, description, document) => {
        try {

            // UPDATE CONDITION
            const updateConditionParms = { input:{ id:currentCondition.id, name, description, document }};
            console.log(updateConditionParms)
            await API.graphql(graphqlOperation(updateCondition, updateConditionParms));

            // UPDATE LOCAL DATA
			let updatedConditions = await getAllConditions();
			session.updateConditions(updatedConditions);
            return true;
        }
        catch (error) {
            console.log(error);
            setIsLoadingData(false);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // DELETE CONDITION
    // ----------------------------------------------------

    const removeCondition = async (conditionID) => {
        try {

            // GET CONDITION REFERENCE
            let currentCondition = session.getConditionByID(conditionID);

            // DELETE RELATED DATA
            if (currentCondition.videos.items.length > 0){
                currentCondition.videos.items.forEach(async (video) => {
                    const deleteVideoConditionParms = { input:{ id:video.id }};
                    await API.graphql(graphqlOperation(deleteVideoCondition, deleteVideoConditionParms));
                });
            }

            // DELETE DOCUMENT
            if (currentCondition.document.key){
                await Storage.remove(currentCondition.document.key);
            }

            // DELETE CONDITION
            const deleteConditionParms = { input:{ id:conditionID }};
            await API.graphql(graphqlOperation(deleteCondition, deleteConditionParms));

            // UPDATE LOCAL DATA
			let updatedConditions = await getAllConditions();
			session.updateConditions(updatedConditions);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // GET ALL IMPACTS
    // ----------------------------------------------------

    const getAllImpacts = async () => {
        try {
            let impacts = await API.graphql(graphqlOperation(listImpacts));
            let results = impacts.data.listImpacts.items.sort( sortResultsByName );
            return results;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // NEW IMPACT
    // ----------------------------------------------------

    const newImpact = async (name, description) => {
        try {

            // CREATE IMPACT
            const newImpactParms = { input:{ name, description }};
            const newImpact = await API.graphql(graphqlOperation(createImpact, newImpactParms));

            // UPDATE LOCAL DATA
			let updatedImpacts = await getAllImpacts();
			session.updateImpacts(updatedImpacts);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // EDIT IMPACT
    // ----------------------------------------------------

    const editImpact = async (currentImpact, name, description) => {
        try {

            // UPDATE IMPACT
            const updateImpactParms = { input:{ id:currentImpact.id, name, description }};
            console.log(updateImpactParms)
            await API.graphql(graphqlOperation(updateImpact, updateImpactParms));

            // UPDATE LOCAL DATA
			let updatedImpacts = await getAllImpacts();
			session.updateImpacts(updatedImpacts);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // DELETE IMPACT
    // ----------------------------------------------------

    const removeImpact = async (conditionID) => {
        try {

            // GET CONDITION REFERENCE
            let currentImpact = session.getImpactByID(conditionID);

            // DELETE RELATED DATA
            if (currentImpact.videos.items.length > 0){
                currentImpact.videos.items.forEach(async (video) => {
                    const deleteVideoImpactParms = { input:{ id:video.id }};
                    await API.graphql(graphqlOperation(deleteVideoImpact, deleteVideoImpactParms));
                });
            }

            // DELETE CONDITION
            const deleteImpactParms = { input:{ id:conditionID }};
            await API.graphql(graphqlOperation(deleteImpact, deleteImpactParms));

            // UPDATE LOCAL DATA
			let updatedImpacts = await getAllImpacts();
			session.updateImpacts(updatedImpacts);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }


    // ----------------------------------------------------
    // GET ALL RESPONDENTS
    // ----------------------------------------------------

    const getAllRespondents = async () => {
        try {
            let respondents = await API.graphql(graphqlOperation(listRespondents));
            let results = respondents.data.listRespondents.items.sort( sortResultsByName );
            return results;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // NEW RESPONDENT
    // ----------------------------------------------------

    const newRespondent = async (name, bio, image) => {
        try {

            // CREATE CONDITION
            const newRespondentParms = { input:{ name, bio, image }};
            const newRespondent = await API.graphql(graphqlOperation(createRespondent, newRespondentParms));

            // UPDATE LOCAL DATA
			let updatedRespondents = await getAllRespondents();
			session.updateRespondents(updatedRespondents);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // EDIT RESPONDENT
    // ----------------------------------------------------

    const editRespondent = async (currentRespondent, name, bio, image) => {
        try {

            // UPDATE RESPONDENT
            const updateRespondentParms = { input:{ id:currentRespondent.id, name, bio, image }};
            console.log(updateRespondentParms)
            await API.graphql(graphqlOperation(updateRespondent, updateRespondentParms));

            // UPDATE LOCAL DATA
			let updatedRespondents = await getAllRespondents();
			session.updateRespondents(updatedRespondents);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // DELETE RESPONDENT
    // ----------------------------------------------------

    const removeRespondent = async (respondentID) => {
        try {

            // GET CONDITION REFERENCE
            let currentRespondent = session.getRespondentByID(respondentID);

            // DELETE RELATED DATA
            if (currentRespondent.videos.items.length > 0){
                currentRespondent.videos.items.forEach(async (video) => {
                    const deleteVideoRespondentParms = { input:{ id:video.id }};
                    await API.graphql(graphqlOperation(deleteVideoRespondent, deleteVideoRespondentParms));
                });
            }

            // DELETE DOCUMENT
            if (currentRespondent.image.key){
                await Storage.remove(currentRespondent.image.key);
            }

            // DELETE CONDITION
            const deleteRespondentParms = { input:{ id:respondentID }};
            await API.graphql(graphqlOperation(deleteRespondent, deleteRespondentParms));

            // UPDATE LOCAL DATA
			let updatedRespondents = await getAllRespondents();
			session.updateRespondents(updatedRespondents);
            return true;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }

    // ----------------------------------------------------
    // SEARCH FILMS / IMPACTS / CONDITIONS / RESPONDENTS
    // ----------------------------------------------------

    const getSearchResults = async (searchTerm, nextToken) => {
        try {

            // VIDEO QUERY
            let videoResults = await API.graphql(graphqlOperation(searchVideos, {
                filter: { or: [
                    { name: { match: searchTerm } },
                    { description: { match: searchTerm } },
                ]},
            nextToken: nextToken }));

            // CONDITIONS QUERY
            let conditionResults = await API.graphql(graphqlOperation(searchConditions, {
                filter: { or: [
                    { name: { match: searchTerm } },
                    { description: { match: searchTerm } },
                ]},
            nextToken: nextToken }));

            // IMPACTS QUERY
            let impactResults = await API.graphql(graphqlOperation(searchImpacts, {
                filter: { or: [
                    { name: { match: searchTerm } },
                    { description: { match: searchTerm } },
                ]},
            nextToken: nextToken }));

            // RESPONDENTS QUERY
            let respondentResults = await API.graphql(graphqlOperation(searchRespondents, {
                filter: { or: [
                    { name: { match: searchTerm } },
                ]},
            nextToken: nextToken }));
            
            // RESULTS
            let results = {
                videos: videoResults.data.searchVideos.items,
                conditions: conditionResults.data.searchConditions.items,
                impacts: impactResults.data.searchImpacts.items,
                respondents: respondentResults.data.searchRespondents.items,
            }

            console.log("SEARCH RESULTS: ", results);
            return results;
        }
        catch (error) {
            console.log(error);
            alerts.showError(error.errors[0].message);
            return null;
        }
    }


    // ----------------------------------------------------
    // POPULATE CONTEXT OBJECT
    // ----------------------------------------------------

    const contextValue = {
        isLoadingData: isLoadingData,
        getAccounts: () => getAccounts(),
        getAccountByID: (accountID, nextToken) => getAccountByID(accountID, nextToken),
        newAccount:(name, logo, conditions) => newAccount(name, logo, conditions),
        editAccount:(currentAccount, accountLogo, conditions) => editAccount(currentAccount, accountLogo, conditions),
        removeAccount: (account) => removeAccount(account),
        
        listGroupUsers: (groupName, nextToken) => listGroupUsers(groupName, nextToken),
        addNewUser: (role, accountName, firstName, lastName, emailAddress, locale, accountID) => addNewUser(role, accountName, firstName, lastName, emailAddress, locale, accountID),
        updateUser: (username, firstName, lastName, emailAddress) => updateUser(username, firstName, lastName, emailAddress),
        forceResetPassword: (username) => forceResetPassword(username),

        getAllVideos: (nextToken) => getAllVideos(nextToken),
        newVideo: (name, description, conditions, impacts, respondents, thumbnail, video) => newVideo(name, description, conditions, impacts, respondents, thumbnail, video),
        editVideo: (id, name, description, conditions, impacts, respondents, thumbnail, video) => editVideo(id, name, description, conditions, impacts, respondents, thumbnail, video),
        removeVideo: (videoID) => removeVideo(videoID),
        
        getAllConditions: (nextToken) => getAllConditions(nextToken),
        newCondition: (name, description, document) => newCondition(name, description, document),
        editCondition:(currentCondition, name, description, document) =>  editCondition(currentCondition, name, description, document),
        removeCondition: (conditionID) => removeCondition(conditionID),
        
        getAllImpacts: (nextToken) => getAllImpacts(nextToken),
        newImpact: (name, description) => newImpact(name, description),
        editImpact:(currentImpact, name, description) =>  editImpact(currentImpact, name, description),
        removeImpact: (imapactID) => removeImpact(imapactID),
        
        getAllRespondents: (nextToken) => getAllRespondents(nextToken),
        newRespondent: (name, description, image) => newRespondent(name, description, image),
        editRespondent:(currentRespondent, name, description, image) =>  editRespondent(currentRespondent, name, description, image),
        removeRespondent: (respondentID) => removeRespondent(respondentID),
        
        getSearchResults: (searchTerm, nextToken) => getSearchResults(searchTerm, nextToken),
    };

    // ----------------------------------------------------
    // RENDER PROVIDER
    // ----------------------------------------------------

    return (
        <APIContext.Provider value={contextValue}>
            {children}
        </APIContext.Provider>
    );
}

// ----------------------------------------------------
// API CONTEXT OBJECT
// ----------------------------------------------------

export const APIContext = React.createContext({
    isLoadingData: false,
    getAccounts: () => { },
    getAccountByID: (accountID, nextToken) => { },
    newAccount:(name, logo, conditions) => { },
    editAccount:(currentAccount, accountLogo, conditions) => { },
    removeAccount: (account) => { },
    
    listGroupUsers: (groupName, nextToken) => { },
    addNewUser: (role, accountName, firstName, lastName, emailAddress, locale, accountID) => { },
    updateUser: (username, firstName, lastName, emailAddress) => { },
    forceResetPassword: (username) => { },

    getAllVideos: (nextToken) => { },
    newVideo: (name, description, conditions, impacts, respondents, thumbnail, video) => { },
    editVideo: (id, name, description, conditions, impacts, respondents, thumbnail, video) => { },
    removeVideo: (videoID) => { },
    
    getAllConditions: (nextToken) => { },
    newCondition: (name, description, document) => { },
    editCondition:(currentCondition, name, description, document) =>  { },
    removeCondition: (conditionID) => { },
    
    getAllImpacts: (nextToken) => { },
    newImpact: (name, description) => { },
    editImpact:(currentImpact, name, description) => { },
    removeImpact: (imapactID) => { },
    
    getAllRespondents: (nextToken) => { },
    newRespondent: (name, description, image) => { },
    editRespondent:(currentRespondent, name, description, image) =>  { },
    removeRespondent: (respondentID) => { },
    
    getSearchResults: (searchTerm, nextToken) => { },
});




/*
// ----------------------------------------------------
// 
// ----------------------------------------------------

const renameMe = async () => {
    setIsLoadingData(true);
    try {
        setIsLoadingData(false);
        alerts.showSuccess("");
        return response;
    }
    catch (error) {
        console.log(error);
        setIsLoadingData(false);
        alerts.showError("");
        return null;
    }
}
*/


/*
VIDEOS TABLE

python3 ddb_to_es.py \
    --rn 'us-west-2' \
    --tn 'Video-tzrkdann2rgdfbkuse2ub2xcri-dev' \
    --lf 'arn:aws:lambda:us-west-2:081510557645:function:amplify-healthimmersionre-OpenSearchStreamingLambd-omlsFy5ezcma' \
    --esarn 'arn:aws:dynamodb:us-west-2:081510557645:table/Video-tzrkdann2rgdfbkuse2ub2xcri-dev/stream/2023-03-04T16:46:57.732'

*/

/*
CONDITIONS TABLE

python3 ddb_to_es.py \
    --rn 'us-west-2' \
    --tn 'Condition-tzrkdann2rgdfbkuse2ub2xcri-dev' \
    --lf 'arn:aws:lambda:us-west-2:081510557645:function:amplify-healthimmersionre-OpenSearchStreamingLambd-omlsFy5ezcma' \
    --esarn 'arn:aws:dynamodb:us-west-2:081510557645:table/Condition-tzrkdann2rgdfbkuse2ub2xcri-dev/stream/2023-03-04T16:48:04.128'

*/

/*
IMPACTS TABLE

python3 ddb_to_es.py \
    --rn 'us-west-2' \
    --tn 'Impact-tzrkdann2rgdfbkuse2ub2xcri-dev' \
    --lf 'arn:aws:lambda:us-west-2:081510557645:function:amplify-healthimmersionre-OpenSearchStreamingLambd-omlsFy5ezcma' \
    --esarn 'arn:aws:dynamodb:us-west-2:081510557645:table/Impact-tzrkdann2rgdfbkuse2ub2xcri-dev/stream/2023-03-04T16:46:58.951'

*/

/*
RESPONDENTS TABLE

python3 ddb_to_es.py \
    --rn 'us-west-2' \
    --tn 'Respondent-tzrkdann2rgdfbkuse2ub2xcri-dev' \
    --lf 'arn:aws:lambda:us-west-2:081510557645:function:amplify-healthimmersionre-OpenSearchStreamingLambd-omlsFy5ezcma' \
    --esarn 'arn:aws:dynamodb:us-west-2:081510557645:table/Respondent-tzrkdann2rgdfbkuse2ub2xcri-dev/stream/2023-03-04T23:43:48.749'

*/