import { Card, CircularProgress, Container } from "@mui/material";
import { Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { Image } from 'mui-image'


// --------------------------------------------------------
// S3 IMAGE COMPONENT
// --------------------------------------------------------

export function S3MenuImage ( props ) {


    // ----------------------------------------------------
	// PROPERTIES
	// ----------------------------------------------------

    const [imageURL, setImageURL] = useState();


    // ----------------------------------------------------
	// LOAD IMAGE
	// ----------------------------------------------------

    const loadImage = async (key) => {
        var signedURL = await Storage.get(key);
        setImageURL(signedURL);
    }

    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        loadImage(props.imagePath);
    }, []);


    // ----------------------------------------------------
	// RENDER S3 IMAGE COMPONENT
	// ----------------------------------------------------

    return (
        <Card elevation={0} sx={{backgroundColor:'transparent', padding:.5}}>
            {imageURL ? (
                <Image src={imageURL} alt={props.imageName} duration={0} fit={"scale-down"} maxwidth={"100%"} height={"80px"} />
            ):(
                <Container sx={{display:'flex', justifyContent:'center', alignItems: "center", maxWidth:"100%", width:"80px"}}>
                    <CircularProgress />
                </Container>
            )}
        </Card>
    )
}