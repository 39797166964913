import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Container, Grid, Typography } from '@mui/material';
import { mediumBlue } from '../styled/theme/Variables';

const FSLoading = ( props ) => {

    // ----------------------------------------------------
    // PROPERTIES
    // ----------------------------------------------------

    const { text } = props;


    // ----------------------------------------------------
    // RENDER FS LOADING UI
    // ----------------------------------------------------

    return (

        <Container>
            <Grid container justifyContent={"center"} alignItems={"center"} style={{ minHeight: '100vh' }}>
                <Grid item>
                    <Container>
                        <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={5}>
                            <Grid item>
                                <Typography align="center" color={mediumBlue}>
                                    <strong>{ text ? text : 'Loading data...' }</strong>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <CircularProgress color="primary"/>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    );
}
export default FSLoading;