import { ExampleDocuseriesContainer, ExampleDocuseriesInnerContainer } from "../../styled/containers/Containers";
import { Storage } from "aws-amplify";
import { Breadcrumbs, Grid, Typography, Link as MUILink, Button, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { mainWhite } from "../../styled/theme/Variables";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player'
import awsExports from "../../../aws-exports";


// --------------------------------------------------------
// EXAMPLE DOCUSERIES SECTION
// --------------------------------------------------------

export default function ExampleDocuseries (props) {

    // ----------------------------------------------------
	// VIDEO OBJECTS 
	// ----------------------------------------------------

    let videos = [{
        "index": 0,
        "description": "Lifestyle,  Mental Health",
        "name": "1. Intro - Not enough energy to care",
        "video": { "key": "assets/example-video-001.mp4", "name": "Introduction to Roxy" },
        "thumbnail": { "key": "images/home/example-video-thumbnail-001.jpg", "name": "Introduction to Roxy" }
    },{
        "index": 1,
        "description": "Financial, Work",
        "name": "2. Can only take on one client",
        "video": { "key": "assets/example-video-002.mp4", "name": "Can only take on one client" },
        "thumbnail": { "key": "images/home/example-video-thumbnail-002.jpg", "name": "Can only take on one client" }
    },{
        "index": 2,
        "description": "Healthcare",
        "name": "3. Doctors confused, not working together",
        "video": { "key": "assets/example-video-003.mp4", "name": "Doctors confused, not working together" },
        "thumbnail": { "key": "images/home/example-video-thumbnail-003.jpg", "name": "Doctors confused, not working together" }
    },{
        "index": 3,
        "description": "Nutrition",
        "name": "4. Not enough energy to eat better",
        "video": { "key": "assets/example-video-004.mp4", "name": "Not enough energy to eat better" },
        "thumbnail": { "key": "images/home/example-video-thumbnail-004.jpg", "name": "Not enough energy to eat better" }
    }];


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------

    const [currentVideo, setCurrentVideo] = useState(videos[0]);
    const [nonActiveVideos, setNonActiveVideos] = useState([]);


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        setNonActiveVideos(videos.filter(video => video.name !== videos[0].name));
    }, []);


    // ----------------------------------------------------
	// CHNAGE ACTIVE VIDEO 
	// ----------------------------------------------------

    let changeActiveVideo = (index) => {
        setCurrentVideo(videos[index]);
        setNonActiveVideos(videos.filter(video => video.name !== videos[index].name));
    }


    // ----------------------------------------------------
	// COMPONENT STYLES
	// ----------------------------------------------------

    const videoPlayer = {
        float: "left",
        maxWidth: "50%",
    }
    const videoGallery = {
        float: "left",
        maxWidth: "50%",
        clear: "both",
    }
    const rightSide = {
        float: "left",
        maxWidth: "45%",
        color: mainWhite,
        fontSize: "18px",
        textAlign: "left",
        marginLeft: "5%",
        ul: {
            color: mainWhite,
        }
    }

    // ----------------------------------------------------
    // GET VIDEO URL
    // ----------------------------------------------------

    let getVideoUrl = (video) => {
        let url = "https://";
        url += awsExports.aws_user_files_s3_bucket;
        url += ".s3.";
        url += awsExports.aws_user_files_s3_bucket_region;
        url += ".amazonaws.com/";
        url += video.key;
        return url;
    }

    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

    return (
        <ExampleDocuseriesContainer maxWidth={false} disableGutters>
            <ExampleDocuseriesInnerContainer>
                <Grid container justifyContent={"center"} alignItems={"center"}>
                    <Box sx={{paddingBottom:"30px"}}>
                        <Typography variant="h3" color="white">Example Docuseries: Long Covid</Typography>
                    </Box>
                </Grid>
                <Grid container spacing={8} justifyContent={"space-between"} alignItems={"flex-start"} direction={"row"} wrap={"nowrap"}>
                    
                    {/* CONTENT LEFT*/}
                    <Grid item>
                        <Grid container rowSpacing={1} justifyContent={"center"} alignItems={"center"} direction={"column"}>
                            <Grid item>
                                <ReactPlayer url={getVideoUrl(currentVideo.video)} controls light={currentVideo.thumbnail.key} config={{ file: { attributes: { controlsList: 'nodownload' } } }} />
                            </Grid>
                            <Grid container justifyContent={"center"} alignItems={"center"} direction={"row"} spacing={1} sx={{marginTop:"2px"}}>
                                { nonActiveVideos.map((video, index) => (
                                    <Grid item key={index} xs={4}>
                                        <img src={video.thumbnail.key} style={{cursor:'pointer'}} onClick={() => changeActiveVideo(video.index)} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* CONTENT RIGHT*/}
                    <Grid item minWidth={"50%"}>
                        <Grid container justifyContent={"flex-start"} alignItems={"flex-start"} direction={"column"}>
                            <Grid item>
                                <Typography color={mainWhite} variant={"docuseriesH2"}><strong>{ currentVideo.name }</strong></Typography>  
                            </Grid>
                            <Grid item sx={{marginTop:"15px"}}>
                                <Typography color={mainWhite} variant={"docuseriesH6"} sx={{paddingBottom:"10px"}}><strong>{ "Impacts:" }</strong></Typography>
                            </Grid>
                            <Grid item sx={{marginTop:"15px", textAlign:"left"}}>
                                {currentVideo.description.split(',').map((item, index) => (
                                    <div key={index}>
                                        <Typography key={index} color={mainWhite} variant="docuseriesBody" sx={{marginLeft:"20px"}}>{`\u2022 ${item}`}</Typography>
                                        <br/>
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </ExampleDocuseriesInnerContainer>
        </ExampleDocuseriesContainer>
    )
}