import { Box, Container } from "@mui/material";
import { styled } from '@mui/material';


// --------------------------------------------------------
// BASE BOX
// --------------------------------------------------------

export const BaseBox = styled (Box)({
    
});

// --------------------------------------------------------
// DIALOG HEADER BOX
// --------------------------------------------------------

export const DialogHeaderBox = styled (Box)({
    textAlign: "center",
    paddingTop: "25px",
    paddingBottom: "25px",
});

// --------------------------------------------------------
// BENEFIT CONTENT BOX
// --------------------------------------------------------

export const BenefitContentBox = styled (Box)({
    textAlign: "center",
    maxWidth: "375px",
});