import { bottomNavigationActionClasses, Container } from "@mui/material";
import { styled } from '@mui/material';
import { grey } from "@mui/material/colors";
import { darkBlue, darkGray, lightBlue, lightGray, mainWhite, mediumBlue, mediumGray, mediumPurple, mediumTaupe } from "../theme/Variables";
import { light } from "@mui/material/styles/createPalette";


// --------------------------------------------------------
// BASE CONTAINER
// --------------------------------------------------------

export const BaseContainer = styled(Container)({
    
});

// --------------------------------------------------------
// HEADER CONTAINERS
// --------------------------------------------------------

export const HeaderContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: darkBlue,
}));

export const HeaderInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    
}));

// --------------------------------------------------------
// CONTENT CONTAINERS
// --------------------------------------------------------

export const ContentContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: mainWhite,
}));

export const ContentInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: mainWhite,
    textAlign: "center",
}));

// --------------------------------------------------------
// HOME HERO CONTAINERS
// --------------------------------------------------------

export const HomeHeroContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: mediumTaupe,
    backgroundImage: "url(/images/home/health-immersion-hero.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    backgroundSize: "contain",
}));

export const HomeHeroInnerContainer = styled ( BaseContainer )(({ theme }) => ({
}));

// --------------------------------------------------------
// BENEFITS CONTAINERS
// --------------------------------------------------------

export const BenefitsContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: mainWhite,
}));

export const BenefitsInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    paddingTop: "80px",
    paddingBottom: "80px",
}));

// --------------------------------------------------------
// FEATURED CONDITIONS CONTAINERS
// --------------------------------------------------------

export const FeaturedConditionsContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: mainWhite,
}));

export const FeaturedConditionsInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    paddingTop: "0",
    paddingBottom: "80px",
}));

// --------------------------------------------------------
// EXAMPLE DOCUSERIES CONTAINERS
// --------------------------------------------------------

export const ExampleDocuseriesContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: mediumBlue,
}));

export const ExampleDocuseriesInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    paddingTop: "80px",
    paddingBottom: "100px",
}));

// --------------------------------------------------------
// PATIENT INTERVIEWS CONTAINERS
// --------------------------------------------------------

export const PatientInterviewsContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: mainWhite,
    backgroundImage: "url(/images/home/patient-interview-bg-left.png), url(/images/home/patient-interview-bg-right.png)",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: "left bottom, right bottom",
    backgroundSize: "auto 70%, auto 70%",

}));

export const PatientInterviewsInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    paddingTop: "100px",
    paddingBottom: "100px",


}));

// --------------------------------------------------------
// BOTTOM CONTAINERS
// --------------------------------------------------------

export const BottomContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: lightGray,
}));

export const BottomInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    width: "50%",
    padding: "100px 0px"
}));

// --------------------------------------------------------
// FOOTER CONTAINERS
// --------------------------------------------------------

export const FooterContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: darkBlue,
}));

export const FooterInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "50px 0",
    color: mainWhite,
    textAlign: "right",
}));


// --------------------------------------------------------
// DASHBOARD CONTAINERS
// --------------------------------------------------------

export const DashboardContainer = styled ( BaseContainer )(({ theme }) => ({
    
}));

export const DashboardHeroContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "60px 0px 60px 0px",
    backgroundColor: mediumTaupe,
}));

export const DashboardHeroConditionsContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "40px 0px 20px 0px",
}));


// --------------------------------------------------------
// CONDITION CONTAINERS
// --------------------------------------------------------

export const ConditionContainer = styled ( BaseContainer )(({ theme }) => ({
    
}));

export const ConditionHeroContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "60px 0px 30px 0px",
    backgroundColor: mediumTaupe,
}));

export const ConditionHeroInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "20px 0px 40px 0px",
    backgroundColor: mediumTaupe,
}));

export const ConditionHeroConditionsContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "40px 0px 20px 0px",
}));

export const ConditionFiltersContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "40px 0px 20px 0px",
    backgroundColor: mainWhite,
}));

export const ConditionResultsContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "40px 0px 40px 0px",
    backgroundColor: mainWhite,
}));


// --------------------------------------------------------
// VIDEO DETAILS CONTAINER
// --------------------------------------------------------

export const VideoDetailsContainer = styled ( BaseContainer )(({ theme }) => ({
    
}));

export const VideoDetailsHeroContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "30px 0px 100px 0px",
    backgroundColor: darkGray,
}));

export const VideoDetailsHeroInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "20px 0px 20px 0px",
    backgroundColor: darkGray,
}));


// --------------------------------------------------------
// FILTERS / RESULTS CONTAINER
// --------------------------------------------------------

export const VideoGridFiltersContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "40px 0px 20px 0px",
    backgroundColor: mainWhite,
}));

export const VideoGridResultsContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "40px 0px 40px 0px",
    backgroundColor: mainWhite,
}));


// --------------------------------------------------------
// SEARCH CONTAINERS
// --------------------------------------------------------

export const SearchContainer = styled ( BaseContainer )(({ theme }) => ({
    
}));

export const SearchHeroContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "60px 0px 30px 0px",
    backgroundColor: mediumTaupe,
}));

export const SearchHeroInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "20px 0px 40px 0px",
    backgroundColor: mediumTaupe,
}));


// --------------------------------------------------------
// ADMIN CONTAINERS
// --------------------------------------------------------

export const AdminContainer = styled ( BaseContainer )(({ theme }) => ({
    
}));

export const AdminHeroContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "30px 0px 30px 0px",
    backgroundColor: mediumTaupe,
}));

export const AdminHeroInnerContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "20px 0px 20px 0px",
    backgroundColor: mediumTaupe,
}));

export const AdminContentContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "40px 0px 60px 0px",
}));


// --------------------------------------------------------
// DATA GRID CONTAINERS
// --------------------------------------------------------

export const DataGridContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "0px 0px 0px 0px",
}));

export const DataGridItemContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "5px",
    borderBottom: "1px solid #CCCCCC",
    //backgroundColor: grey[100],
}));



// --------------------------------------------------------
// DASHBOARD CONTAINERS
// --------------------------------------------------------

export const ContactFormContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "0px 0px 0px 0px",
}));

export const ContactFormHeaderContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: darkBlue,
    padding: "20px 0px 8px 0px",
}));

export const ContactFormActionsContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "0px 0px 20px 0px",
    textAlign: "right",
}));

export const ContactFormContentContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "40px 40px 0px 40px",
}));

export const ContactFormFooterContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "25px 0px 0px 0px",
    backgroundColor: "#EDEDED",
    height: "70px",
}));


// --------------------------------------------------------
// CRUD CONTAINERS
// --------------------------------------------------------

export const CRUDDialogContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "40px 20px 20px 20px",
}));

export const CRUDDialogHeaderContainer = styled ( BaseContainer )(({ theme }) => ({
    backgroundColor: darkBlue,
    padding: "20px 0px 20px 0px",
}));

export const CRUDDialogActionsContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "0px 0px 20px 0px",
    textAlign: "right",
}));

export const CRUDDialogContentContainer = styled ( BaseContainer )(({ theme }) => ({
    padding: "40px 40px 0px 40px",
}));



/*
export const Name = styled ( BaseContainer )(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        //...
    },
    [theme.breakpoints.up('md')]: {
        //...
    },
}));
*/