import React, { useState } from 'react';

// ALERTS CONTEXT OBJECT
export const AlertsContext = React.createContext({
    successOpen: null,
    errorOpen: null,
    successMessage: null,
    errorMessage: null,
    showSuccess: () => {},
    closeSuccess: () => {},
    showError: () => {},
    closeError: () => {}
});

// ALERTS CONTEXT PROVIDER
export default function AlertsProvider({ children }) {

    // PROPERTIES
    const [successMessage, setSuccessMessage] = useState(null);
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);

    // SHOW SUCCESS ALERT
    const showSuccess = (message) => {
        setSuccessMessage(message);
        setSuccessOpen(true);
        //console.log("SUCCESS:", message);
    }

    // SHOW ERROR ALERT
    const showError = (message) => {
        setErrorMessage(message);
        setErrorOpen(true);
        //console.log("ERROR:", message);
    }
    
    // CLOSE SUCCESS ALERT
    const closeSuccess = (event, reason) => {
        if (reason === 'clickaway') return
        setSuccessOpen(false);
    }

    // CLOSE SUCCESS ALERT
    const closeError = (event, reason) => {
        if (reason === 'clickaway') return
        setErrorOpen(false);
    }

    // POPULATE CONTEXT OBJECT
    const contextValue = {
        successOpen: successOpen,
        errorOpen: errorOpen,
        successMessage: successMessage,
        errorMessage: errorMessage,
        showSuccess: (message) => showSuccess(message),
        closeSuccess: (event, reason) => closeSuccess(event, reason),
        showError: (message) => showError(message),
        closeError: (event, reason) => closeError(event, reason),
    };

    // RENDER PROVIDER
    return (
        <AlertsContext.Provider value={contextValue}>
            {children}
        </AlertsContext.Provider>
    );
}