import { Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import useDialogs from "../../global/dialogs/useDialogs";
import useSession from "../../global/session/useSession";
import Loading from "../../loading/Loading";
import { PurpleButton } from "../../styled/buttons/Buttons";
import { AdminContainer, AdminContentContainer, AdminHeroContainer, AdminHeroInnerContainer } from "../../styled/containers/Containers";
import { mediumBlue } from "../../styled/theme/Variables";
import DataGrid from "../modules/DataGrid";
import { AddFilm } from "./AddFilm";
import { EditFilm } from "./EditFilm";
import useAPI from "../../global/api/useAPI";


// --------------------------------------------------------
// FILMS COMPONENT
// --------------------------------------------------------

export function Films( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------

    const api = useAPI();
    const dialogs = useDialogs();
    const session = useSession();
    const [isLoading, setIsLoading] = useState(true);
    
    const [films, setFilms] = useState([]);
    const [newFilmOpen, setNewFilmOpen] = useState(false);
    const [editFilmOpen, setEditFilmOpen] = useState(false);
    const [currentEditFilm, setCurrentEditFilm] = useState(null);


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        setFilms(session.videos);
        setIsLoading(false);
    }, []);


    // ----------------------------------------------------
	// NEW FILM
	// ----------------------------------------------------

    const newItem = (id) => {
        setNewFilmOpen(true)
    }

    // ----------------------------------------------------
	// VIEW FILM
	// ----------------------------------------------------

    const viewItem = (id) => {
        window.open('/film/'+id, '_blank', 'noreferrer');
    }

    // ----------------------------------------------------
	// EDIT FILM
	// ----------------------------------------------------

    const editItem = (id) => {
        let currentFilm = films.filter(film => film.id === id)[0];
        setCurrentEditFilm(currentFilm);
        setEditFilmOpen(true);
    }

    // ----------------------------------------------------
	// DELETE FILM
	// ----------------------------------------------------

    const deleteItem = (id) => {
        let title = "Delete Film";
        let message = "Are you sure you want to delete this Film?";
        dialogs.showConfirmation(title, message, () => doDelete(id), () => { });
    }
    const doDelete = async (id) => {
        dialogs.showProgress("Deleting Film > Please wait...");
        await api.removeVideo(id);
        dialogs.closeProgress();
    }

    // ----------------------------------------------------
	// CLOSE / RESET DIALOGS
	// ----------------------------------------------------

    const handleNewClose = () => {
        setNewFilmOpen(false);
    };
    const handleEditClose = () => {
        setEditFilmOpen(false)
    };


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

	return (
        <Fragment>
            { isLoading === true
                ? <Loading text={"Loading > Please Wait..."}/>
                : <AdminContainer maxWidth={false} disableGutters>
                    <AdminHeroContainer maxWidth={false} disableGutters>
                        <AdminHeroInnerContainer>
                            <Grid container direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <Typography variant={"h4"} color={mediumBlue}><strong>Manage Films</strong></Typography>
                                </Grid>
                                <Grid item>
                                    <PurpleButton variant="contained" onClick={newItem}>Add New Film</PurpleButton>
                                </Grid>
                            </Grid>
                        </AdminHeroInnerContainer>
                    </AdminHeroContainer>
                    <AdminContentContainer>
                        <DataGrid items={films} view={viewItem} edit={editItem} delete={deleteItem}/>
                    </AdminContentContainer>
                </AdminContainer>
            }
            <AddFilm open={newFilmOpen} close={handleNewClose} />
            <EditFilm open={editFilmOpen} close={handleEditClose} film={currentEditFilm} />
         </Fragment>
    )
}

export default Films;