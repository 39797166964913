import { styled } from "@mui/material/styles"
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { darkBlue, mediumBlue, mediumGray } from "../../styled/theme/Variables";

export const StyledDialog = styled(Dialog)({
  textAlign: "center",
});

export const StyledDialogTitle = styled(DialogTitle)({
  textAlign: "center",
  fontWeight: "bold",
  color: darkBlue,
});

export const StyledDialogContent = styled(DialogContent)({
  textAlign: "center",
    padding:"20px 50px 20px 30px",
});