import { Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import useSession from "../../global/session/useSession";
import Loading from "../../loading/Loading";
import { PurpleButton } from "../../styled/buttons/Buttons";
import { AdminContainer, AdminContentContainer, AdminHeroContainer, AdminHeroInnerContainer } from "../../styled/containers/Containers";
import { mediumBlue } from "../../styled/theme/Variables";
import DataGrid from "../modules/DataGrid";
import useAPI from "../../global/api/useAPI";
import useDialogs from "../../global/dialogs/useDialogs";
import { AddCondition } from "./AddCondition";
import { EditCondition } from "./EditCondition";


// --------------------------------------------------------
// CONDITIONS COMPONENT
// --------------------------------------------------------

export function Conditions( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------

    const api = useAPI();
    const dialogs = useDialogs();
    const session = useSession();
    const [isLoading, setIsLoading] = useState(true);
    
    const [conditions, setConditions] = useState([]);
    const [newConditionOpen, setNewConditionOpen] = useState(false);
    const [editConditionOpen, setEditConditionOpen] = useState(false);
    const [currentEditCondition, setCurrentEditCondition] = useState(null);


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        setConditions(session.conditions);
        setIsLoading(false);
    }, []);


    // ----------------------------------------------------
	// NEW CONDITION
	// ----------------------------------------------------

    const newItem = (id) => {
        setNewConditionOpen(true)
    }

    // ----------------------------------------------------
	// VIEW CONDITION
	// ----------------------------------------------------

    const viewItem = (id) => {
        window.open('/condition/'+id, '_blank', 'noreferrer');
    }

    // ----------------------------------------------------
	// EDIT CONDITION
	// ----------------------------------------------------

    const editItem = (id) => {
        let currentCondition = conditions.filter(condition => condition.id === id)[0];
        setCurrentEditCondition(currentCondition);
        setEditConditionOpen(true);
    }

    // ----------------------------------------------------
	// DELETE CONDITION
	// ----------------------------------------------------

    const deleteItem = (id) => {
        let title = "Delete Condition";
        let message = "Are you sure you want to delete this Condition?";
        dialogs.showConfirmation(title, message, () => doDelete(id), () => { });
    }
    const doDelete = async (id) => {
        dialogs.showProgress("Deleting Condition > Please wait...");
        await api.removeCondition(id);
        dialogs.closeProgress();
    }

    // ----------------------------------------------------
	// CLOSE / RESET DIALOGS
	// ----------------------------------------------------

    const handleNewClose = () => {
        setNewConditionOpen(false);
    };
    const handleEditClose = () => {
        setEditConditionOpen(false)
    };


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

    return (
        <Fragment>
            { isLoading === true
                ? <Loading text={"Loading > Please Wait..."}/>
                : <AdminContainer maxWidth={false} disableGutters>
                    <AdminHeroContainer maxWidth={false} disableGutters>
                        <AdminHeroInnerContainer>
                            <Grid container direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item>
                                    <Typography variant={"h4"} color={mediumBlue}><strong>Manage Conditions</strong></Typography>
                                </Grid>
                                <Grid item>
                                    <PurpleButton variant="contained" onClick={newItem}>Add New Condition</PurpleButton>
                                </Grid>
                            </Grid>
                        </AdminHeroInnerContainer>
                    </AdminHeroContainer>
                    <AdminContentContainer>
                        <DataGrid items={conditions} view={viewItem} edit={editItem} delete={deleteItem}/>
                    </AdminContentContainer>
                </AdminContainer>
            }
            <AddCondition open={newConditionOpen} close={handleNewClose} />
            <EditCondition open={editConditionOpen} close={handleEditClose} condition={currentEditCondition} />
         </Fragment>
    )

}

export default Conditions;