import { Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Loading from "../../loading/Loading";
import { AdminContainer, AdminContentContainer, AdminHeroContainer, AdminHeroInnerContainer } from "../../styled/containers/Containers";
import { mediumBlue } from "../../styled/theme/Variables";
import useAPI from "../../global/api/useAPI";
import { useParams } from "react-router-dom";
import { AddUser } from "./AddUser";
import { EditUser } from "./EditUser";
import { GreenButton, PurpleButton } from "../../styled/buttons/Buttons";
import useDialogs from "../../global/dialogs/useDialogs";
import useSession from "../../global/session/useSession";
import DataGrid from "../modules/DataGrid";
import { useNavigate } from "react-router-dom";


// --------------------------------------------------------
// USERS COMPONENT
// --------------------------------------------------------

export function Users( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------

    const { id } = useParams();
    const api = useAPI();
    const dialogs = useDialogs();
    const session = useSession();
    const navigate = useNavigate();
    
    const [account, setAccount] = useState();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [newUserOpen, setNewUserOpen] = useState(false);
    const [editUserOpen, setEditUserOpen] = useState(false);
    const [currentEditUser, setCurrentEditUser] = useState(null);


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        loadAccountUsers();
    }, []);


    // ----------------------------------------------------
	// LOAD ACCOUNT & USERS
	// ----------------------------------------------------

    const loadAccountUsers = async () => {
        setIsLoading(true);
        let account = await api.getAccountByID(id);
        let users = await api.listGroupUsers(account.name);
        setAccount(account);
        setUsers(users);
        setIsLoading(false);
    }


    // ----------------------------------------------------
	// NEW IMPACT
	// ----------------------------------------------------

    const newItem = (id) => {
        setNewUserOpen(true)
    }

    // ----------------------------------------------------
	// EDIT IMPACT
	// ----------------------------------------------------

    const editItem = (id) => {
        let currentUser = users.filter(user => user.Username === id)[0];
        setCurrentEditUser(currentUser);
        setEditUserOpen(true);
    }

    // ----------------------------------------------------
	// DELETE IMPACT
	// ----------------------------------------------------

    const deleteItem = (id) => {
        let title = "Delete User";
        let message = "Are you sure you want to delete this User?";
        dialogs.showConfirmation(title, message, () => doDelete(id), () => { });
    }
    const doDelete = async (id) => {
        dialogs.showProgress("Deleting User > Please wait...");
        //await api.removeUser(id);
        dialogs.closeProgress();
    }

    // ----------------------------------------------------
	// CLOSE / RESET DIALOGS
	// ----------------------------------------------------

    const handleNewClose = () => {
        setNewUserOpen(false);
    };
    const handleEditClose = () => {
        setEditUserOpen(false)
    };

    // ----------------------------------------------------
	// PARSE USERS
	// ----------------------------------------------------

    const parseUsers = () => {
        let parsed = [];
        for (let i = 0; i < users.length; i++) {
            parsed.push({
                id:users[i].Username,
                name:users[i].Attributes[4].Value + " " + users[i].Attributes[5].Value,
                // name:users[i].Attributes[4].Value + " " + users[i].Attributes[5].Value + " – " + users[i].UserStatus
            });
        }
        return parsed;
    }


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

	return (
        <Fragment>
            { isLoading === true
                ? <Loading text={"Loading > Please Wait..."}/>
                : <Fragment>
                    <AdminContainer maxWidth={false} disableGutters>
                        <AdminHeroContainer maxWidth={false} disableGutters>
                            <AdminHeroInnerContainer>
                                <Grid container direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                    <Grid item>
                                        <Grid container direction="row" justifyContent={"flex-start"} alignItems={"center"}>
                                            <Grid item>
                                                <Typography variant={"h4"} color={mediumBlue}><strong>Manage Users:</strong></Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant={"h4"} color={mediumBlue} sx={{marginLeft:"15px"}}>{account.name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {session.getUserAttribute(session.ACCESS_LEVEL) === "SuperAdmins" || session.getUserAttribute(session.ACCESS_LEVEL) === "Admins"
                                            ? <GreenButton sx={{marginRight:"20px"}} variant="contained" onClick={() => navigate("/admin/accounts")}>Back to Accounts</GreenButton>
                                            : null
                                        }
                                        <PurpleButton variant="contained" onClick={newItem}>Add New User</PurpleButton>
                                    </Grid>
                                </Grid>
                            </AdminHeroInnerContainer>
                        </AdminHeroContainer>
                        <AdminContentContainer>
                            <DataGrid items={parseUsers()} edit={editItem} delete={deleteItem} />
                        </AdminContentContainer>
                    </AdminContainer>
                    <AddUser open={newUserOpen} close={handleNewClose} accountID={id} accountName={account.name} reload={loadAccountUsers}/>
                    <EditUser open={editUserOpen} close={handleEditClose} accountID={id} accountName={account.name} user={currentEditUser} reload={loadAccountUsers} />
                </Fragment>
            }
        </Fragment>
    )

}

export default Users;