import React, { Fragment } from 'react';
import useDialogs from "./useDialogs";
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledDialog, StyledDialogContent, StyledDialogTitle } from './StyledDialogs';
import { GreenButton, PurpleButton, RedButton } from '../../styled/buttons/Buttons';
import { Dialog, DialogContent, Grid, TextField, Typography, Link as MUILink, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ContactFormActionsContainer, ContactFormContentContainer, ContactFormFooterContainer, ContactFormHeaderContainer } from '../../styled/containers/Containers';
import { Image } from "@aws-amplify/ui-react";
import { darkGray, mediumBlue } from '../../styled/theme/Variables';
import { Link } from "react-router-dom";


const Dialogs = () => {

    // PROPERTIES
    const dialogs = useDialogs();

    // RENDER UI
    return (
        <Fragment>
            
            {/* PROGRESS DIALOG ---------------------------------- */}

            <StyledDialog open={dialogs.progressOpen} className="Admin-Progress-Dialog" fullWidth={true} maxWidth={"xs"}>
                <StyledDialogTitle>{dialogs.progressTitle}</StyledDialogTitle>
                <StyledDialogContent>
                    <CircularProgress color="primary"/>
                </StyledDialogContent>
            </StyledDialog>

            {/* CONFIRMATION DIALOG ------------------------------ */}

            <StyledDialog open={dialogs.confirmationOpen}>
                <StyledDialogTitle>
                    <strong>{dialogs.confirmationTitle}</strong>
                </StyledDialogTitle>
                <StyledDialogContent>
                    <DialogContentText>
                        {dialogs.confirmationMessage}
                    </DialogContentText>
                </StyledDialogContent>
                <DialogActions>
                    <GreenButton variant="contained" size="small" onClick={dialogs.cancelPressed} color="primary">CANCEL</GreenButton>
                    <PurpleButton variant="contained" size="small" onClick={dialogs.okPressed} color="primary" autoFocus>YES</PurpleButton>
                </DialogActions>
            </StyledDialog>

            {/* VALIDATON DIALOG --------------------------------- */}

            <StyledDialog open={dialogs.validationOpen}>
                <StyledDialogTitle>
                    <strong>{dialogs.validationTitle}</strong>
                </StyledDialogTitle>
                <StyledDialogContent sx={{textAlign:"left"}}>
                    <ul>
                        { dialogs.validationMessages.map((message, index) => (
                            <li key={index}>{message}</li>
                        ))}
                    </ul>
                </StyledDialogContent>
                <DialogActions>
                    <PurpleButton variant="contained" size="small" onClick={dialogs.validateOkPressed} color="primary" autoFocus>OK</PurpleButton>
                </DialogActions>
            </StyledDialog>

            {/* CONTACT FORM | NON-AUTH -------------------------- */}

            <Dialog fullWidth={true} maxWidth={"md"} open={dialogs.contactNonAuthOpen} onClose={dialogs.closeContactNonAuth}>
                <form onSubmit={dialogs.handleContactNonAuthSubmit}>
                    <DialogContent sx={{padding:"0px"}}>
                        <ContactFormHeaderContainer>
                            <MUILink component={Link} to="/dashboard">
                                <Image src="/images/global/health-immersion-logo-light.png" alt="Health Immersion by Dig" width="311" height="37" />
                            </MUILink>
                        </ContactFormHeaderContainer>
                    </DialogContent>
                    <DialogContent sx={{padding:"0px"}}>
                        <ContactFormContentContainer>
                            <Grid container justifyContent={"space-between"} columnSpacing={8}>
                                <Grid item xs={5}>
                                    <Typography color={mediumBlue} variant={"h5"}><strong>For access, or for any questions, please fill out the form to the right.</strong></Typography>
                                    <Typography color={mediumBlue} variant={"body1"} sx={{marginTop:2}}>Responses are usually within the same day during the week.</Typography>
                                </Grid>
                                <Grid item flexGrow={1}>
                                    <Grid container direction={"column"} spacing={3}>
                                        <Grid item>
                                            <TextField id="yourName" label="Your Name" variant="outlined" sx={{width:"100%"}}/>
                                        </Grid>
                                        <Grid item>
                                            <TextField id="emailAddress" label="Email Address" variant="outlined" sx={{width:"100%"}}/>
                                        </Grid>
                                        <Grid item>
                                            <TextField id="message" label="How can we help?" multiline rows={5} variant="outlined" sx={{width:"100%"}}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ContactFormContentContainer>
                    </DialogContent>
                    <DialogActions sx={{padding:"20px 0px 0px 0px"}}>
                        <ContactFormActionsContainer>
                            <Grid container direction="row" columnSpacing={1} justifyContent={"flex-end"}>
                                <Grid item>
                                    <GreenButton variant="contained" onClick={dialogs.closeContactNonAuth}>Cancel</GreenButton>
                                </Grid>
                                <Grid item>
                                    <PurpleButton type="submit" variant="contained">Send</PurpleButton>
                                </Grid>
                            </Grid>
                        </ContactFormActionsContainer>
                    </DialogActions>
                    <DialogContent sx={{padding:"0px"}}>
                        <ContactFormFooterContainer>
                            <Grid container justifyContent={"center"} alignItems={"center"}>   
                                <Grid item>
                                    <Typography color={darkGray} variant={"body2"}>You may also call us at 312-925-3917 between 9am - 5pm CST</Typography>
                                </Grid>
                            </Grid>
                        </ContactFormFooterContainer>
                    </DialogContent>
                </form>
            </Dialog>

            {/* CONTACT FORM | AUTHED ---------------------------- */}

            <Dialog fullWidth={true} maxWidth={"md"} open={dialogs.contactAuthOpen} onClose={dialogs.closeContactAuth}>
                <form onSubmit={dialogs.handleContactAuthSubmit}>
                    <DialogContent sx={{padding:"0px"}}>
                        <ContactFormHeaderContainer>
                            <MUILink component={Link} to="/dashboard">
                                <Image src="/images/global/health-immersion-logo-light.png" alt="Health Immersion by Dig" width="311" height="37" />
                            </MUILink>
                        </ContactFormHeaderContainer>
                    </DialogContent>
                    <DialogContent sx={{padding:"0px"}}>
                        <ContactFormContentContainer>
                            <Grid container justifyContent={"space-between"} columnSpacing={8}>
                                <Grid item>
                                    <Typography color={mediumBlue} variant={"h6"}><strong>Please contact us for any of the services below:</strong></Typography>
                                    <ul>
                                        <li>General Support</li>
                                        <li>Request a New Film</li>
                                        <li>Facilitated Discussion</li>
                                        <li>Interview a Respondent</li>
                                        <li>Product Testing</li>
                                        <li>Ideation Roundtables</li>
                                        <li>Customized Exploration</li>
                                        <li>Other Services</li>
                                    </ul> 
                                </Grid>
                                <Grid item flexGrow={1}>
                                    <Grid container direction={"column"} spacing={3}>
                                        <Grid item>
                                            <FormControl fullWidth>
                                                <InputLabel id="contactReasonLabel">Please Select</InputLabel>
                                                <Select labelId="contactReasonLabel" id="contactReasonSelect" value={dialogs.contactReason} label="Please Select" onChange={dialogs.handleReasonChange}>
                                                    <MenuItem value={"General Support"}>General Support</MenuItem>
                                                    <MenuItem value={"Request a New Film"}>Request a New Film</MenuItem>
                                                    <MenuItem value={"Facilitated Discussion"}>Facilitated Discussion</MenuItem>
                                                    <MenuItem value={"Interview a Respondent"}>Interview a Respondent</MenuItem>
                                                    <MenuItem value={"Product Testing"}>Product Testing</MenuItem>
                                                    <MenuItem value={"Ideation Roundtables"}>Ideation Roundtables</MenuItem>
                                                    <MenuItem value={"Customized Exploration"}>Customized Exploration</MenuItem>
                                                    <MenuItem value={"Other Services"}>Other Services</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <TextField id="message" label="How can we help?" multiline rows={5} variant="outlined" sx={{width:"100%"}}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ContactFormContentContainer>
                    </DialogContent>
                    <DialogActions sx={{padding:"20px 0px 0px 0px"}}>
                        <ContactFormActionsContainer>
                            <Grid container direction="row" columnSpacing={1} justifyContent={"flex-end"}>
                                <Grid item>
                                    <GreenButton variant="contained" onClick={dialogs.closeContactAuth}>Cancel</GreenButton>
                                </Grid>
                                <Grid item>
                                    <PurpleButton type="submit" variant="contained">Send</PurpleButton>
                                </Grid>
                            </Grid>
                        </ContactFormActionsContainer>
                    </DialogActions>
                    <DialogContent sx={{padding:"0px"}}>
                        <ContactFormFooterContainer>
                            <Grid container justifyContent={"center"} alignItems={"center"}>   
                                <Grid item>
                                    <Typography color={darkGray} variant={"body2"}>You may also call us at 312-925-3917 between 9am - 5pm CST</Typography>
                                </Grid>
                            </Grid>
                        </ContactFormFooterContainer>
                    </DialogContent>
                </form>
            </Dialog>

        </Fragment>
    )
}
export default Dialogs;