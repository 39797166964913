
// --------------------------------------------------------
// LIST VIDEOS
// --------------------------------------------------------

export const listVideos = /* GraphQL */ `
    query ListVideos(
        $filter: ModelVideoFilterInput
        $limit: Int
        $nextToken: String
    ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            description
            id
            name
            isFeatured
            video {
                key
                name
            }
            thumbnail {
                key
                name
            }
            respondents {
                items {
                    id
                    respondentID
                    videoID
                }
            }
            impacts {
                items {
                    id
                    impactID
                    videoID
                }
            }
            conditions {
                items {
                    id
                    conditionID
                    videoID
                }
            }
        }
    }
}
`;

// --------------------------------------------------------
// LIST CONDITIONS
// --------------------------------------------------------

export const listConditions = /* GraphQL */ `
    query ListConditions(
        $filter: ModelConditionFilterInput
        $limit: Int
        $nextToken: String
    ) {
    listConditions(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            document {
                key
                name
            }
            description
            id
            name
            videos {
                items {
                    id
                    conditionID
                    videoID
                }
            }
        }
    }
}
`;

// --------------------------------------------------------
// LIST IMPACTS
// --------------------------------------------------------

export const listImpacts = /* GraphQL */ `
    query ListImpacts(
        $filter: ModelImpactFilterInput
        $limit: Int
        $nextToken: String
    ) {
    listImpacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            description
            id
            name
            videos {
                items {
                    id
                    impactID
                    videoID
                }
            }
        }
    }
}
`;

// --------------------------------------------------------
// LIST RESPONDENTS
// --------------------------------------------------------

export const listRespondents = /* GraphQL */ `
    query ListRespondents(
        $filter: ModelRespondentFilterInput
        $limit: Int
        $nextToken: String
    ) {
    listRespondents(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            bio
            id
            name
            image {
                key
                name
            }
            videos {
                items {
                    id
                    respondentID
                    videoID
                }
            }
        }
    }
}
`;

// --------------------------------------------------------
// GET ACCOUNT
// --------------------------------------------------------

export const getAccount = /* GraphQL */ `
    query GetAccount($id: ID!) {
        getAccount(id: $id) {
            id
            name
            logo {
                name
                key
            }
            conditions {
                items {
                    id
                    accountID
                    conditionID
                }
            }
        }
    }
`;

// --------------------------------------------------------
// LIST ACCOUNTS
// --------------------------------------------------------

export const listAccounts = /* GraphQL */ `
    query ListAccounts(
        $filter: ModelAccountFilterInput
        $limit: Int
        $nextToken: String
    ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            id
            name
            logo {
                name
                key
            }
			conditions {
				items {
                    id
					accountID
					conditionID
				}
			}
        }
    }
}
`;

// --------------------------------------------------------
// LIST ACCOUNT USERS
// --------------------------------------------------------

export const listCognitoUsers = /* GraphQL */ `
    query ListCognitoUsers($groupName: String!, $nextToken: String) {
        listCognitoUsers(groupName: $groupName, nextToken: $nextToken)
    }
`;