import { Box, Checkbox, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputAdornment, InputLabel,ListItemText,MenuItem,OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GreenButton, PurpleButton } from "../../styled/buttons/Buttons";
import { CRUDDialogActionsContainer, CRUDDialogContainer, CRUDDialogHeaderContainer } from "../../styled/containers/Containers";
import { mainWhite } from "../../styled/theme/Variables";
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { v4 as uuidv4 } from 'uuid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useDialogs from "../../global/dialogs/useDialogs";
import useAPI from "../../global/api/useAPI";
import { Storage } from 'aws-amplify';
import useSession from "../../global/session/useSession";
import { JSONPath } from "jsonpath-plus";


// --------------------------------------------------------
// ADD RESPONENT COMPONENT
// --------------------------------------------------------

export function EditAccount( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------
    
    const api = useAPI();
    const dialogs = useDialogs();
    const session = useSession();
    const [allowImageUpload, setAllowImageUpload] = useState(true);
    const [imageUploadPath, setImageUploadPath] = useState("");
    const [currentEditAccount, setCurrentEditAccount] = useState({name:"", logo:""});
    const [accountName, setAccountName] = useState("");
    const [selectedConditions, setSelectedConditions] = useState([]);


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        if (props.account && props.account !== currentEditAccount){
            setAccountName(props.account.name);
            if (props.account.logo.key){
                setAllowImageUpload(false);
                setImageUploadPath(props.account.logo.key);
            }
            let conditions = getConditionNames(props.account);
            setSelectedConditions(conditions);
            setCurrentEditAccount(props.account);
        }
    }, [props]);

    // ----------------------------------------------------
	// GET CONDITION NAMES
	// ----------------------------------------------------

    const getConditionNames = (account) => {
        let conditions = [];
        for (let a = 0; a < account.conditions.items.length; a++){
            conditions.push(session.getConditionByID(account.conditions.items[a].conditionID).name);
        }
        return conditions.sort();
    }

    // ----------------------------------------------------
	// HANDLERS 
	// ----------------------------------------------------

    const handleSubmit = async (event) => {
        event.preventDefault();

        let validate = validateForm(event);
        if (validate !== false) {
            let title = "Missing Required Fields";
            dialogs.showValidation(title, validate, () => { });
            return;
        }

        let accountImage = {name:event.currentTarget.accountName.value, key:event.currentTarget.imageFile.value};
        try {
            dialogs.showProgress("Updating Account > Please wait...");

            // GET CONDITION IDS
            let conditionsQuery = "$[?(";
            for (let a = 0; a < selectedConditions.length; a++) {
                conditionsQuery += "@.name === '" + selectedConditions[a] + "'";
                (a < selectedConditions.length - 1) ? conditionsQuery += " || " : conditionsQuery += ")].id";
            }
            let conditions = JSONPath({ path: conditionsQuery, json: session.conditions });

            // CREATE NEW ACCOUNT
            await api.editAccount(currentEditAccount, accountImage, conditions);
            dialogs.closeProgress();
        } catch (error) {
            console.log(error);
            dialogs.closeProgress();
        }
    };

    const validateForm = (event) => {
        let errors = [];
        let accountImage = (event.currentTarget.imageFile.value === "") ? errors.push("Account Logo is required") : null;
        let hasSelectedConditions = (selectedConditions.length === 0) ? errors.push("At least one Subscribed Condition is required") : null;
        if (errors.length > 0) {
            return errors;
        } else {
            return false;
        }
    }

    const handleClose = () => {
        setSelectedConditions([]);
        props.close(false);
    };

    const handleClearImage = async () => {
        dialogs.showProgress("Deleting > Please wait...");
        await Storage.remove(imageUploadPath);
        setAllowImageUpload(true);
        setImageUploadPath("");
        dialogs.closeProgress();
    };

    // ----------------------------------------------------
	// DROPDOWN HANDLER
	// ----------------------------------------------------

    const handleConditionsChange = (event) => {
        const { target: { value }, } = event;
        setSelectedConditions(typeof value === 'string' ? value.split(',') : value);
    };

    // ----------------------------------------------------
	// DROPDOWN STYLING
	// ----------------------------------------------------

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

    return (
        <Dialog fullWidth={true} maxWidth={"sm"} open={props.open}>
            <form onSubmit={handleSubmit}>
                <DialogContent sx={{padding:"0px"}}>
                    <CRUDDialogHeaderContainer>
                        <Typography color={mainWhite} variant={"h6"}><strong>Edit Account</strong></Typography>
                    </CRUDDialogHeaderContainer>
                    <CRUDDialogContainer>
                        <Grid container direction={"column"} rowSpacing={3}>
                            <Grid item flexGrow={1}>
                                <TextField disabled id="accountName" value={accountName} label="Account Name" variant="outlined" sx={{width:"100%"}} />
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ width: "100%", maxWidth: "550px" }}>
                                    <InputLabel id="selectedConditions">Subscribed Conditions</InputLabel>
                                    <Select labelId="selectedConditions" multiple value={ selectedConditions }
                                        onChange={ handleConditionsChange }
                                        input={ <OutlinedInput multiline label="Subscribed Conditions"/> }
                                        renderValue={ (selected) => selected.join(', ') }
                                        MenuProps={MenuProps} >
                                        { session.conditions.map((condition, index) => (
                                            <MenuItem key={index} value={condition.name}>
                                                <Checkbox checked={selectedConditions.indexOf(condition.name) > -1} />
                                                <ListItemText primary={condition.name} primaryTypographyProps={{fontSize: '14px'}}  />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Box sx={{ position:'relative', width: "100%", maxWidth: "550px" }}>
                                    <StorageManager
                                        acceptedFileTypes={['image/*']}
                                        accessLevel="public"
                                        maxFileCount={1}
                                        isResumable
                                        components={{
                                            Container({ children }) {
                                                return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
                                            },
                                            DropZone({ children }) {
                                                return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
                                            },
                                            FilePicker({ onClick }) {
                                                return (
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <InputLabel>Account Logo</InputLabel>
                                                        <OutlinedInput
                                                            id="imageFile"
                                                            disabled
                                                            value={imageUploadPath}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={onClick} sx={{display:(allowImageUpload ? "inherit" : "none")}}>
                                                                        <CloudUploadIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={handleClearImage} sx={{display:(allowImageUpload ? "none" : "inherit")}}>
                                                                        <HighlightOffIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label="Account Logo"
                                                        />
                                                    </FormControl>
                                                );
                                            },
                                            FileList(args) {
                                                return null;
                                            },
                                            FileListHeader( args ) {
                                                return null;
                                            },
                                        }}
                                        processFile={({ file, key }) => {
                                            let uuid = uuidv4();
                                            let uuidSplit = uuid.split('-');
                                            key = "images/" + uuidSplit[4] + "-" + file.name;
                                            return { file, key };
                                        }}
                                        onUploadStart={({ key }) => {
                                            dialogs.showProgress("Uploading > Please wait...");
                                        }}
                                        onUploadSuccess={({ key }) => {
                                            dialogs.closeProgress();
                                            setImageUploadPath(key);
                                            setAllowImageUpload(false);
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CRUDDialogContainer>
                </DialogContent>
                <DialogActions sx={{padding:"20px 0px 0px 0px"}}>
                    <CRUDDialogActionsContainer>
                        <Grid container direction="row" columnSpacing={1} justifyContent={"flex-end"}>
                            <Grid item>
                                <GreenButton variant="contained" onClick={handleClose}>Cancel</GreenButton>
                            </Grid>
                            <Grid item>
                                <PurpleButton type="submit" variant="contained" >Update Account</PurpleButton>
                            </Grid>
                        </Grid>
                    </CRUDDialogActionsContainer>
                </DialogActions>
            </form>
        </Dialog>
    )

	// return (
    //     <Dialog fullWidth={true} maxWidth={"sm"} open={props.open} >
    //         <form onSubmit={handleSubmit}>
    //             <DialogContent sx={{padding:"0px"}}>
    //                 <CRUDDialogHeaderContainer>
    //                     <Typography color={mainWhite} variant={"h6"}><strong>Edit Respondent</strong></Typography>
    //                 </CRUDDialogHeaderContainer>
    //                 <CRUDDialogContainer>
    //                     <Grid container direction={"column"} rowSpacing={3}>
    //                         <Grid item flexGrow={1}>
    //                             <TextField id="respondentName" label="Respondent Name" value={respondentName} variant="outlined" sx={{width:"100%"}}onChange={(event) => setRespondentName(event.target.value)}/>
    //                         </Grid>
    //                         <Grid item>
    //                             <TextField id="respondentBio" label="Respondent Bio" value={respondentBio} multiline rows={5} variant="outlined" sx={{width:"100%"}} onChange={(event) => setRespondentBio(event.target.value)}/>
    //                         </Grid>
    //                         <Grid item>
    //                             <Box sx={{ position:'relative', width: "100%", maxWidth: "550px" }}>
    //                                 <StorageManager
    //                                     acceptedFileTypes={['image/*']}
    //                                     accessLevel="public"
    //                                     maxFileCount={1}
    //                                     isResumable
    //                                     components={{
    //                                         Container({ children }) {
    //                                             return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
    //                                         },
    //                                         DropZone({ children }) {
    //                                             return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
    //                                         },
    //                                         FilePicker({ onClick }) {
    //                                             return (
    //                                                 <FormControl sx={{ width: "100%" }}>
    //                                                     <InputLabel >Respondent Photo</InputLabel>
    //                                                     <OutlinedInput
    //                                                         id="imageFile"
    //                                                         disabled
    //                                                         value={imageUploadPath}
    //                                                         endAdornment={
    //                                                             <InputAdornment position="end">
    //                                                                 <IconButton onClick={onClick} sx={{display:(allowImageUpload ? "inherit" : "none")}}>
    //                                                                     <CloudUploadIcon />
    //                                                                 </IconButton>
    //                                                                 <IconButton onClick={handleClearImage} sx={{display:(allowImageUpload ? "none" : "inherit")}}>
    //                                                                     <HighlightOffIcon />
    //                                                                 </IconButton>
    //                                                             </InputAdornment>
    //                                                         }
    //                                                         label="Respondent Photo"
    //                                                     />
    //                                                 </FormControl>
    //                                             );
    //                                         },
    //                                         FileList(args) {
    //                                             return null;
    //                                         },
    //                                         FileListHeader( args ) {
    //                                             return null;
    //                                         },
    //                                     }}
    //                                     processFile={({ file, key }) => {
    //                                         let uuid = uuidv4();
    //                                         let uuidSplit = uuid.split('-');
    //                                         key = "images/" + uuidSplit[4] + "-" + file.name;
    //                                         return { file, key };
    //                                     }}
    //                                     onUploadStart={({ key }) => {
    //                                         dialogs.showProgress("Uploading > Please wait...");
    //                                     }}
    //                                     onUploadSuccess={({ key }) => {
    //                                         dialogs.closeProgress();
    //                                         setImageUploadPath(key);
    //                                         setAllowImageUpload(false);
    //                                     }}
    //                                 />
    //                             </Box>
    //                         </Grid>
    //                     </Grid>
    //                 </CRUDDialogContainer>
    //             </DialogContent>
    //             <DialogActions sx={{padding:"20px 0px 0px 0px"}}>
    //                 <CRUDDialogActionsContainer>
    //                     <Grid container direction="row" columnSpacing={1} justifyContent={"flex-end"}>
    //                         <Grid item>
    //                             <GreenButton variant="contained" onClick={handleClose}>Cancel</GreenButton>
    //                         </Grid>
    //                         <Grid item>
    //                             <PurpleButton type="submit" variant="contained" >Update Respondent</PurpleButton>
    //                         </Grid>
    //                     </Grid>
    //                 </CRUDDialogActionsContainer>
    //             </DialogActions>
    //         </form>
    //     </Dialog>
    // )
}