import { Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Loading from "../../loading/Loading";
import { AdminContainer, AdminHeroContainer, AdminHeroInnerContainer } from "../../styled/containers/Containers";
import { mediumBlue } from "../../styled/theme/Variables";


// --------------------------------------------------------
// PROFILE COMPONENT
// --------------------------------------------------------

export function Profile( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------

    const [isLoading, setIsLoading] = useState(true);


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        setIsLoading(false);
    }, []);


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

	return (
        <Fragment>
            { isLoading === true
                ? <Loading text={"Loading > Please Wait..."}/>
                : <AdminContainer maxWidth={false} disableGutters>
                    <AdminHeroContainer maxWidth={false} disableGutters>
                        <AdminHeroInnerContainer>
                            <Typography variant={"h4"} color={mediumBlue}><strong>Manage Profile</strong></Typography>
                        </AdminHeroInnerContainer>
                    </AdminHeroContainer>
                </AdminContainer>
            }
        </Fragment>
    )

}

export default Profile;