import './styles/global.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "./components/styled/theme/Theme";
import AlertsProvider from './components/global/alerts/AlertsProvider';
import DialogsProvider from './components/global/dialogs/DialogsProvider';
import APIProvider from './components/global/api/APIProvider';
import SessionProvider from './components/global/session/SessionProvider';
import { Authenticator } from '@aws-amplify/ui-react';


// --------------------------------------------------------
// CONFIGURE AMPLIFY
// --------------------------------------------------------

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);


// --------------------------------------------------------
// GET APPLICATION STRUCTURE
// --------------------------------------------------------

const getAppStructure = () => {
	return (
		<ThemeProvider theme={ theme }>
			<AlertsProvider>
				<DialogsProvider>
					<SessionProvider>
						<APIProvider>
							<BrowserRouter>
								<Authenticator.Provider>
									<App />
								</Authenticator.Provider>
							</BrowserRouter>
						</APIProvider>
					</SessionProvider>
				</DialogsProvider>
			</AlertsProvider>
		</ThemeProvider>
	)
}

// --------------------------------------------------------
// RENDER APPLICATION
// --------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(getAppStructure());