import { Image, useAuthenticator } from "@aws-amplify/ui-react";
import { Grid, Typography, Link as MUILink } from "@mui/material";
import { FooterContainer, FooterInnerContainer } from "../../styled/containers/Containers";
import { Link } from "react-router-dom";
import { lightBlue, mainWhite } from "../../styled/theme/Variables";
import { GreenButton } from "../../styled/buttons/Buttons";
import useDialogs from "../../global/dialogs/useDialogs";


// --------------------------------------------------------
// FOOTER COMPONENT
// --------------------------------------------------------

export default function Footer (props) {

    // ----------------------------------------------------
	// PROPERTIES
	// ----------------------------------------------------

    const dialogs = useDialogs();
    const { route, signOut } = useAuthenticator((context) => [context.route, context.signOut]);

    const footerImg = {
        float: "right",
        maxWidth: "40px",
        marginLeft: "15px",
        marginTop: "5px",
    }

    // ----------------------------------------------------
	// RENDER FOOTER COMPONENT
	// ----------------------------------------------------

    return (
        <FooterContainer maxWidth={false} disableGutters>
            <FooterInnerContainer>
                <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                        <Grid container direction={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                            <Grid item>
                                {route === "authenticated"
                                    ? <MUILink component={Link} to="/dashboard">
                                        <Image src="/images/global/health-immersion-logo-light.png" alt="Health Immersion by Dig" width="311" height="37" />
                                    </MUILink>
                                    : <MUILink component={Link} to="/">
                                        <Image src="/images/global/health-immersion-logo-light.png" alt="Health Immersion by Dig" width="311" height="37" />
                                    </MUILink>
                                }
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent={"flex-start"} columnSpacing={1}>
                                    <Grid item>
                                        <Typography color={lightBlue} variant="body2">© 2023 dig</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography color={lightBlue} variant="body2">
                                            Terms | Privacy Policy
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>                        
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"} justifyContent={"flex-end"} alignItems={"flex-end"} rowSpacing={1}>
                            {route === "authenticated"
                                ? <Grid item>
                                    <Grid container justifyContent={"flex-start"} columnSpacing={1}>
                                        <Grid item>
                                            <Typography color={mainWhite} variant="body"><button className="underlinedLink asText" onClick={dialogs.showContactAuth}>Request a Film</button> | </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography color={mainWhite} variant="body"><button className="underlinedLink asText" onClick={dialogs.showContactAuth}>Interview Respondent</button> | </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography color={mainWhite} variant="body"><button className="underlinedLink asText" onClick={dialogs.showContactAuth}>Ideation Roundtables</button> | </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography color={mainWhite} variant="body"><button className="underlinedLink asText" onClick={dialogs.showContactAuth}>Other Services</button></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                : <Grid item>
                                    <Grid container justifyContent={"flex-start"} columnSpacing={1}>
                                        <Grid item>
                                            <GreenButton variant="contained" onClick={dialogs.showContactNonAuth}>Get Access</GreenButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {route === "authenticated"
                                ? <Grid item>
                                    <Typography color={lightBlue} variant={"body2"}>For questions or support, please <button className="underlinedLink asText" onClick={dialogs.showContactAuth}>contact us</button></Typography>    
                                </Grid>
                                : <Grid item>
                                    <Typography color={lightBlue} variant={"body2"}>For questions or support, please <button className="underlinedLink asText" onClick={dialogs.showContactNonAuth}>contact us</button></Typography>    
                                </Grid>
                            }
                        </Grid>   
                    </Grid>
                </Grid>   
            </FooterInnerContainer>
        </FooterContainer>
    )
}
