import { Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { GreenButton, PurpleButton } from "../../styled/buttons/Buttons";
import { CRUDDialogActionsContainer, CRUDDialogContainer, CRUDDialogHeaderContainer } from "../../styled/containers/Containers";
import { mainWhite } from "../../styled/theme/Variables";
import useDialogs from "../../global/dialogs/useDialogs";
import useAPI from "../../global/api/useAPI";
import useSession from "../../global/session/useSession";
import { useState } from "react";


// --------------------------------------------------------
// ADD IMPACT COMPONENT
// --------------------------------------------------------

export function AddUser( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------
    
    const api = useAPI();
    const session = useSession();
    const dialogs = useDialogs();
    const [userRole, setUserRole] = useState("");
    const [showSuper, setShowSuper] = useState(session.getUserAttribute(session.ACCESS_LEVEL) === "SuperAdmins");
    const [showAdmin, setShowAdmin] = useState(
        session.getUserAttribute(session.ACCESS_LEVEL) === "SuperAdmins" || 
        session.getUserAttribute(session.ACCESS_LEVEL) === "Admins" && props.accountName === "Dig"
    );


    // ----------------------------------------------------
	// HANDLERS 
	// ----------------------------------------------------

    const handleSubmit = async (event) => {
        event.preventDefault();

        let validate = validateForm(event);
        if (validate !== false) {
            let title = "Missing Required Fields";
            dialogs.showValidation(title, validate, () => { });
            return;
        }
        let firstName = event.currentTarget.firstName.value;
        let lastName = event.currentTarget.lastName.value;
        let emailAddress = event.currentTarget.emailAddress.value;
        try {
            dialogs.showProgress("Adding User > Please wait...");
            await api.addNewUser(userRole, props.accountName, firstName, lastName, emailAddress, "en-US", props.accountID);
            dialogs.closeProgress();
            handleClose();
            props.reload();
        } catch (error) {
            console.log(error);
            dialogs.closeProgress();
        }
    };

    const validateForm = (event) => {
        let errors = [];
        let role = (userRole === "") ? errors.push("User Role is required") : null;
        let firstName = (event.currentTarget.firstName.value === "") ? errors.push("First Name is required") : null;
        let lastName = (event.currentTarget.lastName.value === "") ? errors.push("Last Name is required") : null;
        let emailAddress = (event.currentTarget.emailAddress.value === "") ? errors.push("Email Address is required") : null;
        if (errors.length > 0) {
            return errors;
        } else {
            return false;
        }
    }

    // HANDLE CLOSE DIALOG
    const handleClose = (doReload) => {
        setUserRole("");
        props.close(false);
    };

    // HANDLE REASON CHANGE
    const handleRoleChange = (event) => {
        setUserRole(event.target.value);
    };


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

	return (
        <Dialog fullWidth={true} maxWidth={"sm"} open={props.open}>
            <form onSubmit={handleSubmit}>
                <DialogContent sx={{padding:"0px"}}>
                    <CRUDDialogHeaderContainer>
                        <Typography color={mainWhite} variant={"h6"}><strong>Add New User</strong></Typography>
                    </CRUDDialogHeaderContainer>
                    <CRUDDialogContainer>
                        <Grid container direction={"column"} rowSpacing={3}>
                            <Grid item flexGrow={1}>
                                <FormControl fullWidth>
                                    <InputLabel id="userRoleLabel">User Role</InputLabel>
                                    <Select labelId="userRoleLabel" id="userRoleSelect" value={userRole} label="User Role" onChange={handleRoleChange}>
                                        {showSuper ? <MenuItem value={"SuperAdmins"}>Super Admin</MenuItem> : null}
                                        {showAdmin ? <MenuItem value={"Admins"}>Admin</MenuItem> : null}
                                        <MenuItem value={"AccountAdmins"}>Account Admin</MenuItem>
                                        <MenuItem value={"AccountUsers"}>Account User</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item flexGrow={1}>
                                <TextField id="firstName" label="First Name" variant="outlined" sx={{width:"100%"}} />
                            </Grid>
                            <Grid item flexGrow={1}>
                                <TextField id="lastName" label="Last Name" variant="outlined" sx={{width:"100%"}} />
                            </Grid>
                            <Grid item flexGrow={1}>
                                <TextField id="emailAddress" label="Email Address" variant="outlined" sx={{width:"100%"}} />
                            </Grid>                   
                        </Grid>
                    </CRUDDialogContainer>
                </DialogContent>
                <DialogActions sx={{padding:"20px 0px 0px 0px"}}>
                    <CRUDDialogActionsContainer>
                        <Grid container direction="row" columnSpacing={1} justifyContent={"flex-end"}>
                            <Grid item>
                                <GreenButton variant="contained" onClick={handleClose}>Cancel</GreenButton>
                            </Grid>
                            <Grid item>
                                <PurpleButton type="submit" variant="contained" >Add User</PurpleButton>
                            </Grid>
                        </Grid>
                    </CRUDDialogActionsContainer>
                </DialogActions>
            </form>
        </Dialog>
    )
}