import { Box, Checkbox, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputAdornment, InputLabel,ListItemText,MenuItem,OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { GreenButton, PurpleButton } from "../../styled/buttons/Buttons";
import { CRUDDialogActionsContainer, CRUDDialogContainer, CRUDDialogHeaderContainer } from "../../styled/containers/Containers";
import { mainWhite } from "../../styled/theme/Variables";
import { StorageManager } from '@aws-amplify/ui-react-storage';
import { v4 as uuidv4 } from 'uuid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useDialogs from "../../global/dialogs/useDialogs";
import useAPI from "../../global/api/useAPI";
import { Storage } from 'aws-amplify';
import useSession from "../../global/session/useSession";
import { JSONPath } from "jsonpath-plus";


// --------------------------------------------------------
// ADD ACCOUNT COMPONENT
// --------------------------------------------------------

export function AddAccount( props ) {


    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------
    
    const api = useAPI();
    const dialogs = useDialogs();
    const session = useSession();
    const [allowImageUpload, setAllowImageUpload] = useState(true);
    const [imageUploadPath, setImageUploadPath] = useState("");
    const [selectedConditions, setSelectedConditions] = useState([]);


    // ----------------------------------------------------
	// HANDLERS 
	// ----------------------------------------------------

    const handleSubmit = async (event) => {
        event.preventDefault();

        let validate = validateForm(event);
        if (validate !== false) {
            let title = "Missing Required Fields";
            dialogs.showValidation(title, validate, () => { });
            return;
        }

        let accountName = event.currentTarget.accountName.value;
        let adminFirstName = event.currentTarget.adminFirstName.value;
        let adminLastName = event.currentTarget.adminLastName.value;
        let adminEmail = event.currentTarget.adminEmail.value;
        let accountImage = {name:event.currentTarget.accountName.value, key:event.currentTarget.imageFile.value};
        
        try {
            dialogs.showProgress("Adding Account > Please wait...");

            // GET CONDITION IDS
            let conditionsQuery = "$[?(";
            for (let a = 0; a < selectedConditions.length; a++) {
                conditionsQuery += "@.name === '" + selectedConditions[a] + "'";
                (a < selectedConditions.length - 1) ? conditionsQuery += " || " : conditionsQuery += ")].id";
            }
            let conditions = JSONPath({ path: conditionsQuery, json: session.conditions });
            
            // CREATE NEW ACCOUNT
            let account = await api.newAccount(accountName, accountImage, conditions);

            // CREATE NEW ADMIN USER
            let adminUser = await api.addNewUser("AccountAdmins", accountName, adminFirstName, adminLastName, adminEmail, "en-US", account.id);

            dialogs.closeProgress();
        } catch (error) {
            console.log(error);
            //dialogs.closeProgress();
        }
    };

    const validateForm = (event) => {
        let errors = [];
        let accountName = (event.currentTarget.accountName.value === "") ? errors.push("Account Name is required") : null;
        let adminFirstName = (event.currentTarget.adminFirstName.value === "") ? errors.push("Admin First Name is required") : null;
        let adminLastName = (event.currentTarget.adminLastName.value === "") ? errors.push("Admin Last Name is required") : null;
        let adminEmail = (event.currentTarget.adminEmail.value === "") ? errors.push("Admin Email Address is required") : null;
        let hasSelectedConditions = (selectedConditions.length === 0) ? errors.push("At least one Subscribed Condition is required") : null;
        let accountImage = (event.currentTarget.imageFile.value === "") ? errors.push("Account Logo is required") : null;
        if (errors.length > 0) {
            return errors;
        } else {
            return false;
        }
    }

    const handleClose = () => {
        setSelectedConditions([]);
        props.close(false);
    };

    const handleClearImage = async () => {
        dialogs.showProgress("Deleting > Please wait...");
        await Storage.remove(imageUploadPath);
        setAllowImageUpload(true);
        setImageUploadPath("");
        dialogs.closeProgress();
    };

    const handleProcessAccountName = (event) => {
        const filteredValue = event.target.value.replace(/[^a-zA-Z]/g, '');
        event.target.value = filteredValue;
    };

    // ----------------------------------------------------
	// DROPDOWN HANDLER
	// ----------------------------------------------------

    const handleConditionsChange = (event) => {
        const { target: { value }, } = event;
        setSelectedConditions(typeof value === 'string' ? value.split(',') : value);
    };


    // ----------------------------------------------------
	// DROPDOWN STYLING
	// ----------------------------------------------------

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    // ----------------------------------------------------
	// RENDER COMPONENT
	// ----------------------------------------------------

	return (
        <Dialog fullWidth={true} maxWidth={"sm"} open={props.open}>
            <form onSubmit={handleSubmit}>
                <DialogContent sx={{padding:"0px"}}>
                    <CRUDDialogHeaderContainer>
                        <Typography color={mainWhite} variant={"h6"}><strong>Add New Account</strong></Typography>
                    </CRUDDialogHeaderContainer>
                    <CRUDDialogContainer>
                        <Grid container direction={"column"} rowSpacing={3}>
                            <Grid item flexGrow={1}>
                                <TextField id="accountName" label="Account Name" variant="outlined" sx={{width:"100%"}} onInput={handleProcessAccountName} />
                            </Grid>
                            <Grid item flexGrow={1}>
                                <TextField id="adminFirstName" label="Admin First Name" variant="outlined" sx={{width:"100%"}} />
                            </Grid>
                            <Grid item flexGrow={1}>
                                <TextField id="adminLastName" label="Admin Last Name" variant="outlined" sx={{width:"100%"}} />
                            </Grid>
                            <Grid item flexGrow={1}>
                                <TextField id="adminEmail" label="Admin Email Address" variant="outlined" sx={{width:"100%"}} />
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ width: "100%", maxWidth: "550px" }}>
                                    <InputLabel id="selectedConditions">Subscribed Conditions</InputLabel>
                                    <Select labelId="selectedConditions" multiple value={ selectedConditions }
                                        onChange={ handleConditionsChange }
                                        input={ <OutlinedInput multiline label="Subscribed Conditions"/> }
                                        renderValue={ (selected) => selected.join(', ') }
                                        MenuProps={MenuProps} >
                                        { session.conditions.map((condition, index) => (
                                            <MenuItem key={index} value={condition.name}>
                                                <Checkbox checked={selectedConditions.indexOf(condition.name) > -1} />
                                                <ListItemText primary={condition.name} primaryTypographyProps={{fontSize: '14px'}}  />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Box sx={{ position:'relative', width: "100%", maxWidth: "550px" }}>
                                    <StorageManager
                                        acceptedFileTypes={['image/*']}
                                        accessLevel="public"
                                        maxFileCount={1}
                                        isResumable
                                        components={{
                                            Container({ children }) {
                                                return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
                                            },
                                            DropZone({ children }) {
                                                return <Container sx={{ margin:0, padding:0 }} disableGutters>{children}</Container>
                                            },
                                            FilePicker({ onClick }) {
                                                return (
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <InputLabel>Account Logo</InputLabel>
                                                        <OutlinedInput
                                                            id="imageFile"
                                                            disabled
                                                            value={imageUploadPath}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={onClick} sx={{display:(allowImageUpload ? "inherit" : "none")}}>
                                                                        <CloudUploadIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={handleClearImage} sx={{display:(allowImageUpload ? "none" : "inherit")}}>
                                                                        <HighlightOffIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label="Account Logo"
                                                        />
                                                    </FormControl>
                                                );
                                            },
                                            FileList(args) {
                                                return null;
                                            },
                                            FileListHeader( args ) {
                                                return null;
                                            },
                                        }}
                                        processFile={({ file, key }) => {
                                            let uuid = uuidv4();
                                            let uuidSplit = uuid.split('-');
                                            key = "images/" + uuidSplit[4] + "-" + file.name;
                                            return { file, key };
                                        }}
                                        onUploadStart={({ key }) => {
                                            dialogs.showProgress("Uploading > Please wait...");
                                        }}
                                        onUploadSuccess={({ key }) => {
                                            dialogs.closeProgress();
                                            setImageUploadPath(key);
                                            setAllowImageUpload(false);
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CRUDDialogContainer>
                </DialogContent>
                <DialogActions sx={{padding:"20px 0px 0px 0px"}}>
                    <CRUDDialogActionsContainer>
                        <Grid container direction="row" columnSpacing={1} justifyContent={"flex-end"}>
                            <Grid item>
                                <GreenButton variant="contained" onClick={handleClose}>Cancel</GreenButton>
                            </Grid>
                            <Grid item>
                                <PurpleButton type="submit" variant="contained" >Add Account</PurpleButton>
                            </Grid>
                        </Grid>
                    </CRUDDialogActionsContainer>
                </DialogActions>
            </form>
        </Dialog>
    )
}