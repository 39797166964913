
// --------------------------------------------------------
// COMPONENT COLORS
// --------------------------------------------------------

export const primaryColor = "#00E2FF";
export const secondaryColor = "#E2FDFF";
export const errorColor = "#FC3636";
export const warningColor = "#FF9534";
export const infoColor = "#2196F3";
export const successColor = "#13C504";


// --------------------------------------------------------
// FONTS COLORS
// --------------------------------------------------------

export const fontPrimaryHeadingColor = "#000000";
export const fontSecondaryHeadingColor = "#757575";
export const fontPrimaryBodyColor = "#000000";
export const fontSecondaryBodyColor = "#757575";


// --------------------------------------------------------
// THEME COLORS
// --------------------------------------------------------

export const mainWhite = "#FFFFFF";
export const mainBlack = "#000000";

export const darkBlue = "#194D82";
export const mediumBlue = "#5081BC";
export const lightBlue = "#A9C7E8";

export const mediumPurple = "#8880D7";
export const mediumGreen = "#72D3B7";
export const mediumOrange = "#FDB088";
export const mediumTaupe = "#faeee7"; //original  FEF9F6

export const darkGray = "#4C5762";
export const mediumGray = "#82CBB6";
export const lightGray = "#FEF6F3";