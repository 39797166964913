import { View } from "@aws-amplify/ui-react";
import { Storage } from "aws-amplify";
import { Breadcrumbs, Grid, Typography, Link as MUILink, Button } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { VideoDetailsContainer, VideoDetailsHeroContainer, VideoDetailsHeroInnerContainer } from "../styled/containers/Containers";
import { mainWhite } from "../styled/theme/Variables";
import { Link, useLocation, useParams } from "react-router-dom";
import ReactPlayer from 'react-player'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useSession from "../global/session/useSession";
import Loading from "../loading/Loading";


// --------------------------------------------------------
// VIDEO DETAILS COMPONENT
// --------------------------------------------------------

export function VideoDetails() {
	
    // ----------------------------------------------------
	// PROPERTIES 
	// ----------------------------------------------------

    const { id } = useParams();
    const location = useLocation();
	const session = useSession();
    const [currentVideo, setCurrentVideo] = useState();
    const [videoURL, setVideoURL] = useState();
    const [videoThumbURL, setVideoThumbURL] = useState();
    const [isLoading, setIsLoading] = useState(true);


    // ----------------------------------------------------
	// GET VIDEO URL
	// ----------------------------------------------------

    const getVideoThumbURL = async (key) => {
        var pathCheck = key.split("/");
        if (pathCheck[0] === "public"){
            key = key.substring(key.indexOf('/') + 1)
        }
        var signedURL = await Storage.get(key);
        setVideoThumbURL(signedURL);
    }

    
    // ----------------------------------------------------
	// GET VIDEO URL
	// ----------------------------------------------------

    const getVideoURL = async (key) => {
        var pathCheck = key.split("/");
        if (pathCheck[0] === "public"){
            key = key.substring(key.indexOf('/') + 1)
        }
        var signedURL = await Storage.get(key);
        setVideoURL(signedURL);
    }


    // ----------------------------------------------------
	// LOAD APPLICATION DATA 
	// ----------------------------------------------------

    const loadVideoData = async () => {
        
        let video = session.getVideoByID(id);
        setCurrentVideo(video);
        getVideoURL(video.video.key);
        getVideoThumbURL(video.thumbnail.key);
        setIsLoading(false);
    }


    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        loadVideoData();
    }, []);


	// ----------------------------------------------------
	// CONTAINER STYLES
	// ----------------------------------------------------

    const videoDetailsContainer = {
        width: "100%",
    }

    
	// ----------------------------------------------------
	// RENDER CONDITION COMPONENT
	// ----------------------------------------------------

	return (
        <Fragment>
            { isLoading === true
                ? <View>
                    <Loading text={"Loading > Please Wait..."}/>
                </View>
                : <VideoDetailsContainer maxWidth={false} disableGutters>
                    <VideoDetailsHeroContainer maxWidth={false} >
                        
                        {/* HERO CONTENT */}
                        <VideoDetailsHeroInnerContainer>

                            <Button startIcon={<ArrowBackIosNewIcon style={{ color: mainWhite, paddingRight:10 }}/>} sx={{marginBottom:"30px", textTransform:"none"}}>
                                <MUILink component={Link} to="/dashboard">
                                    <Typography color={mainWhite}>Back</Typography>
                                </MUILink>
                            </Button>
                            
                            <Grid container spacing={8} justifyContent={"space-between"} alignItems={"flex-start"} direction={"row"} wrap={"nowrap"}>
                                <Grid item>
                                
                                    {/* HERO CONTENT LEFT*/}
                                    <Grid container rowSpacing={1} justifyContent={"center"} alignItems={"center"} direction={"column"}>
                                        <Grid item>
                                            <ReactPlayer url={videoURL} controls light={videoThumbURL} config={{ file: { attributes: { controlsList: 'nodownload' } } }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item minWidth={"50%"}>

                                    {/* HERO CONTENT RIGHT*/}
                                    <Grid container rowSpacing={1} justifyContent={"center"} alignItems={"flex-start"} direction={"column"}>
                                        <Grid item>
                                            <Breadcrumbs aria-label="breadcrumb" color={mainWhite}>
                                                <MUILink component={Link} to="/dashboard">
                                                    <Typography color={mainWhite}>All Films</Typography>
                                                </MUILink>
                                                <Typography color={mainWhite}>{ currentVideo.name }</Typography>
                                            </Breadcrumbs>
                                        </Grid>
                                        <Grid item>
                                            <Typography color={mainWhite} variant={"h1"} sx={{marginBottom:"10px", marginTop:"20px"}}>{ currentVideo.name }</Typography>  
                                        </Grid>
                                        <Grid item>
                                            <Typography color={mainWhite}>{ currentVideo.description }</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </VideoDetailsHeroInnerContainer>
                    </VideoDetailsHeroContainer>
                </VideoDetailsContainer>
            }
        </Fragment>
    );
}