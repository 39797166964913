import { Avatar, CircularProgress, Container, Grid, Tooltip, Typography, tooltipClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Storage } from "aws-amplify";
import { Fragment, useEffect, useState } from "react";
import { darkBlue, darkGray, mainWhite, mediumBlue, mediumGray } from "../theme/Variables";


// --------------------------------------------------------
// RESPONDENT GRID ITEM
// --------------------------------------------------------

export function RespondentGridItem ( props ) {

    // ----------------------------------------------------
	// PROPERTIES
	// ----------------------------------------------------

    const [resultImage, setResultImage] = useState();


    // ----------------------------------------------------
	// GET IMAGE URL
	// ----------------------------------------------------

    const getImageURL = async (key) => {
        var signedURL = await Storage.get(key);
        setResultImage(signedURL);
    }

    // ----------------------------------------------------
	// ON COMPONENT LOAD
	// ----------------------------------------------------

    useEffect(() => {
        getImageURL(props.respondent.image.key);
    }, [props]);


    // ----------------------------------------------------
	// CUSTOM WIDTH TOOLTIP
	// ----------------------------------------------------

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 400,
            padding: 15,
            backgroundColor: darkGray,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: darkGray,
        },
    });


    // ----------------------------------------------------
	// RENDER RESPONDENT GRID ITEM COMPONENT
	// ----------------------------------------------------

    return (
        <Fragment>
            {resultImage ? (
                <CustomWidthTooltip title={<Typography color={mainWhite} variant={"body2"}>{props.respondent.bio}</Typography>} arrow>
                    <Grid container spacing={2} direction={"column"} alignItems={"center"}>
                        <Grid item>
                            <Avatar alt={props.respondent.name} src={resultImage} sx={{ width: 120, height: 120 }}/>
                        </Grid>
                        <Grid item>
                            <Typography color={mediumBlue}>{props.respondent.name}</Typography>
                        </Grid>
                    </Grid>
                </CustomWidthTooltip>
            ):(
                <Container sx={{display:'flex', justifyContent:'center', alignItems: "center", height:"180px"}}>
                    <CircularProgress />
                </Container>
            )}
        </Fragment>
    )
}